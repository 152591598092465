import React, { Component } from 'react';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import { isEmpty } from './../../functions';

class About extends Component {
  componentDidMount() {
    document.body.className = '';
    document.body.classList.add('about');
  }

  render() {
    const { user, blocks } = this.props;
    return (
      <div style={{ background: '#f7f7f7' }}>
        <div className="container aboutCBDD">
          {!isEmpty(blocks.translations) && (
            <>
              {blocks.translations.hasOwnProperty('about_us_page') && (
                <>
                  {blocks.translations.about_us_page[user.location.country]
                    .content !== undefined &&
                  blocks.translations.about_us_page[user.location.country]
                    .content
                    ? parse(
                        blocks.translations.about_us_page[user.location.country]
                          .content
                      )
                    : parse(blocks.translations.about_us_page['USA'].content)}
                </>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    blocks: state.translations
  };
};

export default connect(mapStateToProps, null)(About);
