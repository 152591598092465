import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { login } from '../../redux/users/index';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { dispatchRegister, dispatchLogin } from '../../redux/modals/index';
import { isEmpty } from '../../functions';
import parse from 'html-react-parser';

class Login extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      submitted: false,
      show: false
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.show === false && this.props.show.showLogin === true) {
      this.setState({ show: true });
    }
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  showRegisterFromLogin = () => {
    this.setState({ show: false }, () => {
      this.props.handleShowRegisterModal();
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ submitted: true });
    const { email, password } = this.state;
    if (email && password) {
      this.props.handleAuthentication(email, password).then(closeWindow => {
        if (this._isMounted && closeWindow.dontShow) {
          this.setState({ show: false }, () => {
            this.props.handleCloseLoginModal();
          });
        }
      });
    }
  };

  handleClose = () => {
    this.setState({ show: false }, () => {
      this.props.handleCloseLoginModal();
    });
  };

  render() {
    const { email, password, submitted, show } = this.state;
    const { user, blocks } = this.props;
    return (
      <Modal show={show} onHide={this.handleClose}>
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="col-md-6 col-md-offset-3">
            <h2>Login</h2>
            <form name="form" onSubmit={this.handleSubmit}>
              <div
                className={
                  'form-group' + (submitted && !email ? ' has-error' : '')
                }
              >
                <label htmlFor="email">Email: </label>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                />
                {submitted && !email && (
                  <div className="help-block text-danger">
                    Email is required
                  </div>
                )}
              </div>
              <div
                className={
                  'form-group' + (submitted && !password ? ' has-error' : '')
                }
              >
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={this.handleChange}
                />
                {submitted && !password && (
                  <div className="help-block text-danger">
                    Password is required
                  </div>
                )}

                {submitted && this.props.message && (
                  <div className="help-block text-danger">
                    {this.props.message}
                  </div>
                )}
              </div>
              <div className="form-group">
                <button className="btn btn-primary">Login</button>
              </div>
            </form>
            <div className="form-group">
              <Link onClick={this.showRegisterFromLogin} className="" to={`#`}>
                Register
              </Link>
            </div>
            <div className="form-group">
              <Link onClick={this.handleClose} className="" to="/reset">
                Reset password
              </Link>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={this.handleClose} className="btn btn-primary">
            {!isEmpty(blocks.translations) && (
              <>
                {blocks.translations.hasOwnProperty('strclose') && (
                  <>
                    {blocks.translations.strclose[user.location.country]
                      .content !== undefined &&
                    blocks.translations.strclose[user.location.country].content
                      ? parse(
                          blocks.translations.strclose[user.location.country]
                            .content,
                          {
                            replace: function(domNode) {
                              if (domNode.hasOwnProperty('children')) {
                                let text =
                                  domNode.children.length > 0
                                    ? domNode.children[0].data
                                    : '';
                                return <>{text}</>;
                              }

                              return '';
                            }
                          }
                        )
                      : parse(blocks.translations.strclose['USA'].content, {
                          replace: function(domNode) {
                            if (domNode.hasOwnProperty('children')) {
                              let text =
                                domNode.children.length > 0
                                  ? domNode.children[0].data
                                  : '';
                              return <>{text}</>;
                            }

                            return '';
                          }
                        })}
                  </>
                )}
              </>
            )}
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const { loggedIn } = state.user_info;
  let message = state.user_info.hasOwnProperty('message')
    ? state.user_info.message
    : null;
  return {
    loggedIn,
    message,
    user: state.user,
    blocks: state.translations
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleCloseLoginModal: () => {
      dispatch(dispatchLogin('close'));
    },
    handleShowRegisterModal: () => {
      dispatch(dispatchRegister('open'));
    },
    handleAuthentication: (email, password) => {
      return dispatch(login(email, password));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
