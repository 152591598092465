// eslint-disable-next-line
import React, { Component } from 'react';
import { Collapse, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { FAIL_GET_RESULT } from '../../redux/cars/types';
import {
  requestResultCollection,
  receiveResultCollection,
  fetch
} from '../../redux/cars/index';
import Service from '../../api';
import Config from '../../config';
import { Link } from 'react-router-dom';
import Pagination from '../pagination/Pagination';
import { authHeader } from '../../redux/users/services/auth-header';

class QueryHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      collection: [],
      queries: [],
      isFetching: false,
      rowsPerPage: 5,
      totalRecords: '',
      totalPages: '',
      pageLimit: '',
      currentPage: '',
      startIndex: '',
      endIndex: ''
    };
  }

  getHistory = () => {
    const { queries } = this.state;
    let service = new Service({ ...authHeader() });
    this.setState({ isFetching: true });
    service
      .getWithParams(Config.LARAVEL_API.URL + '/get-query-history', {
        params: {
          queries_ids: queries
        }
      })
      .then(
        response => {
          let collection = response.data.payload;
          this.setState({
            totalRecords: collection.length,
            collection: collection,
            isFetching: false
          });
        },
        error => {
          this.setState({
            collection: [],
            isFetching: false
          });
        }
      )
      .catch(error => {
        this.setState({
          collection: [],
          isFetching: false
        });
      });
  };

  onChangePage = data => {
    this.setState({
      pageLimit: data.pageLimit,
      totalPages: data.totalPages,
      currentPage: data.page,
      startIndex: data.startIndex,
      endIndex: data.endIndex
    });
  };

  handleOpen = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  showTableBody = () => {
    const { collection, startIndex, endIndex } = this.state;
    if (collection.length) {
      let itemsPerPage = collection.slice(startIndex, endIndex + 1);
      return itemsPerPage.map((value, index) => {
        return (
          <tr>
            <td>
              {value['cars_ids'].map((value, index) => {
                return (
                  <>
                    <img
                      src={
                        value.imageUrl != null
                          ? value.imageUrl
                          : require('../../assets/images/empty.png')
                      }
                      width="100px"
                      alt="Logo"
                    />
                  </>
                );
              })}
            </td>
            <td>{value['country']}</td>
            <td>{value['segment_name']}</td>
            <td>
              <Link
                onClick={e => this.onFetchResults(e, value)}
                to={{
                  pathname: '/compare-results',
                  state: { cars: value['cars_ids'] }
                }}
              >
                Go to result
              </Link>
            </td>
          </tr>
        );
      });
    }

    return <tr className="text-center">Nothing to show</tr>;
  };

  onFetchResults = (e, value) => {
    let data = {};
    data['cars_ids'] = value['cars'];
    data['segment_id'] = value['segment_id'];
    data['country'] = value['country'];

    this.props.fetchCollection(data);
  };

  render() {
    const { open, collection, isFetching, pageLimit } = this.state;
    return (
      <>
        <Button
          onClick={() => this.handleOpen()}
          aria-controls="history-collapse"
          aria-expanded={open}
        >
          Toggle history
        </Button>
        <Collapse in={open} onEnter={() => this.getHistory()}>
          <div id="history-collapse">
            {!isFetching ? (
              <>
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Cars</th>
                      <th>Country</th>
                      <th>Segment</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.showTableBody()}</tbody>
                </table>
                <div className="text-center">
                  <Pagination
                    totalRecords={collection.length}
                    pageLimit={pageLimit || 5}
                    initialPage={1}
                    pagesToShow={5}
                    onChangePage={this.onChangePage}
                  />
                </div>
              </>
            ) : (
              <div className="center-Y cm-spinner cm-spinner-slider" />
            )}
          </div>
        </Collapse>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchCollection: collection => {
      dispatch(requestResultCollection());

      return fetch(collection)
        .then(response => response.data)
        .then(data => dispatch(receiveResultCollection(data)))
        .catch(error => {
          if (error.response.status === 401) {
            localStorage.removeItem('user');
            window.location = '/';
          }
          let data = {};
          data['payload'] = error.response.data.message;
          data['receivedAt'] = Date.now();
          dispatch({ type: FAIL_GET_RESULT, data });
        });
    }
  };
};

export default connect(null, mapDispatchToProps)(QueryHistory);
