import React, { Component } from 'react';
import Service from '../../api';
import Config from '../../config';

class Reset extends Component {
  state = {
    email: '',
    message: '',
    submitted: false,
    isLoading: false
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  sendMail = e => {
    e.preventDefault();

    let service = new Service();
    this.setState({ isLoading: true, submitted: true });
    const { email } = this.state;
    if (email) {
      service
        .post(Config.LARAVEL_API.URL + '/reset-password', {
          email: email
        })
        .then(
          response => {
            this.setState({
              isLoading: false,
              showSuccess: true,
              message: response.data.message
            });
          },
          error => {
            this.setState({
              isLoading: false,
              message: error.response.data.message
            });
          }
        )
        .catch(error => {
          this.setState({
            isLoading: false,
            message: ''
          });
        });
    }
  };

  render() {
    const { email, isLoading, submitted, message } = this.state;

    if (isLoading) {
      return (
        <div className="row">
          <div className="container">Verifying data...</div>
        </div>
      );
    }

    return (
      <div className="row">
        <div className="container">
          {this.state.hasOwnProperty('showSuccess') ? (
            <p>Reset password information was sent to your mailbox.</p>
          ) : (
            <form>
              <div
                className={
                  'form-group' + (submitted && !email ? ' has-error' : '')
                }
              >
                <label for="email">Enter email:</label>
                <input
                  id="email"
                  name="email"
                  onChange={this.handleChange}
                  value={email}
                  type="email"
                />
                {submitted && !email && (
                  <div className="help-block">Email is required</div>
                )}

                {Object.keys(message).length !== 0 && (
                  <>
                    {message.hasOwnProperty('email') && (
                      <div className="help-block text-danger">
                        {message.email[0]}
                      </div>
                    )}
                  </>
                )}
              </div>
              <button className="btn btn-primary" onClick={this.sendMail}>
                Send reset link
              </button>
            </form>
          )}
        </div>
      </div>
    );
  }
}

export default Reset;
