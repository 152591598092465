import { REQUEST_RESULT_COLLECTION, RECEIVE_RESULT_COLLECTION } from './types';
import axios from 'axios';
import Config from '../../config';
import { authHeader } from '../users/services/auth-header';

const uri_cars_compare = '/cars/compare';
const uri_free_cars_compare = '/free-best-seller-comparison';

export const requestResultCollection = () => {
  return {
    type: REQUEST_RESULT_COLLECTION
  };
};

export const receiveResultCollection = data => {
  let places = [];
  let results = data.hasOwnProperty('result')
    ? data['result']['collection']
    : data['collection'];
  for (let item in results) {
    places.push({
      car_id: parseInt(results[item].car_id),
      index: parseFloat(results[item].comparably_equipped_price)
    });
  }

  places.sort(function(x, y) {
    return x.index - y.index;
  });

  let object = {
    type: RECEIVE_RESULT_COLLECTION
  };

  object['collection'] = data.collection;
  if (data.hasOwnProperty('result')) {
    object['collection'] = data.result.collection;
  }

  if (data.hasOwnProperty('user')) {
    object['user'] = data['user'];
    localStorage.setItem('user', JSON.stringify(data['user']));
  }

  object['places'] = places;
  object['receivedAt'] = Date.now();

  return object;
};

export const fetch = data => {
  return axios.post(Config.LARAVEL_API.URL + uri_cars_compare, data, {
    headers: { ...authHeader() }
  });
};

export const freeFetch = data => {
  return axios.get(Config.LARAVEL_API.URL + uri_free_cars_compare, {
    params: data
  });
};
