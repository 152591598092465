import React from 'react';

export const getSuffixForPlace = place => {
  let suffix = '';
  switch (place) {
    case 1:
      suffix = 'st';
      break;
    case 2:
      suffix = 'nd';
      break;
    case 3:
      suffix = 'rd';
      break;
    case 4:
      suffix = 'th';
      break;
    default:
      break;
  }

  return suffix;
};

export const countryShortCode = name => {
  switch (name) {
    case 'Russia':
      return 'ru';
    case 'Israel':
      return 'he';
    case 'USA':
      return 'en';
    default:
      break;
  }
};

export const drawStringStars = howMany => {
  let html = '';
  for (let i = 0; i < howMany; i++) {
    html += '<span><i class="fas fa fa-star"></i></span>';
  }

  return html;
};

export const drawEmptyStringStars = howMany => {
  let html = '';
  for (let i = 0; i < howMany; i++) {
    html += '<span><i class="far fa-star"></i></span>';
  }

  return html;
};

export const showStringStars = rating => {
  let result = '';
  switch (true) {
    case rating === 1:
      result = drawEmptyStringStars(9);
      break;
    case rating === 2:
      result = drawEmptyStringStars(9);
      break;
    case rating === 3:
      result = drawStringStars(1) + drawEmptyStringStars(8);
      break;
    case rating === 4:
      result =
        drawStringStars(1) +
        '<span><i class="fas fa-star-half-alt"></i></span>' +
        drawEmptyStringStars(7);
      break;
    case rating === 5:
      result = drawStringStars(2) + drawEmptyStringStars(7);
      break;
    case rating === 6:
      result =
        drawStringStars(2) +
        '<span><i class="fas fa-star-half-alt"></i></span>' +
        drawEmptyStringStars(6);
      break;
    case rating === 7:
      result = drawStringStars(3) + drawEmptyStringStars(6);
      break;
    case rating === 8:
      result =
        drawStringStars(3) +
        '<span><i class="fas fa-star-half-alt"></i></span>' +
        drawEmptyStringStars(5);
      break;
    case rating === 9:
      result = drawStringStars(4) + drawEmptyStringStars(5);
      break;
    case rating === 10:
      result =
        drawStringStars(4) +
        '<span><i class="fas fa-star-half-alt"></i></span>' +
        drawEmptyStringStars(4);
      break;
    case rating === 11:
      result = drawStringStars(5) + drawEmptyStringStars(4);
      break;
    case rating === 12:
      result =
        drawStringStars(5) +
        '<span><i class="fas fa-star-half-alt"></i></span>' +
        drawEmptyStringStars(3);
      break;
    case rating === 13:
      result = drawStringStars(6) + drawEmptyStringStars(3);
      break;
    case rating === 14:
      result =
        drawStringStars(6) +
        '<span><i class="fas fa-star-half-alt"></i></span>' +
        drawEmptyStringStars(2);
      break;
    case rating === 15:
      result = drawStringStars(7) + drawEmptyStringStars(2);
      break;
    case rating === 16:
      result =
        drawStringStars(7) +
        '<span><i class="fas fa-star-half-alt"></i></span>' +
        drawEmptyStringStars(1);
      break;
    case rating === 17:
      result = drawStringStars(8) + drawEmptyStringStars(1);
      break;
    case rating === 18:
      result =
        drawStringStars(8) +
        '<span><i class="fas fa-star-half-alt"></i></span>';
      break;
    case rating === 19:
      result = drawStringStars(9);
      break;
    default:
      result = '';
      break;
  }

  return result;
};

export const drawStars = howMany => {
  let html = [];
  for (let i = 0; i < howMany; i++) {
    html.push(
      <span key={makeHash(2, i)}>
        <i className="fas fa fa-star" />
      </span>
    );
  }

  return html;
};

export const drawEmptyStars = howMany => {
  let html = [];
  for (let i = 0; i < howMany; i++) {
    html.push(
      <span key={makeHash(2, i)}>
        <i className="far fa-star" />
      </span>
    );
  }

  return html;
};

export const showStars = rating => {
  let result = null;
  let last = null;
  switch (true) {
    case rating === 1:
      result = drawEmptyStars(9);
      break;
    case rating === 2:
      result = drawEmptyStars(9);
      break;
    case rating === 3:
      result = drawStars(1).concat(drawEmptyStars(8));
      break;
    case rating === 4:
      result = drawStars(1);
      last = result.push(
        <span key={result.length}>
          <i className="fas fa-star-half-alt" />
        </span>
      );
      for (let i = last; i < last + 7; i++) {
        result.push(
          <span key={i}>
            <i className="far fa-star" />
          </span>
        );
      }
      break;
    case rating === 5:
      result = drawStars(2).concat(drawEmptyStars(7));
      break;
    case rating === 6:
      result = drawStars(2);
      last = result.push(
        <span key={result.length}>
          <i className="fas fa-star-half-alt" />
        </span>
      );
      for (let i = last; i < last + 6; i++) {
        result.push(
          <span key={i}>
            <i className="far fa-star" />
          </span>
        );
      }
      break;
    case rating === 7:
      result = drawStars(3).concat(drawEmptyStars(6));
      break;
    case rating === 8:
      result = drawStars(3);
      last = result.push(
        <span key={result.length}>
          <i className="fas fa-star-half-alt" />
        </span>
      );
      for (let i = last; i < last + 5; i++) {
        result.push(
          <span key={i}>
            <i className="far fa-star" />
          </span>
        );
      }
      break;
    case rating === 9:
      result = drawStars(4).concat(drawEmptyStars(5));
      break;
    case rating === 10:
      result = drawStars(4);
      last = result.push(
        <span key={result.length}>
          <i className="fas fa-star-half-alt" />
        </span>
      );
      for (let i = last; i < last + 4; i++) {
        result.push(
          <span key={i}>
            <i className="far fa-star" />
          </span>
        );
      }
      break;
    case rating === 11:
      result = drawStars(5).concat(drawEmptyStars(4));
      break;
    case rating === 12:
      result = drawStars(5);
      last = result.push(
        <span key={result.length}>
          <i className="fas fa-star-half-alt" />
        </span>
      );
      for (let i = last; i < last + 3; i++) {
        result.push(
          <span key={i}>
            <i className="far fa-star" />
          </span>
        );
      }
      break;
    case rating === 13:
      result = drawStars(6).concat(drawEmptyStars(3));
      break;
    case rating === 14:
      result = drawStars(6);
      last = result.push(
        <span key={result.length}>
          <i className="fas fa-star-half-alt" />
        </span>
      );
      for (let i = last; i < last + 2; i++) {
        result.push(
          <span key={i}>
            <i className="far fa-star" />
          </span>
        );
      }
      break;
    case rating === 15:
      result = drawStars(7).concat(drawEmptyStars(2));
      break;
    case rating === 16:
      result = drawStars(7);
      last = result.push(
        <span key={result.length}>
          <i className="fas fa-star-half-alt" />
        </span>
      );
      for (let i = last; i < last + 1; i++) {
        result.push(
          <span key={i}>
            <i className="far fa-star" />
          </span>
        );
      }
      break;
    case rating === 17:
      result = drawStars(8).concat(drawEmptyStars(1));
      break;
    case rating === 18:
      result = drawStars(8);
      result.push(
        <span key={result.length}>
          <i className="fas fa-star-half-alt" />
        </span>
      );
      break;
    case rating === 19:
      result = drawStars(9);
      break;
    default:
      result = [];
      break;
  }

  return result;
};

export const isEmpty = obj => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }

  return true;
};

export const makeHash = (length, index) => {
  var result = '';
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result + '-' + index;
};
