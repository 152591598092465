import { MODAL_REGISTER, MODAL_LOGIN } from './types';

const initialState = { showRegister: false, showLogin: false };

export function modalsReducer(state = initialState, action) {
  switch (action.type) {
    case MODAL_REGISTER:
      return action.modal;
    case MODAL_LOGIN:
      return action.modal;
    default:
      return state;
  }
}
