import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { isEmpty } from '../../functions';
import parse from 'html-react-parser';
import { connect } from 'react-redux';
import { dispatchLogin, dispatchRegister } from '../../redux/modals';
import { login } from '../../redux/users';

class SubmitCompare extends Component {
  handleSubmit = () => {
    let dataToSend = {
      cars_ids: [],
      country: this.props.user.location.country,
      segment_id: null
    };

    this.props.cards.map(value => {
      dataToSend.cars_ids.push({
        id: value.id,
        isBenchMark: value.isBenchmark
      });
      return value;
    });

    this.props.segment_info.map(value => {
      if (value.name === this.props.choosedSegment)
        dataToSend.segment_id = parseInt(value.id);
      return value;
    });

    //dispatch
    this.props.fetchCollection(dataToSend);
  };

  render() {
    const { show, user, blocks } = this.props;
    return (
      <Modal show={show} onHide={this.props.handleClose}>
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="buttonInModal text-center">
            <Link
              className=""
              onClick={() => this.handleSubmit()}
              to={{
                pathname: '/compare-results',
                state: { cars: this.props.cards }
              }}
            >
              Compare Cars
            </Link>
          </div>
          <button onClick={this.props.handleClose} className="btn btn-info">
            Wait, I need to change something ... :)
          </button>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={this.props.handleClose} className="btn btn-primary">
            {!isEmpty(blocks.translations) && (
              <>
                {blocks.translations.hasOwnProperty('strclose') && (
                  <>
                    {blocks.translations.strclose[user.location.country]
                      .content !== undefined &&
                    blocks.translations.strclose[user.location.country].content
                      ? parse(
                          blocks.translations.strclose[user.location.country]
                            .content,
                          {
                            replace: function(domNode) {
                              if (domNode.hasOwnProperty('children')) {
                                let text =
                                  domNode.children.length > 0
                                    ? domNode.children[0].data
                                    : '';
                                return <>{text}</>;
                              }

                              return '';
                            }
                          }
                        )
                      : parse(blocks.translations.strclose['USA'].content, {
                          replace: function(domNode) {
                            if (domNode.hasOwnProperty('children')) {
                              let text =
                                domNode.children.length > 0
                                  ? domNode.children[0].data
                                  : '';
                              return <>{text}</>;
                            }

                            return '';
                          }
                        })}
                  </>
                )}
              </>
            )}
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    blocks: state.translations
  };
};

export default connect(mapStateToProps, null)(SubmitCompare);
