import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  requestDealersCollection,
  receiveDealersCollection,
  fetch
} from '../../redux/dealers/index';
import { showStars, getSuffixForPlace, isEmpty } from '../../functions';
import { Card, Collapse } from 'react-bootstrap';
import parse from 'html-react-parser';

class Result extends Component {
  constructor(props, context) {
    super(props, context);

    const collection = this.props.places;
    let cars = this.props.cars;
    const collectionLength = collection.length;
    const carsLength = cars.length;
    let winner = null;
    for (let position = 0; position < collectionLength; position++) {
      let place = 0;
      for (let innerPosition = 0; innerPosition < carsLength; innerPosition++) {
        if (
          parseInt(collection[position].car_id) ===
          parseInt(cars[innerPosition].id)
        ) {
          place = parseInt(position) + 1;
          cars[innerPosition].place = place;
          if (place === 1) {
            winner = position;
          }
          break;
        }
      }
    }

    this.state = {
      cars: cars,
      winner: winner,
      opened: [].fill(false, 0, this.props.cars.length)
    };
  }

  onExpand = index => {
    let opened = this.state.opened;
    opened[index] = !opened[index];
    this.setState({ opened: opened });
  };

  fetchDealers = id => {
    this.props.fetchCollection({
      id: parseInt(id),
      country: this.props.user.location.country,
      d: 0,
      uc: this.props.user.location.lat + '|' + this.props.user.location.lon
    });
  };

  render() {
    const { info, user, blocks } = this.props;
    const { cars, opened } = this.state;
    console.log(this.props);
    return (
      <div className="resaultContainer">
        <div className="row">
          {cars.map((car, index) => {
            return (
              <Card
                key={index}
                className={'col-3' + (car.place === 1 ? ' winner' : '')}
              >
                <Card.Body>
                  <Card.Title className="text-left">
                    <div className="carPlace">
                      <span className="carPlace__number">{car.place}</span>
                      <span className="carPlace__sufx">
                        {getSuffixForPlace(car.place)}
                      </span>
                    </div>
                    <span className="cardBrandModel">
                      {car.brand} {car.model}
                    </span>
                    <span className="cardVersion">{car.version_name}</span>
                    <span className="rating">
                      {showStars(parseFloat(info[index].grade))}
                    </span>
                  </Card.Title>
                  <div className="cardResaultPaddingfix">
                    <Card.Img
                      variant="top"
                      src={
                        info[index].imageUrl != null
                          ? info[index].imageUrl
                          : require('../../assets/images/empty.png')
                      }
                    />
                    <div className="card__prices">
                      <p className="resaultListPrice">
                        {!isEmpty(blocks.translations) && (
                          <>
                            {blocks.translations.hasOwnProperty(
                              'strlistprice'
                            ) && (
                              <>
                                {blocks.translations.strlistprice[
                                  user.location.country
                                ].content !== undefined &&
                                blocks.translations.strlistprice[
                                  user.location.country
                                ].content
                                  ? parse(
                                      blocks.translations.strlistprice[
                                        user.location.country
                                      ].content,
                                      {
                                        replace: function(domNode) {
                                          if (
                                            domNode.hasOwnProperty('children')
                                          ) {
                                            let text =
                                              domNode.children.length > 0
                                                ? domNode.children[0].data
                                                : '';
                                            return <>{text}</>;
                                          }

                                          return '';
                                        }
                                      }
                                    )
                                  : isEmpty(blocks.translations)
                                  ? ''
                                  : parse(
                                      blocks.translations.strlistprice['USA']
                                        .content,
                                      {
                                        replace: function(domNode) {
                                          if (
                                            domNode.hasOwnProperty('children')
                                          ) {
                                            let text =
                                              domNode.children.length > 0
                                                ? domNode.children[0].data
                                                : '';
                                            return <>{text}</>;
                                          }

                                          return '';
                                        }
                                      }
                                    )}
                              </>
                            )}
                          </>
                        )}
                        <span
                          className={
                            'float-right card_listPrice' +
                            (parseInt(info[index].transaction_price) -
                              parseInt(info[index].list_price) ===
                            0
                              ? ''
                              : ' cross')
                          }
                        >
                          {!user.isFetching && (
                            <i
                              className={
                                user.location.currency === 'NIS'
                                  ? 'fal fa-shekel-sign'
                                  : user.location.currency === 'RUB'
                                  ? 'fal fa-ruble-sign'
                                  : 'fal fa-dollar-sign'
                              }
                            />
                          )}
                          {info[index].list_price}
                        </span>
                      </p>
                      <p>
                        {!isEmpty(blocks.translations) && (
                          <>
                            {blocks.translations.hasOwnProperty(
                              'strtransactionprice'
                            ) && (
                              <>
                                {blocks.translations.strtransactionprice[
                                  user.location.country
                                ].content !== undefined &&
                                blocks.translations.strtransactionprice[
                                  user.location.country
                                ].content
                                  ? parse(
                                      blocks.translations.strtransactionprice[
                                        user.location.country
                                      ].content,
                                      {
                                        replace: function(domNode) {
                                          if (
                                            domNode.hasOwnProperty('children')
                                          ) {
                                            let text =
                                              domNode.children.length > 0
                                                ? domNode.children[0].data
                                                : '';
                                            return <>{text}</>;
                                          }

                                          return '';
                                        }
                                      }
                                    )
                                  : parse(
                                      blocks.translations.strtransactionprice[
                                        'USA'
                                      ].content,
                                      {
                                        replace: function(domNode) {
                                          if (
                                            domNode.hasOwnProperty('children')
                                          ) {
                                            let text =
                                              domNode.children.length > 0
                                                ? domNode.children[0].data
                                                : '';
                                            return <>{text}</>;
                                          }

                                          return '';
                                        }
                                      }
                                    )}
                              </>
                            )}
                          </>
                        )}
                        <span className="float-right card_transPrice">
                          {!user.isFetching && (
                            <i
                              className={
                                user.location.currency === 'NIS'
                                  ? 'fal fa-shekel-sign'
                                  : user.location.currency === 'RUB'
                                  ? 'fal fa-ruble-sign'
                                  : 'fal fa-dollar-sign'
                              }
                            />
                          )}
                          {info[index].transaction_price}
                        </span>
                      </p>
                    </div>
                    {/*<div*/}
                    {/*  className="panel-title keyFeature text-center"*/}
                    {/*  onClick={() => this.onExpand(index)}*/}
                    {/*  aria-controls="example-collapse-text"*/}
                    {/*  aria-expanded={opened[index]}*/}
                    {/*>*/}
                    {/*  <p className="panel-heading">Car key features</p>*/}
                    {/*</div>*/}
                    <Collapse in={opened[index]}>
                      <div className="container-fluid">
                        <div className="row">
                          <div
                            className="d-flex flex-row flex-wrap w-100 text-center"
                            id="example-collapse-text"
                          >
                            {info[index].valuable_equipment.map(
                              (value, index) => {
                                return (
                                  <div className="col-4 p-0" key={index}>
                                    {/* <img src={value.imageUrl} alt={value.name} /> */}
                                    <i className="keyFetIcon fab fa-bluetooth-b" />
                                    <p className="keyFetDesc">{value.name}</p>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                  {/* <Collapse in={opened[index]}>
                    <div id="example-collapse-text">
                      {info[index].valuable_equipment.map((value, index) => {
                        return (
                          <div key={index}>
                            <img src={value.imageUrl} alt={value.name} />
                            <p>{value.name}</p>
                          </div>
                        );
                      })}
                    </div>
                  </Collapse> */}
                </Card.Body>
              </Card>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    blocks: state.translations
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchCollection: collection => {
      dispatch(requestDealersCollection());
      return fetch(collection)
        .then(response => response.data)
        .then(data => dispatch(receiveDealersCollection(data)));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Result);
