import React, { Component } from 'react';
import { connect } from 'react-redux';
import FilterCheckboxes from './FilterCheckboxes';
import _ from 'lodash';
import {
  requestDealersCollection,
  receiveDealersCollection,
  fetchFilter,
  fetch
} from '../../redux/dealers/index';
import { FAIL_GET_RESULT } from '../../redux/dealers/types';
import { isEmpty } from '../../functions';
import parse from 'html-react-parser';

class Filter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      brands: [],
      cities: [],
      range: 0,
      checkedItems: new Map()
    };
  }

  componentDidMount() {
    if (
      this.props.dealers.hasOwnProperty('brands') &&
      this.props.dealers.hasOwnProperty('cities')
    ) {
      if (
        this.props.dealers.brands.length > 0 &&
        this.props.dealers.cities.length > 0
      ) {
        let range = localStorage.getItem('range')
          ? localStorage.getItem('range')
          : 0;
        this.setState(
          {
            range: range,
            brands: this.props.dealers.brands,
            cities: this.props.dealers.cities
          },
          () => {
            localStorage.removeItem('range');
          }
        );
      }
    }
  }

  onEnter = (value, filter) => {
    let listOfCheckboxes = document.querySelectorAll(
      '.checkboxes-' + filter + ' input[name="' + filter[0].toLowerCase() + '"]'
    );
    for (let checkBox in listOfCheckboxes) {
      if (listOfCheckboxes[checkBox] != undefined) {
        if (listOfCheckboxes[checkBox].parentElement !== undefined) {
          listOfCheckboxes[checkBox].parentElement.classList.remove('hide');
        }
      }
    }

    for (let checkBox in listOfCheckboxes) {
      if (listOfCheckboxes[checkBox].id != undefined && value) {
        if (
          listOfCheckboxes[checkBox].id
            .toLowerCase()
            .lastIndexOf(value.toLowerCase()) === -1
        ) {
          if (listOfCheckboxes[checkBox].parentElement != undefined) {
            listOfCheckboxes[checkBox].parentElement.classList.add('hide');
          }
        }
      }
    }
  };

  /*onSearchClick = (value, filter) => {
    console.log('Clicked');
  };*/

  showCheckboxesFilters = (name, collection) => {
    let filterName = name.toLowerCase();
    let firstLetter = name[0].toLowerCase();
    let singleName =
      filterName === 'cities'
        ? filterName
        : filterName.substring(0, filterName.length - 1);
    if (collection.length > 0) {
      return (
        <>
          <div className="col-12 filterTitle">{name}</div>
          <FilterCheckboxes
            filterName={filterName}
            //onSearchClick={this.onSearchClick}
            onEnter={this.onEnter}
          />
          {collection.length > 0 && (
            <>
              <div className="col-12" style={{ paddingBottom: '15px' }}>
                {collection.map((value, index) => {
                  if (value.isSelected) {
                    return (
                      <span
                        key={index + '-badge-' + name}
                        className="badge badge-secondary badge-pointer"
                        onClick={() => this.onBadgeRemove(name, value.id)}
                      >
                        <i className="fas fa-times"></i> {value[singleName]}
                      </span>
                    );
                  }

                  return false;
                })}
              </div>
              <div className={'col-12 fixed-height checkboxes-' + filterName}>
                {collection.map((value, index) => {
                  return (
                    <div
                      key={index + '-checkboxes-' + name}
                      className="custom-control custom-checkbox custom-control-inline col-6 m-0"
                    >
                      <input
                        name={firstLetter}
                        type="checkbox"
                        className="custom-control-input"
                        id={value[singleName]}
                        value={value.id}
                        checked={
                          this.state.checkedItems.get(value[singleName])
                            ? true
                            : value.isSelected
                        }
                        onChange={this.checkBoxChange}
                        onClick={this.onFilterChange}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={value[singleName]}
                      >
                        {value[singleName]}
                      </label>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </>
      );
    }

    return '';
  };

  rangeChange = e => {
    this.setState({ range: e.target.value }, () => {
      localStorage.setItem('range', this.state.range);
    });
  };

  checkBoxChange = e => {
    const name = e.target.id;
    const isChecked = e.target.checked;

    this.setState(prevState => ({
      checkedItems: prevState.checkedItems.set(name, isChecked)
    }));
  };

  onBadgeRemove = (name, id) => {
    let collection = this.state[name.toLowerCase()];

    for (let item in collection) {
      if (collection[item].id === id && collection[item].isSelected) {
        collection[item].isSelected = false;
      }
    }

    this.setState(
      {
        [name.toLowerCase()]: collection
      },
      () => {
        this.onFilterChange();
      }
    );
  };

  onFilterChange = () => {
    let div = document.getElementById('filter');
    let checkboxes = div.getElementsByTagName('input');
    let data = {};
    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].type === 'checkbox') {
        if (!data.hasOwnProperty(checkboxes[i].name)) {
          data[checkboxes[i].name] = [];
        }

        if (checkboxes[i].checked) {
          data[checkboxes[i].name].push(checkboxes[i].value);
          checkboxes[i].checked = true;
        }
      }

      if (checkboxes[i].type === 'range') {
        if (!data.hasOwnProperty(checkboxes[i].name)) {
          data[checkboxes[i].name] = [];
        }

        data[checkboxes[i].name].push(this.state.range);
      }
    }

    data['country'] = this.props.user.location.country;
    data['uc'] =
      this.props.user.location.lat + '|' + this.props.user.location.lon;
    let count = 0;
    for (let item in data) {
      if (Array.isArray(data[item])) {
        if (data[item].length === 0) {
          data[item].push(0);
          count++;
        }
      }
    }

    if (count === 2) {
      let fetchDefault = _.debounce(this.props.fetchDefault, 1500);
      fetchDefault({
        id: null,
        country: data['country'],
        uc: data['uc'],
        d: this.state.range
      });
    } else {
      let fetchCollection = _.debounce(this.props.fetchCollection, 1500);
      fetchCollection(data);
    }

    localStorage.setItem('range', this.state.range);
  };

  render() {
    const { brands, cities } = this.state;
    const { user, blocks } = this.props;
    let newValue = Number(((this.state.range - 0) * 100) / 1000);
    let newPosition = 10 - newValue * 0.2;
    let calc = 'calc(' + newValue + '% + ' + newPosition + 'px)';
    return (
      <>
        <div id="filter" className="container-fluid">
          <button
            style={{ marginBottom: '20px' }}
            id="reset-filter"
            type="button"
            className="btn btn-primary"
            onClick={() => {
              localStorage.removeItem('range');
              this.props.fetchDefault({
                id: null,
                country: this.props.user.location.country
              });
            }}
          >
            {!isEmpty(blocks.translations) && (
              <>
                {blocks.translations.hasOwnProperty('strresetfilter') && (
                  <>
                    {blocks.translations.strresetfilter[user.location.country]
                      .content !== undefined &&
                    blocks.translations.strresetfilter[user.location.country]
                      .content
                      ? parse(
                          blocks.translations.strresetfilter[
                            user.location.country
                          ].content,
                          {
                            replace: function(domNode) {
                              if (domNode.hasOwnProperty('children')) {
                                let text =
                                  domNode.children.length > 0
                                    ? domNode.children[0].data
                                    : '';
                                return <>{text}</>;
                              }

                              return '';
                            }
                          }
                        )
                      : parse(
                          blocks.translations.strresetfilter['USA'].content,
                          {
                            replace: function(domNode) {
                              if (domNode.hasOwnProperty('children')) {
                                let text =
                                  domNode.children.length > 0
                                    ? domNode.children[0].data
                                    : '';
                                return <>{text}</>;
                              }

                              return '';
                            }
                          }
                        )}
                  </>
                )}
              </>
            )}
          </button>
          {brands.length === 0 && cities.length === 0 && (
            <div className="form-group row no-gutters">
              Wrong Country
              <br />
              Please choose proper one.
              <br />
              Or reset the filter
            </div>
          )}

          {cities.length > 0 && brands.length > 0 && (
            <div className="form-group row no-gutters range-wrap">
              <div className="range-value" style={{ left: calc }}>
                <span>{this.state.range}</span>
              </div>
              <input
                type="range"
                min="0"
                max="1000"
                step="1"
                value={this.state.range}
                name="d"
                className="filter-range-slider"
                id="myRange"
                onChange={this.rangeChange}
                onMouseUp={this.onFilterChange}
                onInput={this.onRangeValueChanged}
              />
            </div>
          )}

          {cities.length > 0 && (
            <div className="form-group row no-gutters">
              {this.showCheckboxesFilters('Cities', cities)}
            </div>
          )}

          {brands.length > 0 && (
            <div className="form-group row no-gutters">
              {this.showCheckboxesFilters('Brands', brands)}
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    blocks: state.translations
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchCollection: collection => {
      dispatch(requestDealersCollection());
      return fetchFilter(collection)
        .then(response => response.data)
        .then(data => dispatch(receiveDealersCollection(data)))
        .catch(error => {
          let data = {};
          data['payload'] = error.response.data.message;
          data['receivedAt'] = Date.now();
          dispatch({ type: FAIL_GET_RESULT, data });
        });
    },
    fetchDefault: collection => {
      dispatch(requestDealersCollection());
      return fetch(collection)
        .then(response => response.data)
        .then(data => dispatch(receiveDealersCollection(data)))
        .catch(error => {
          let data = {};
          data['payload'] = error.response.data.message;
          data['receivedAt'] = Date.now();
          dispatch({ type: FAIL_GET_RESULT, data });
        });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
