import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { register } from '../../redux/users/index';
import { connect } from 'react-redux';
import { dispatchRegister, dispatchLogin } from '../../redux/modals/index';

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      submitted: false,
      show: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.show === false && this.props.show.showRegister === true) {
      this.setState({ show: true });
    }
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();

    this.setState({ submitted: true });
    const { name, email, password, password_confirmation } = this.state;

    if (
      name &&
      email &&
      password &&
      password_confirmation &&
      password === password_confirmation
    ) {
      this.props.handleRegister({
        name: name,
        email: email,
        password: password,
        password_confirmation: password_confirmation
      });
    }
  };

  handleClose = () => {
    this.setState({ show: false }, () => {
      this.props.handleCloseRegisterModal();
    });
  };

  showLoginFromRegister = () => {
    this.setState({ show: false }, () => {
      this.props.handleShowLoginModal();
    });
  };

  render() {
    const { user_registering } = this.props;
    const {
      name,
      email,
      password,
      password_confirmation,
      submitted,
      show
    } = this.state;
    return (
      <Modal show={show} onHide={this.handleClose} className="registarModal">
        <Modal.Header closeButton />
        <Modal.Body closeButton>
          <p className="registerTitle text-center">Register</p>
          {Object.keys(user_registering).length !== 0 &&
          user_registering.hasOwnProperty('letter') ? (
            <div className="row">
              <p className="col-md-12 text-center">{user_registering.letter}</p>
            </div>
          ) : (
            <form name="form" className="row" onSubmit={this.handleSubmit}>
              <div className="col-md-12">
                <div
                  className={
                    'form-group' + (submitted && !name ? ' has-error' : '')
                  }
                >
                  <label htmlFor="name">Name: </label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={name}
                    onChange={this.handleChange}
                  />
                  {submitted && !name && (
                    <div className="help-block text-danger">
                      Name is required
                    </div>
                  )}

                  {Object.keys(user_registering).length !== 0 && (
                    <>
                      {user_registering.hasOwnProperty('message') && (
                        <>
                          {!user_registering.success &&
                            user_registering.message.hasOwnProperty('name') && (
                              <div className="help-block text-danger">
                                {user_registering.message.name[0]}
                              </div>
                            )}
                        </>
                      )}
                    </>
                  )}
                </div>
                <div
                  className={
                    'form-group' + (submitted && !email ? ' has-error' : '')
                  }
                >
                  <label htmlFor="email">Email: </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    value={email}
                    onChange={this.handleChange}
                  />
                  {submitted && !email && (
                    <div className="help-block text-danger">
                      Email is required
                    </div>
                  )}

                  {Object.keys(user_registering).length !== 0 && (
                    <>
                      {user_registering.hasOwnProperty('message') && (
                        <>
                          {!user_registering.success &&
                            user_registering.message.hasOwnProperty(
                              'email'
                            ) && (
                              <div className="help-block text-danger">
                                {user_registering.message.email[0]}
                              </div>
                            )}
                        </>
                      )}
                    </>
                  )}
                </div>
                <div
                  className={
                    'form-group' + (submitted && !password ? ' has-error' : '')
                  }
                >
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    value={password}
                    onChange={this.handleChange}
                  />
                  {submitted && !password && (
                    <div className="help-block text-danger">
                      Password is required
                    </div>
                  )}

                  {Object.keys(user_registering).length !== 0 && (
                    <>
                      {user_registering.hasOwnProperty('message') && (
                        <>
                          {!user_registering.success &&
                            user_registering.message.hasOwnProperty(
                              'password'
                            ) && (
                              <div className="help-block text-danger">
                                {user_registering.message.password[0]}
                              </div>
                            )}
                        </>
                      )}
                    </>
                  )}
                </div>

                <div
                  className={
                    'form-group' +
                    (submitted && !password_confirmation ? ' has-error' : '')
                  }
                >
                  <label htmlFor="password_confirmation">
                    Confirm password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    name="password_confirmation"
                    value={password_confirmation}
                    onChange={this.handleChange}
                  />
                  {submitted && !password_confirmation && (
                    <div className="help-block text-danger">
                      Please confirm the password
                    </div>
                  )}

                  {submitted &&
                    password_confirmation !== password &&
                    password_confirmation &&
                    password && (
                      <div className="help-block text-danger">
                        The passwords you entered do not match. Please re-enter
                        your passwords.
                      </div>
                    )}

                  {Object.keys(user_registering).length !== 0 && (
                    <>
                      {user_registering.hasOwnProperty('message') && (
                        <>
                          {!user_registering.success &&
                            user_registering.message.hasOwnProperty(
                              'password_confirmation'
                            ) && (
                              <div className="help-block text-danger">
                                {
                                  user_registering.message
                                    .password_confirmation[0]
                                }
                              </div>
                            )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div
                className="col-md-12 text-center registerBtn"
                style={{ marginTop: '10px' }}
              >
                <button className="btn btn-lg btn-primary">Register</button>
              </div>
            </form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <p className="text-center tos">
            By clicking, you confirm that you agree to our Terms of Service.
          </p>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const { user_registering, user_info, user, modals } = state;
  return {
    user_registering,
    user_info,
    user,
    modals
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleRegister: object => {
      dispatch(register(object));
    },
    handleCloseRegisterModal: () => {
      dispatch(dispatchRegister('close'));
    },
    handleShowLoginModal: () => {
      dispatch(dispatchLogin('open'));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
