import Service from '../../../api';
import Config from '../../../config';
import { authHeader } from './auth-header';

export const userService = {
  login,
  logout,
  register,
  update
};

function login(email, password) {
  let service = new Service();
  return service
    .post(Config.LARAVEL_API.URL + '/login', {
      email: email,
      password: password
    })
    .then(handleResponse);
}

function logout() {
  let service = new Service({ ...authHeader() });
  return service.post(Config.LARAVEL_API.URL + '/logout', {});
}

function update(user) {
  let service = new Service({ ...authHeader() });
  return service
    .put(Config.LARAVEL_API.URL + '/update', {
      subscription: user.subscription,
      name: user.name
    })
    .then(handleUpdateResponse);
}

function register(user) {
  let service = new Service();

  return service
    .post(Config.LARAVEL_API.URL + '/register', user)
    .then(handleRegisterResponse);
}

function handleUpdateResponse(response) {
  const data = response.data;
  if (!data.success) {
    return response.data;
  }

  // store user details and jwt token in local storage to keep user logged in between page refreshes
  localStorage.setItem('user', JSON.stringify(data.user));

  return data.user;
}

function handleRegisterResponse(response) {
  const user = response.data;
  if (!user.success) {
    return response.data;
  }

  return user;
}

function handleResponse(response) {
  const user = response.data;
  if (!user.success) {
    return response.data;
  }

  // store user details and jwt token in local storage to keep user logged in between page refreshes
  localStorage.setItem('user', JSON.stringify(user.data));

  return user.data;
}
