import { combineReducers } from 'redux';
import {
  segmentReducer,
  bestsellerReducer,
  translationReducer
} from './segment/reducer';
import {
  userReducer,
  userAuthenticationReducer,
  userRegistrationReducer
} from './users/reducer';
import { carsReducer } from './cars/reducer';
import { dealersReducer } from './dealers/reducer';
import { modalsReducer } from './modals/reducer';
import { reducer as notifications } from 'react-notification-system-redux';

const rootReducer = combineReducers({
  segment: segmentReducer,
  translations: translationReducer,
  bestseller: bestsellerReducer,
  user: userReducer,
  user_info: userAuthenticationReducer,
  user_registering: userRegistrationReducer,
  cars: carsReducer,
  dealers: dealersReducer,
  modals: modalsReducer,
  notifications
});

export default rootReducer;
