import React, { Component } from 'react';
import { preFetchUserLocation } from '../redux/users/index';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DropDownSegmentList from './lists/DropDownSegmentList';
import { Navbar, Nav, Dropdown, Button, Modal } from 'react-bootstrap';
import {
  requestDealersCollection,
  receiveDealersCollection,
  fetch
} from '../redux/dealers/index';
import { FAIL_GET_RESULT } from '../redux/dealers/types';
import { withRouter } from 'react-router';
import parse from 'html-react-parser';
import { isEmpty } from './../functions';

class Header extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
      countries: ['USA', 'Russia', 'Israel'],
      country: ''
    };
  }

  switchCountry = country => {
    if (
      window.location.pathname === '/compare-results' ||
      window.location.pathname === '/demo-compare'
    ) {
      if (
        !this.props.collection.isFetching &&
        this.props.collection.hasOwnProperty('collection')
      ) {
        this.handleShow();
        this.setState({ country: country });
      }
    } else {
      this.props.fetchCollection({ id: null, country: country });
      this.props.reFetchData(country);
    }
  };

  changeCountry = e => {
    e.preventDefault();
    this.props.reFetchData(this.state.country);
    this.props.history.push('/');
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleCompare = e => {
    e.preventDefault();
    let slider = [];
    if (
      this.props.slider.isFetching === false &&
      this.props.slider.hasOwnProperty('items')
    ) {
      let temp = this.props.slider.items;
      let length = temp.length;
      for (let i = 0; i < length; i++) {
        slider.push(temp[i].best_seller);
        for (let j = 0; j < temp[i].other.length; j++)
          slider.push(temp[i].other[j]);
      }

      slider.sort(() => Math.random() - 0.5);
      this.props.history.push({
        pathname: `/compare`,
        state: {
          resetState: {
            value: '',
            suggestions: [],
            cards: [],
            additionalSlider: [],
            noSuggestions: false,
            show: false,
            dontChange: false,
            multiplySliderFetch: false,
            forceChangeId: null,
            element: null,
            bestSlider: slider,
            isBestFetching: false,
            showBeforeCompare: false,
            choosedSegment: '',
            choosedCarsIds: [],
            amountofChoosedCars: 0
          },
          fromHistory: false
        }
      });
    }
  };

  render() {
    const { user, blocks } = this.props;
    const { countries } = this.state;
    return (
      <div className="row">
        <div className="col-12 px-0">
          <header>
            <Navbar expand="lg" className="py-0">
              <div className="row w-100">
                <div className="col">
                  <Link className="navbar-brand" to="/">
                    <img
                      src={require('../assets/images/logoHeader.png')}
                      alt="CBDD Logo"
                    />
                  </Link>
                </div>
                <div className="col-auto align-self-end">
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mx-auto">
                      <Link
                        className="nav-link py-0 px-0"
                        onClick={e => this.handleCompare(e)}
                      >
                        {!isEmpty(blocks.translations) && (
                          <>
                            {blocks.translations.hasOwnProperty(
                              'strcompare'
                            ) && (
                              <>
                                {blocks.translations.strcompare[
                                  user.location.country
                                ].content !== undefined &&
                                blocks.translations.strcompare[
                                  user.location.country
                                ].content
                                  ? parse(
                                      blocks.translations.strcompare[
                                        user.location.country
                                      ].content,
                                      {
                                        replace: function(domNode) {
                                          if (
                                            domNode.hasOwnProperty('children')
                                          ) {
                                            let text =
                                              domNode.children.length > 0
                                                ? domNode.children[0].data
                                                : '';
                                            return <>{text}</>;
                                          }

                                          return '';
                                        }
                                      }
                                    )
                                  : parse(
                                      blocks.translations.strcompare['USA']
                                        .content,
                                      {
                                        replace: function(domNode) {
                                          if (
                                            domNode.hasOwnProperty('children')
                                          ) {
                                            let text =
                                              domNode.children.length > 0
                                                ? domNode.children[0].data
                                                : '';
                                            return <>{text}</>;
                                          }

                                          return '';
                                        }
                                      }
                                    )}
                              </>
                            )}
                          </>
                        )}
                      </Link>
                      <Link className="nav-devider mx-3" to="" />
                      <div className="py-0 dropdown nav-item">
                        <a
                          aria-haspopup="true"
                          aria-expanded="false"
                          href={`#`}
                          className="dropdown-toggle nav-link"
                          role="button"
                        >
                          {!isEmpty(blocks.translations) && (
                            <>
                              {blocks.translations.hasOwnProperty(
                                'strcarsegments'
                              ) && (
                                <>
                                  {blocks.translations.strcarsegments[
                                    user.location.country
                                  ].content !== undefined &&
                                  blocks.translations.strcarsegments[
                                    user.location.country
                                  ].content
                                    ? parse(
                                        blocks.translations.strcarsegments[
                                          user.location.country
                                        ].content,
                                        {
                                          replace: function(domNode) {
                                            if (
                                              domNode.hasOwnProperty('children')
                                            ) {
                                              let text =
                                                domNode.children.length > 0
                                                  ? domNode.children[0].data
                                                  : '';
                                              return <>{text}</>;
                                            }

                                            return '';
                                          }
                                        }
                                      )
                                    : parse(
                                        blocks.translations.strcarsegments[
                                          'USA'
                                        ].content,
                                        {
                                          replace: function(domNode) {
                                            if (
                                              domNode.hasOwnProperty('children')
                                            ) {
                                              let text =
                                                domNode.children.length > 0
                                                  ? domNode.children[0].data
                                                  : '';
                                              return <>{text}</>;
                                            }

                                            return '';
                                          }
                                        }
                                      )}
                                </>
                              )}
                            </>
                          )}
                        </a>
                        <div
                          className="dropdown-menu shadow"
                          aria-labelledby=""
                        >
                          <DropDownSegmentList />
                        </div>
                      </div>
                      <Link className="nav-devider mx-3" to="" />
                      <Link className="nav-link py-0 px-0" to="/how-it-works">
                        {!isEmpty(blocks.translations) && (
                          <>
                            {blocks.translations.hasOwnProperty(
                              'strhowdoesitwork'
                            ) && (
                              <>
                                {blocks.translations.strhowdoesitwork[
                                  user.location.country
                                ].content !== undefined &&
                                blocks.translations.strhowdoesitwork[
                                  user.location.country
                                ].content
                                  ? parse(
                                      blocks.translations.strhowdoesitwork[
                                        user.location.country
                                      ].content,
                                      {
                                        replace: function(domNode) {
                                          if (
                                            domNode.hasOwnProperty('children')
                                          ) {
                                            let text =
                                              domNode.children.length > 0
                                                ? domNode.children[0].data
                                                : '';
                                            return <>{text}</>;
                                          }

                                          return '';
                                        }
                                      }
                                    )
                                  : parse(
                                      blocks.translations.strhowdoesitwork[
                                        'USA'
                                      ].content,
                                      {
                                        replace: function(domNode) {
                                          if (
                                            domNode.hasOwnProperty('children')
                                          ) {
                                            let text =
                                              domNode.children.length > 0
                                                ? domNode.children[0].data
                                                : '';
                                            return <>{text}</>;
                                          }

                                          return '';
                                        }
                                      }
                                    )}
                              </>
                            )}
                          </>
                        )}
                      </Link>
                      <Link className="nav-devider mx-3" to="" />
                      <Link className="nav-link py-0 px-0" to="/about-cbdd">
                        {!isEmpty(blocks.translations) && (
                          <>
                            {blocks.translations.hasOwnProperty(
                              'straboutus'
                            ) && (
                              <>
                                {blocks.translations.straboutus[
                                  user.location.country
                                ].content !== undefined &&
                                blocks.translations.straboutus[
                                  user.location.country
                                ].content
                                  ? parse(
                                      blocks.translations.straboutus[
                                        user.location.country
                                      ].content,
                                      {
                                        replace: function(domNode) {
                                          if (
                                            domNode.hasOwnProperty('children')
                                          ) {
                                            let text =
                                              domNode.children.length > 0
                                                ? domNode.children[0].data
                                                : '';
                                            return <>{text}</>;
                                          }

                                          return '';
                                        }
                                      }
                                    )
                                  : parse(
                                      blocks.translations.straboutus['USA']
                                        .content,
                                      {
                                        replace: function(domNode) {
                                          if (
                                            domNode.hasOwnProperty('children')
                                          ) {
                                            let text =
                                              domNode.children.length > 0
                                                ? domNode.children[0].data
                                                : '';
                                            return <>{text}</>;
                                          }

                                          return '';
                                        }
                                      }
                                    )}
                              </>
                            )}
                          </>
                        )}
                      </Link>
                      {/* {this.props.loggedIn && (
                        <>
                          <Link className="nav-devider mx-3" to="" />
                          <Link
                            className="nav-link py-0 px-0"
                            to="/personal-info"
                          >
                            Personal info
                          </Link>
                        </>
                      )} */}
                    </Nav>
                  </Navbar.Collapse>
                </div>

                <div className="col align-self-end">
                  <Dropdown className="float-right countrySelect">
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="dropdown-header d-flex h-100 align-items-center justify-content-center"
                    >
                      {!user.isFetching && (
                        <>
                          <img
                            src={require('../assets/images/' +
                              user.location.country +
                              '.jpg')}
                            alt="CBDD Logo"
                            className="mr-2"
                          />{' '}
                          {!isEmpty(blocks.translations) && (
                            <>
                              {blocks.translations.hasOwnProperty(
                                'str' + user.location.country.toLowerCase()
                              ) && (
                                <>
                                  {blocks.translations[
                                    'str' + user.location.country.toLowerCase()
                                  ][user.location.country].content !==
                                    undefined &&
                                  blocks.translations[
                                    'str' + user.location.country.toLowerCase()
                                  ][user.location.country].content
                                    ? parse(
                                        blocks.translations[
                                          'str' +
                                            user.location.country.toLowerCase()
                                        ][user.location.country].content,
                                        {
                                          replace: function(domNode) {
                                            if (
                                              domNode.hasOwnProperty('children')
                                            ) {
                                              let text =
                                                domNode.children.length > 0
                                                  ? domNode.children[0].data
                                                  : '';
                                              return <>{text}</>;
                                            }

                                            return '';
                                          }
                                        }
                                      )
                                    : parse(
                                        blocks.translations[
                                          'str' +
                                            user.location.country.toLowerCase()
                                        ]['USA'].content,
                                        {
                                          replace: function(domNode) {
                                            if (
                                              domNode.hasOwnProperty('children')
                                            ) {
                                              let text =
                                                domNode.children.length > 0
                                                  ? domNode.children[0].data
                                                  : '';
                                              return <>{text}</>;
                                            }

                                            return '';
                                          }
                                        }
                                      )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="shadow">
                      {user.isFetching && (
                        <Dropdown.Item href="#">Loading ...</Dropdown.Item>
                      )}
                      {!user.isFetching &&
                        countries.map((value, index) => {
                          return (
                            <Dropdown.Item
                              href="#"
                              key={index}
                              className={
                                user.location.country === value ? 'active' : ''
                              }
                              onClick={() => this.switchCountry(value)}
                            >
                              {!user.isFetching && (
                                <>
                                  <img
                                    src={require('../assets/images/' +
                                      value +
                                      '.jpg')}
                                    alt="CBDD Logo"
                                    className="mr-2"
                                  />{' '}
                                  {!isEmpty(blocks.translations) && (
                                    <>
                                      {blocks.translations.hasOwnProperty(
                                        'str' + value.toLowerCase()
                                      ) && (
                                        <>
                                          {blocks.translations[
                                            'str' + value.toLowerCase()
                                          ][user.location.country].content !==
                                            undefined &&
                                          blocks.translations[
                                            'str' + value.toLowerCase()
                                          ][user.location.country].content
                                            ? parse(
                                                blocks.translations[
                                                  'str' + value.toLowerCase()
                                                ][user.location.country]
                                                  .content,
                                                {
                                                  replace: function(domNode) {
                                                    if (
                                                      domNode.hasOwnProperty(
                                                        'children'
                                                      )
                                                    ) {
                                                      let text =
                                                        domNode.children
                                                          .length > 0
                                                          ? domNode.children[0]
                                                              .data
                                                          : '';
                                                      return <>{text}</>;
                                                    }

                                                    return '';
                                                  }
                                                }
                                              )
                                            : parse(
                                                blocks.translations[
                                                  'str' + value.toLowerCase()
                                                ]['USA'].content,
                                                {
                                                  replace: function(domNode) {
                                                    if (
                                                      domNode.hasOwnProperty(
                                                        'children'
                                                      )
                                                    ) {
                                                      let text =
                                                        domNode.children
                                                          .length > 0
                                                          ? domNode.children[0]
                                                              .data
                                                          : '';
                                                      return <>{text}</>;
                                                    }

                                                    return '';
                                                  }
                                                }
                                              )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </Dropdown.Item>
                          );
                        })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </Navbar>
          </header>
        </div>
        <div className="col-12 px-0">
          <div className="hpTitle d-flex align-items-center justify-content-center w-100 h-100">
            <h1>
              {!isEmpty(blocks.translations) && (
                <>
                  {blocks.translations.hasOwnProperty('strcomparenewcars') && (
                    <>
                      {blocks.translations['strcomparenewcars'][
                        user.location.country
                      ].content !== undefined &&
                      blocks.translations['strcomparenewcars'][
                        user.location.country
                      ].content
                        ? parse(
                            blocks.translations['strcomparenewcars'][
                              user.location.country
                            ].content,
                            {
                              replace: function(domNode) {
                                if (domNode.hasOwnProperty('children')) {
                                  let text =
                                    domNode.children.length > 0
                                      ? domNode.children[0].data
                                      : '';

                                  if (text) {
                                    let first_half = text.match(
                                      new RegExp('(.*)<span')
                                    );
                                    if (first_half) {
                                      text = first_half[1];
                                    }
                                  }

                                  return <>{text}</>;
                                }

                                return '';
                              }
                            }
                          )
                        : parse(
                            blocks.translations['strcomparenewcars']['USA']
                              .content,
                            {
                              replace: function(domNode) {
                                if (domNode.hasOwnProperty('children')) {
                                  let text =
                                    domNode.children.length > 0
                                      ? domNode.children[0].data
                                      : '';

                                  if (text) {
                                    let first_half = text.match(
                                      new RegExp('(.*)<span')
                                    );
                                    if (first_half) {
                                      text = first_half[1];
                                    }
                                  }

                                  return <>{text}</>;
                                }

                                return '';
                              }
                            }
                          )}
                    </>
                  )}
                </>
              )}{' '}
              <span className="strong">
                {!isEmpty(blocks.translations) && (
                  <>
                    {blocks.translations.hasOwnProperty(
                      'strcomparenewcars'
                    ) && (
                      <>
                        {blocks.translations['strcomparenewcars'][
                          user.location.country
                        ].content !== undefined &&
                        blocks.translations['strcomparenewcars'][
                          user.location.country
                        ].content
                          ? parse(
                              blocks.translations['strcomparenewcars'][
                                user.location.country
                              ].content,
                              {
                                replace: function(domNode) {
                                  if (domNode.hasOwnProperty('children')) {
                                    let text =
                                      domNode.children.length > 0
                                        ? domNode.children[0].data
                                        : '';

                                    if (text) {
                                      let second_half = text.match(
                                        new RegExp('>(.*)</span')
                                      );
                                      if (second_half) {
                                        text = second_half[1];
                                      }
                                    }

                                    return <>{text}</>;
                                  }

                                  return '';
                                }
                              }
                            )
                          : parse(
                              blocks.translations['strcomparenewcars']['USA']
                                .content,
                              {
                                replace: function(domNode) {
                                  if (domNode.hasOwnProperty('children')) {
                                    let text =
                                      domNode.children.length > 0
                                        ? domNode.children[0].data
                                        : '';

                                    if (text) {
                                      let second_half = text.match(
                                        new RegExp('>(.*)</span>')
                                      );
                                      if (second_half) {
                                        text = second_half[1];
                                      }
                                    }

                                    return <>{text}</>;
                                  }

                                  return '';
                                }
                              }
                            )}
                      </>
                    )}
                  </>
                )}
              </span>
            </h1>
          </div>
        </div>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Switch country</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Button
              className="btn btn-secondary"
              onClick={e => this.changeCountry(e)}
            >
              Change Country
            </Button>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.handleClose()}>
              Proceed with current country
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { loggedIn } = state.user_info;
  return {
    slider: state.bestseller,
    user: state.user,
    collection: state.cars,
    blocks: state.translations,
    loggedIn
  };
};

const mapDispatchToProps = dispatch => {
  return {
    reFetchData: country => {
      dispatch(preFetchUserLocation(country, true));
    },
    fetchCollection: collection => {
      dispatch(requestDealersCollection());
      return fetch(collection)
        .then(response => response.data)
        .then(data => dispatch(receiveDealersCollection(data)))
        .catch(error => {
          let data = {};
          data['payload'] = error.response.data.message;
          data['receivedAt'] = Date.now();
          dispatch({ type: FAIL_GET_RESULT, data });
        });
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
