import React, { Component } from 'react';
import { update } from '../../redux/users/index';
import { connect } from 'react-redux';
import QueryHistory from '../blocks/QueryHistory';

class PersonalPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: '',
      submit_modal: false,
      user_info: {
        name: '',
        email: '',
        subscription: '',
        payment_verified: true,
        temp_payment_code: ''
      },
      submitted: false,
      showBeforePayment: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.user_info.hasOwnProperty('isFetching')) {
      if (!this.props.user_info.isFetching) {
        this.setState({
          message: '',
          user_info: {
            name: this.props.user_info.user.name,
            email: this.props.user_info.user.email,
            subscription: this.props.user_info.user.subscription,
            payment_verified:
              this.props.user_info.user.payment_verified === 0 ? false : true,
            temp_payment_code: this.props.user_info.user.temp_payment_code
          }
        });
      }
    }
  }

  componentDidMount() {
    document.body.className = '';
    document.body.classList.add('personal-page');
    this.setState({
      message: '',
      user_info: {
        name: this.props.user_info.user.name,
        email: this.props.user_info.user.email,
        subscription: this.props.user_info.user.subscription,
        payment_verified:
          this.props.user_info.user.payment_verified === 0 ? false : true,
        temp_payment_code: this.props.user_info.user.temp_payment_code
      }
    });
  }

  handleChange = e => {
    const { user_info } = this.state;
    const { name, value } = e.target;
    user_info[name] = value;
    this.setState(user_info);
  };

  handleSubmit = e => {
    e.preventDefault();

    this.setState({ submitted: true });
    const { dispatch } = this.props;
    const { user_info } = this.state;
    if (user_info.name) {
      dispatch(update(user_info));
    }
  };

  render() {
    const { user_info, submitted } = this.state;

    if (this.props.user_info.hasOwnProperty('isFetching')) {
      if (this.props.user_info.isFetching) {
        return <div className="center-Y cm-spinner" />;
      }
    }

    return (
      <div className="row">
        <div className="container">
          <form>
            <div className="form-group">
              <label for="name">Name:</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                onChange={this.handleChange}
                value={user_info.name}
              />

              {submitted && !user_info.name && (
                <div className="help-block text-danger">Please enter name</div>
              )}
            </div>
            <div className="form-group">
              <button onClick={this.handleSubmit} className="btn btn-primary">
                Update
              </button>
            </div>
          </form>
          <QueryHistory />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { user, user_info } = state;
  return {
    user,
    user_info
  };
};

export default connect(mapStateToProps, null)(PersonalPage);
