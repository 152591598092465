import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { isEmpty } from './../../functions';

function createMenu(user = null, blocks = null, list) {
  const amountItemsInOneMenu = Math.floor(list.items.length / 2);
  let rows = [];
  let start = 0;
  let stop = amountItemsInOneMenu;

  for (let i = 0; i < 2; i++) {
    let temp = [];
    for (let j = start; j < stop; j++) {
      let vowels = ['\\\\', '%', '&', '.', '/', ' '];
      let updatedString = list.items[j].alias.toLowerCase();
      let pattern = new RegExp('[' + vowels.join(',') + ']', 'g');
      updatedString = updatedString.replace(pattern, '_');
      pattern = new RegExp('&amp;', 'g');
      updatedString = updatedString.replace(pattern, '_');
      if (updatedString === 'm_mpv_large') {
        updatedString = 'm_mpv_med___large';
      }
      temp.push(
        <li key={j} className="list-group-item col">
          <Link
            className="dropdown-item text-center"
            to={{
              pathname: list.items[j].segment_info
                ? `/compare/${encodeURIComponent(list.items[j].name)}`
                : `#`,
              state: { segment_name: list.items[j].name }
            }}
          >
            <div className={'menuPicto seg' + list.items[j].id}>
              {!isEmpty(blocks.translations) && (
                <>
                  {blocks.translations.hasOwnProperty(updatedString) && (
                    <>
                      {blocks.translations[updatedString][user.location.country]
                        .content !== undefined &&
                      blocks.translations[updatedString][user.location.country]
                        .content
                        ? parse(
                            blocks.translations[updatedString][
                              user.location.country
                            ].content,
                            {
                              replace: function(domNode) {
                                if (domNode.hasOwnProperty('children')) {
                                  let text =
                                    domNode.children.length > 0
                                      ? domNode.children[0].data
                                      : '';
                                  return <>{text}</>;
                                }

                                return '';
                              }
                            }
                          )
                        : parse(
                            blocks.translations[updatedString]['USA'].content,
                            {
                              replace: function(domNode) {
                                if (domNode.hasOwnProperty('children')) {
                                  let text =
                                    domNode.children.length > 0
                                      ? domNode.children[0].data
                                      : '';
                                  return <>{text}</>;
                                }

                                return '';
                              }
                            }
                          )}
                    </>
                  )}
                </>
              )}
            </div>
          </Link>
        </li>
      );
    }
    rows.push(temp);
    start += amountItemsInOneMenu;
    stop += amountItemsInOneMenu;
  }

  return rows;
}

function SegmentList({ user, segment, blocks }) {
  if (segment.isFetching) {
    return (
      <ul>
        <li>Loading ...</li>
      </ul>
    );
  }

  return (
    <React.Fragment>
      {createMenu(user, blocks, segment).map((value, index) => {
        return (
          <ul className="list-group list-group-horizontal" key={index}>
            {value.map((li, index) => {
              return li;
            })}
          </ul>
        );
      })}
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    segment: state.segment,
    blocks: state.translations,
    user: state.user
  };
};

export default connect(mapStateToProps)(SegmentList);
