import { MODAL_REGISTER, MODAL_LOGIN } from './types';

export const dispatchLogin = type => {
  return dispatch => {
    switch (type) {
      case 'open':
        dispatch(openLogin());
        break;
      case 'close':
        dispatch(closeLogin());
        break;
      default:
        break;
    }
  };

  function openLogin() {
    const modal = { showRegister: false, showLogin: true };
    return { type: MODAL_LOGIN, modal };
  }
  function closeLogin() {
    const modal = { showRegister: false, showLogin: false };
    return { type: MODAL_LOGIN, modal };
  }
};

export const dispatchRegister = type => {
  return dispatch => {
    switch (type) {
      case 'open':
        dispatch(openRegister());
        break;
      case 'close':
        dispatch(closeRegister());
        break;
      default:
        break;
    }
  };

  function openRegister() {
    const modal = { showRegister: true, showLogin: false };
    return { type: MODAL_REGISTER, modal };
  }
  function closeRegister() {
    const modal = { showRegister: false, showLogin: false };
    return { type: MODAL_REGISTER, modal };
  }
};
