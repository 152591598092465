import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import {
  requestResultCollection,
  receiveResultCollection,
  fetch
} from '../../redux/cars/index';
import { FAIL_GET_RESULT } from '../../redux/cars/types';
import MultiplyAutoSuggest from '../blocks/MultiplyAutoSuggest';
import { debounce } from 'throttle-debounce';
import { Card, Modal, Button } from 'react-bootstrap';
import Config from '../../config';
import axios from 'axios';
import { stringify } from 'flatted/esm';
import { makeHash, isEmpty } from '../../functions';
import { dispatchLogin, dispatchRegister } from '../../redux/modals/index';
import SubmitCompare from '../modals/SubmitCompare';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import parse from 'html-react-parser';

class Compare extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      suggestions: [],
      noSuggestions: false,
      show: false,
      dontChange: false,
      multiplySliderFetch: false,
      additionalSlider: [],
      forceChangeId: null,
      element: null,
      bestSlider: [],
      isBestFetching: false,
      showBeforeCompare: false
    };
  }

  UNSAFE_componentWillMount() {
    document.body.className = '';
    document.body.classList.add('compare');

    let chooseBenchmark = false;
    let choosedSegment = '';
    let choosedCarsIds = [];
    let cards = [];
    let amountOfChoosedCars = 0;

    if (
      this.props.match.params.hasOwnProperty('segmentName') &&
      this.props.match.params.hasOwnProperty('car')
    ) {
      choosedSegment = this.props.match.params.segmentName
        ? decodeURIComponent(this.props.match.params.segmentName)
        : '';
      chooseBenchmark = this.props.match.params.car ? true : false;

      if (
        this.props.location.state &&
        this.props.location.state.hasOwnProperty('single_id')
      ) {
        chooseBenchmark = true;
        const {
          single_id,
          single_unique_id,
          single_segment,
          single_brand,
          single_model,
          single_img_url,
          single_version_name,
          single_list_price,
          single_transaction_price
        } = this.props.location.state;

        choosedCarsIds.push({
          id: single_id,
          name: single_unique_id
        });

        cards.push({
          id: single_id,
          unique_id: single_unique_id,
          brand: single_brand,
          model: single_model,
          version_name: single_version_name,
          list_price: single_list_price,
          transaction_price: single_transaction_price,
          imageUrl: single_img_url,
          isBenchmark: true,
          choosed: true,
          segment: single_segment
        });

        amountOfChoosedCars++;
      }
    } else if (this.props.match.params.hasOwnProperty('segmentName')) {
      choosedSegment = this.props.match.params.segmentName
        ? decodeURIComponent(this.props.match.params.segmentName)
        : '';
    } else {
      if (
        this.props.location.state &&
        this.props.location.state.hasOwnProperty('segment_name')
      ) {
        choosedSegment = this.props.location.state.segment_name;
      }
    }

    this.onSuggestionsFetchRequested = debounce(
      500,
      this.onSuggestionsFetchRequested
    );

    this.setState(
      {
        choosedCarsIds: choosedCarsIds,
        amountofChoosedCars: amountOfChoosedCars,
        choosedSegment: choosedSegment,
        choosedBenchmark: chooseBenchmark,
        cards: cards
      },
      () => {
        if (choosedSegment === '') {
          this.compareSlider();
        }

        if (
          !this.props.user.isFetching &&
          this.props.match.params.hasOwnProperty('segmentName') &&
          this.props.match.params.hasOwnProperty('car')
        ) {
          this.onSuggestionsMultiplySliderFetchRequested();
        }
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (
      this.state.choosedSegment === '' &&
      prevProps.slider.isFetching === true &&
      this.props.slider.isFetching === false
    ) {
      this.compareSlider();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    if (!this.props.user.isFetching) {
      if (nextProps.location.state !== undefined) {
        if (nextProps.location.state.hasOwnProperty('resetState')) {
          if (Object.keys(nextProps.location.state.resetState).length > 0) {
            if (
              nextProps.location.state.resetState.choosedCarsIds.length === 0 &&
              nextProps.location.state.resetState.cards.length === 0
            ) {
              this.setState(nextProps.location.state.resetState);
            }

            if (nextProps.location.state.hasOwnProperty('fromHistory')) {
              if (nextProps.location.state.fromHistory) {
                this.setState(nextProps.location.state.resetState);
              }
            }
          }
        }
      }

      if (this.state.choosedSegment) {
        this.onSuggestionsMultiplySliderFetchRequested(nextProps, 'props');
      }

      if (this.state.choosedSegment === '' && !this.props.slider.isFetching) {
        this.compareSlider();
      }

      if (
        nextProps.user.location.country !== this.props.user.location.country
      ) {
        this.setState({
          country: this.props.user.location.country,
          choosedCarsIds: [],
          amountofChoosedCars: 0,
          choosedBenchmark: false,
          cards: []
        });
      } else {
        this.setState({
          country: this.props.user.location.country
        });
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    let segment_name = '';

    if (this.props.match.params.hasOwnProperty('segmentName')) {
      segment_name = this.props.match.params.segmentName
        ? decodeURIComponent(this.props.match.params.segmentName)
        : '';
    } else {
      if (
        this.props.location.state &&
        this.props.location.state.hasOwnProperty('segment_name')
      ) {
        segment_name = this.props.location.state.segment_name;
      }
    }

    if (nextProps.location.hasOwnProperty('state')) {
      if (
        nextProps.location.state &&
        nextProps.location.state.hasOwnProperty('segment_name')
      ) {
        if (nextProps.location.state.segment_name !== segment_name) {
          this.setState({
            additionalSlider: [],
            choosedSegment: nextProps.location.state.segment_name
          });
        }
      }
    }

    if (nextProps.match.params.hasOwnProperty('segmentName')) {
      let choosedSegment = nextProps.match.params.segmentName
        ? decodeURIComponent(nextProps.match.params.segmentName)
        : '';
      if (choosedSegment !== segment_name) {
        this.setState(
          {
            additionalSlider: [],
            choosedSegment: choosedSegment
          },
          () => {
            if (this.state.choosedSegment) {
              this.onSuggestionsMultiplySliderFetchRequested(
                nextProps,
                'props'
              );
            }
          }
        );
      }
    }

    return true;
  }

  /* card crud logic */
  deleteCard = id => {
    let choosedBenchmark = false;
    let index = 0;
    let { amountofChoosedCars, cards, choosedCarsIds } = this.state;
    for (let i = 0; i < cards.length; i++) {
      if (cards[i].id === id) {
        choosedBenchmark = cards[i].isBenchmark;
        index = i;
      }
    }

    choosedCarsIds = choosedCarsIds.filter((value, i) => {
      return value.id !== cards[index].id;
    });

    cards.splice(index, 1);

    if (choosedBenchmark) {
      if (cards.length === 0) {
        this.setState(
          {
            choosedBenchmark: !choosedBenchmark,
            choosedSegment: '',
            additionalSlider: []
          },
          () => {
            this.compareSlider();
          }
        );
      } else {
        cards[0].isBenchmark = true;
      }
    }

    this.setState(
      {
        choosedCarsIds: choosedCarsIds,
        amountofChoosedCars: amountofChoosedCars - 1,
        cards: cards
      },
      () => {
        this.onSuggestionsMultiplySliderFetchRequested();
      }
    );
  };

  handleChildResult = (data, type = null) => {
    let isBenchmark = false;
    let { amountofChoosedCars, cards, choosedCarsIds } = this.state;
    let state = {};

    if (cards.length === 0) {
      this.props.history.push(`/compare/${encodeURIComponent(data.segment)}`);
      isBenchmark = true;
      state = {
        choosedBenchmark: true,
        choosedSegment: data.segment,
        amountofChoosedCars: amountofChoosedCars + 1,
        choosedCarsIds: choosedCarsIds,
        cards: cards
      };
    } else {
      state = {
        amountofChoosedCars: amountofChoosedCars + 1,
        choosedCarsIds: choosedCarsIds,
        cards: cards
      };
    }

    choosedCarsIds.push({
      name: data.unique_id,
      id: parseInt(data.id)
    });

    cards.push({
      id: parseInt(data.id),
      unique_id: data.unique_id,
      segment: data.segment,
      brand: data.brand,
      model: data.model,
      version_name: data.version_name,
      list_price: data.list_price,
      transaction_price: data.transaction_price,
      imageUrl: data.imageUrl,
      isBenchmark: isBenchmark,
      choosed: true
    });

    this.setState(state, () => {
      if (type !== null) {
        this.onSuggestionsMultiplySliderFetchRequested(this.state, type);
      } else {
        this.onSuggestionsMultiplySliderFetchRequested();
      }
    });
  };

  handleLogin = () => {
    this.props.handleLoginModal();
  };

  onFetchResults = e => {
    e.preventDefault();
    if (!this.props.user_info.loggedIn) {
      let state = this.state;
      state.show = false;
      state.showBeforeCompare = false;
      localStorage.setItem('saved-result', stringify(state));
      this.handleLogin();
    } else {
      this.handleBeforeCompare();
    }
  };

  /* end of card crud logic */

  /* Main search */
  onChangeMain = (event, { newValue }) => {
    this.setState({ value: newValue });
  };

  renderSuggestionsContainer = ({ containerProps, children, query }) => {
    return <div {...containerProps}>{children}</div>;
  };

  chooseGlobalCar(suggestion) {
    this.setState({
      choosedSegment: suggestion.segment
    });
    this.handleChildResult(suggestion);
  }

  renderSuggestion = (suggestion, { query }) => {
    const suggestionText = `${suggestion.unique_id}`;
    const matches = AutosuggestHighlightMatch(suggestionText, query);
    const parts = AutosuggestHighlightParse(suggestionText, matches);
    return (
      <Link onClick={() => this.chooseGlobalCar(suggestion)} to="#">
        <span className="name">
          {parts.map((part, index) => {
            const className = part.highlight ? 'highlight' : null;

            return (
              <span className={className} key={makeHash(3, index)}>
                {part.text}
              </span>
            );
          })}
        </span>
      </Link>
    );
  };

  onChange = (event, { newValue, method }) => {
    this.setState({
      value:
        typeof newValue !== 'undefined'
          ? method === 'click' || method === 'enter'
            ? ''
            : newValue
          : ''
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    const uri_cars = '/suggestions-fetch-requested';
    const isInputBlank =
      value !== undefined ? (value.trim() === '' ? false : true) : false;
    let noSuggestions = [];

    axios
      .post(Config.LARAVEL_API.URL + uri_cars, {
        search: value,
        country: this.props.user.location.country
      })
      .then(res => {
        const results = res.data.hits.hits.map(h => h._source);
        noSuggestions = !isInputBlank && results.length === 0;

        this.setState({
          suggestions: results.length > 0 ? results.slice(0, 5) : [],
          noSuggestions: noSuggestions
        });
      });
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  /* end of main search */

  /* change segment by clicking on the icon */
  handleShow = yesNo => {
    this.setState({ show: true, dontChange: yesNo });
  };

  handleClose = yesNo => {
    this.setState({ show: false, dontChange: yesNo });
  };

  changeSegmentRequest = (e, id) => {
    let current_state = this.state;
    const { cards } = this.state;
    if (cards.length !== 0) {
      e.preventDefault();
      current_state.element = e.target;
      current_state.forceChangeId = id;
      this.setState(current_state, () => {
        let type = this.props.match.params.segmentName ? 'switched' : 'url';
        this.onSuggestionsMultiplySliderFetchRequested(null, type);
      });
      this.handleShow(true);
    } else {
      let segment = this.props.segment_info.filter(
        segment => segment.id === id
      );

      this.props.history.push({
        pathname: `/compare/${encodeURIComponent(segment[0].name)}`,
        state: {
          segmentName: segment[0].name
        }
      });
    }
  };

  setNewSegment = () => {
    const { element, forceChangeId } = this.state;
    let li = document.querySelectorAll('#compare-legend li div');
    let length = li.length;
    for (let i = 0; i < length; i++) {
      let classList = li[i].classList;
      let classLength = classList.length;
      for (let j = 0; j < classLength; j++) {
        if (classList[j].match(/-active/)) {
          li[i].classList.remove(classList[j]);
          break;
        }
      }
    }

    element.classList.add('seg' + forceChangeId + '-active');
    let dontChange = this.state.dontChange;

    this.setState(
      {
        choosedCarsIds: [],
        amountofChoosedCars: 0,
        choosedBenchmark: false,
        cards: [],
        show: false,
        dontChange: false
      },
      () => {
        if (dontChange) {
          let segment = this.props.segment_info.filter(
            segment => segment.id === forceChangeId
          );

          this.props.history.push({
            pathname: `/compare/${encodeURIComponent(segment[0].name)}`,
            state: {
              segmentName: segment[0].name
            }
          });
        }
      }
    );
  };

  createMenu = (user, blocks, list, choosedSegment) => {
    const amountItemsInOneMenu = Math.floor(list.items.length / 2);
    let rows = [];
    let start = 0;
    let stop = amountItemsInOneMenu;
    let active = '';

    for (let i = 0; i < 2; i++) {
      let temp = [];
      for (let j = start; j < stop; j++) {
        let vowels = ['\\\\', '%', '&', '.', '/', ' '];
        let updatedString = list.items[j].alias.toLowerCase();
        let pattern = new RegExp('[' + vowels.join(',') + ']', 'g');
        updatedString = updatedString.replace(pattern, '_');
        pattern = new RegExp('&amp;', 'g');
        updatedString = updatedString.replace(pattern, '_');

        if (updatedString === 'm_mpv_large') {
          updatedString = 'm_mpv_med___large';
        }
        active =
          choosedSegment === list.items[j].name
            ? ' seg' + list.items[j].id + '-active'
            : '';
        temp.push(
          <li
            key={makeHash(3, j)}
            data-name={list.items[j].name}
            data-id={list.items[j].id}
            className="list-group-item col segmentList"
          >
            <Link
              onClick={e => this.changeSegmentRequest(e, list.items[j].id)}
              className="dropdown-item text-center"
              to={
                list.items[j].segment_info
                  ? `/compare/${encodeURIComponent(list.items[j].name)}`
                  : `#`
              }
            >
              <div className={'menuPicto seg' + list.items[j].id + active}>
                {!isEmpty(blocks.translations) && (
                  <>
                    {blocks.translations.hasOwnProperty(updatedString) && (
                      <>
                        {blocks.translations[updatedString][
                          user.location.country
                        ].content !== undefined &&
                        blocks.translations[updatedString][
                          user.location.country
                        ].content
                          ? parse(
                              blocks.translations[updatedString][
                                user.location.country
                              ].content,
                              {
                                replace: function(domNode) {
                                  if (domNode.hasOwnProperty('children')) {
                                    let text =
                                      domNode.children.length > 0
                                        ? domNode.children[0].data
                                        : '';
                                    return <>{text}</>;
                                  }

                                  return '';
                                }
                              }
                            )
                          : parse(
                              blocks.translations[updatedString]['USA'].content,
                              {
                                replace: function(domNode) {
                                  if (domNode.hasOwnProperty('children')) {
                                    let text =
                                      domNode.children.length > 0
                                        ? domNode.children[0].data
                                        : '';
                                    return <>{text}</>;
                                  }

                                  return '';
                                }
                              }
                            )}
                      </>
                    )}
                  </>
                )}
              </div>
            </Link>
          </li>
        );
      }

      rows.push(temp);
      start += amountItemsInOneMenu;
      stop += amountItemsInOneMenu;
    }

    return rows;
  };

  renderSegmentList = (user, blocks, segment, choosedSegment) => {
    if (segment.isFetching) {
      return (
        <ul>
          <li>Loading ...</li>
        </ul>
      );
    }

    return (
      <React.Fragment>
        {this.createMenu(user, blocks, segment, choosedSegment).map(
          (value, index) => {
            return (
              <ul
                id="compare-legend"
                className="list-group list-group-horizontal pl-3 pr-3"
                key={makeHash(3, index)}
              >
                {value.map((li, index) => {
                  return li;
                })}
              </ul>
            );
          }
        )}
      </React.Fragment>
    );
  };

  /* end of change segment by clicking on the icon */

  /* multiply slider */
  onSuggestionsMultiplySliderFetchRequested = (props = null, type = 'url') => {
    const uri_cars = '/suggestions-slide-fetch-requested';
    let country = this.props.user.location.country;
    let onlyInSegment = null;
    switch (type) {
      case 'switched':
        onlyInSegment = decodeURIComponent(this.props.match.params.segmentName);
        break;
      case 'url':
        onlyInSegment =
          this.state.choosedSegment !==
          decodeURIComponent(this.props.match.params.segmentName)
            ? decodeURIComponent(this.props.match.params.segmentName)
            : this.state.choosedSegment;
        if (onlyInSegment == 'undefined') {
          onlyInSegment = this.state.choosedSegment;
        }

        if (
          this.props.match.params.hasOwnProperty('segmentName') &&
          this.props.match.params.hasOwnProperty('car')
        ) {
          if (
            this.props.match.params.car != 'undefined' &&
            this.props.match.params.segmentName != 'undefinded' &&
            this.props.match.params.car !== '' &&
            this.props.match.params.segmentName !== ''
          ) {
            onlyInSegment =
              this.state.choosedSegment !==
                decodeURIComponent(this.props.match.params.segmentName) &&
              this.state.choosedSegment !== ''
                ? this.state.choosedSegment
                : decodeURIComponent(this.props.match.params.segmentName);
          }
        }

        let segment = null;

        if (
          this.props.segment_info != 'undefined' &&
          !this.props.segment.isFetching
        ) {
          segment = this.props.segment_info.filter(
            segment => segment.name === onlyInSegment
          );
        }

        if (Array.isArray(segment)) {
          if (segment.length > 0) {
            if (segment[0].segment_info === null) {
              onlyInSegment = decodeURIComponent(
                this.props.match.params.segmentName
              );
            }
          }
        }
        break;
      case 'props':
        onlyInSegment = decodeURIComponent(props.match.params.segmentName);
        if (onlyInSegment == 'undefined') {
          onlyInSegment = this.state.choosedSegment;
        }
        break;
      case 'fromSlider':
        onlyInSegment = props.choosedSegment;
        break;
      default:
        break;
    }
    let excludeCars = this.state.choosedCarsIds.map(car => car.id);
    if (onlyInSegment) {
      this.setState({ additionalSlider: [], multiplySliderFetch: true });
      Promise.resolve(
        axios.post(Config.LARAVEL_API.URL + uri_cars, {
          onlyInSegment: onlyInSegment,
          country: country,
          excludeCars: excludeCars
        })
      )
        .then(response => {
          this.setState({
            multiplySliderFetch: false,
            additionalSlider: response.data.aggregations.max_value.hits.hits.map(
              h => h._source
            )
          });
        })
        .catch(error => {
          this.setState({
            multiplySliderFetch: false,
            additionalSlider: []
          });
        });
    }
  };

  compareSlider = () => {
    let slider = [];
    let isFetching = true;
    if (
      this.props.slider.isFetching === false &&
      this.props.slider.hasOwnProperty('items')
    ) {
      let temp = this.props.slider.items;
      let length = temp.length;
      for (let i = 0; i < length; i++) {
        slider.push(temp[i].best_seller);
        for (let j = 0; j < temp[i].other.length; j++)
          slider.push(temp[i].other[j]);
      }

      isFetching = false;
      slider.sort(() => Math.random() - 0.5);
      this.setState({
        isBestFetching: isFetching,
        bestSlider: slider
      });
    }
  };

  handleBeforeCompare = () => {
    let current_state = this.state;
    current_state.showBeforeCompare = true;
    this.setState(current_state);
  };

  handleCloseBeforeCompare = () => {
    let current_state = this.state;
    current_state.showBeforeCompare = false;
    this.setState(current_state);
  };
  /* end of multiply slider */

  render() {
    const {
      choosedSegment,
      cards,
      choosedCarsIds,
      value,
      suggestions,
      noSuggestions
    } = this.state;

    const settingsSlider = {
      className: 'center text-center',
      centerMode: true,
      infinite: true,
      centerPadding: '0px',
      slidesToShow: 1,
      speed: 500,
      lazyLoad: true
    };

    const theme = {
      container: 'autosuggest',
      input: 'form-control',
      suggestionsContainer: 'dropdown',
      suggestionsList: `dropdown-menu min-wp-100 ${
        suggestions.length ? 'show' : ''
      }`,
      suggestion: 'dropdown-item',
      suggestionFocused: 'active'
    };

    const { user, segment, blocks } = this.props;

    const inputProps = {
      placeholder: '',
      value: value !== undefined ? value : '',
      onChange: this.onChange
    };
    return (
      <React.Fragment>
        <div className="compareCars">
          <div className="compareCarsInner">
            <div className="d-flex justify-content-center">
              <h1>
                {!isEmpty(blocks.translations) && (
                  <>
                    {blocks.translations.hasOwnProperty('strcompare') && (
                      <>
                        {blocks.translations.strcompare[user.location.country]
                          .content !== undefined &&
                        blocks.translations.strcompare[user.location.country]
                          .content
                          ? parse(
                              blocks.translations.strcompare[
                                user.location.country
                              ].content,
                              {
                                replace: function(domNode) {
                                  if (domNode.hasOwnProperty('children')) {
                                    let text =
                                      domNode.children.length > 0
                                        ? domNode.children[0].data
                                        : '';
                                    return <>{text}</>;
                                  }

                                  return '';
                                }
                              }
                            )
                          : parse(
                              blocks.translations.strcompare['USA'].content,
                              {
                                replace: function(domNode) {
                                  if (domNode.hasOwnProperty('children')) {
                                    let text =
                                      domNode.children.length > 0
                                        ? domNode.children[0].data
                                        : '';
                                    return <>{text}</>;
                                  }

                                  return '';
                                }
                              }
                            )}
                      </>
                    )}
                  </>
                )}
              </h1>
            </div>
            {this.props.segment.isFetching ? (
              <div className="center-Y cm-spinner" />
            ) : (
              <>
                <div className="segmentLegend">
                  {this.renderSegmentList(
                    user,
                    blocks,
                    segment,
                    choosedSegment
                  )}
                </div>
                <div className="container-fluid chosenCars">
                  <div className="row no-gutters">
                    {cards.map((card, index) => {
                      return (
                        <Card
                          key={makeHash(3, index)}
                          className="col-3 chooseCar"
                        >
                          <Card.Body className="selected">
                            <Card.Title className="text-left">
                              <span className="cardBrandModel">
                                {card.brand} {card.model}
                              </span>
                              <span className="cardVersion">
                                {card.version_name}
                              </span>
                            </Card.Title>
                            <Card.Img
                              variant="top"
                              src={
                                card.imageUrl != null
                                  ? card.imageUrl
                                  : require('../../assets/images/empty.png')
                              }
                            />
                            <div className="cardPrices">
                              <p className="d-flex justify-content-between cardListPrice">
                                <span>
                                  {!isEmpty(blocks.translations) && (
                                    <>
                                      {blocks.translations.hasOwnProperty(
                                        'strlistprice'
                                      ) && (
                                        <>
                                          {blocks.translations.strlistprice[
                                            user.location.country
                                          ].content !== undefined &&
                                          blocks.translations.strlistprice[
                                            user.location.country
                                          ].content
                                            ? parse(
                                                blocks.translations
                                                  .strlistprice[
                                                  user.location.country
                                                ].content,
                                                {
                                                  replace: function(domNode) {
                                                    if (
                                                      domNode.hasOwnProperty(
                                                        'children'
                                                      )
                                                    ) {
                                                      let text =
                                                        domNode.children
                                                          .length > 0
                                                          ? domNode.children[0]
                                                              .data
                                                          : '';
                                                      return <>{text}</>;
                                                    }

                                                    return '';
                                                  }
                                                }
                                              )
                                            : parse(
                                                blocks.translations
                                                  .strlistprice['USA'].content,
                                                {
                                                  replace: function(domNode) {
                                                    if (
                                                      domNode.hasOwnProperty(
                                                        'children'
                                                      )
                                                    ) {
                                                      let text =
                                                        domNode.children
                                                          .length > 0
                                                          ? domNode.children[0]
                                                              .data
                                                          : '';
                                                      return <>{text}</>;
                                                    }

                                                    return '';
                                                  }
                                                }
                                              )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </span>
                                <span className="price">
                                  ${card.list_price}
                                </span>
                              </p>
                              <p className="d-flex align-items-center justify-content-between cardTransPrice">
                                <span>
                                  {!isEmpty(blocks.translations) && (
                                    <>
                                      {blocks.translations.hasOwnProperty(
                                        'strtransactionprice'
                                      ) && (
                                        <>
                                          {blocks.translations
                                            .strtransactionprice[
                                            user.location.country
                                          ].content !== undefined &&
                                          blocks.translations
                                            .strtransactionprice[
                                            user.location.country
                                          ].content
                                            ? parse(
                                                blocks.translations
                                                  .strtransactionprice[
                                                  user.location.country
                                                ].content,
                                                {
                                                  replace: function(domNode) {
                                                    if (
                                                      domNode.hasOwnProperty(
                                                        'children'
                                                      )
                                                    ) {
                                                      let text =
                                                        domNode.children
                                                          .length > 0
                                                          ? domNode.children[0]
                                                              .data
                                                          : '';
                                                      return <>{text}</>;
                                                    }

                                                    return '';
                                                  }
                                                }
                                              )
                                            : parse(
                                                blocks.translations
                                                  .strtransactionprice['USA']
                                                  .content,
                                                {
                                                  replace: function(domNode) {
                                                    if (
                                                      domNode.hasOwnProperty(
                                                        'children'
                                                      )
                                                    ) {
                                                      let text =
                                                        domNode.children
                                                          .length > 0
                                                          ? domNode.children[0]
                                                              .data
                                                          : '';
                                                      return <>{text}</>;
                                                    }

                                                    return '';
                                                  }
                                                }
                                              )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </span>
                                <span className="price">
                                  ${card.transaction_price}
                                </span>
                              </p>
                            </div>
                            <p
                              className="panel-heading text-center"
                              onClick={() => this.deleteCard(card.id)}
                            >
                              <u className="cbddRed">Replace car</u>
                            </p>
                          </Card.Body>
                        </Card>
                      );
                    })}

                    {!user.isFetching && (
                      <>
                        {cards.length < 4 && (
                          <>
                            {choosedSegment &&
                            !this.state.multiplySliderFetch &&
                            this.state.additionalSlider.length === 0 ? (
                              <>
                                <div className="w-100 text-center">
                                  No data for choosed <b>{choosedSegment}</b> in{' '}
                                  <b>{user.location.country}</b>
                                  <br />
                                  Please choose another segment
                                </div>
                              </>
                            ) : (
                              <Card className="col-3 chooseCar">
                                <Card.Body>
                                  <div>
                                    <p className="text-center">
                                      {!choosedSegment &&
                                        !isEmpty(blocks.translations) && (
                                          <>
                                            {blocks.translations.hasOwnProperty(
                                              'strchoosecar'
                                            ) && (
                                              <>
                                                {blocks.translations
                                                  .strchoosecar[
                                                  user.location.country
                                                ].content !== undefined &&
                                                blocks.translations
                                                  .strchoosecar[
                                                  user.location.country
                                                ].content
                                                  ? parse(
                                                      blocks.translations
                                                        .strchoosecar[
                                                        user.location.country
                                                      ].content,
                                                      {
                                                        replace: function(
                                                          domNode
                                                        ) {
                                                          if (
                                                            domNode.hasOwnProperty(
                                                              'children'
                                                            )
                                                          ) {
                                                            let text =
                                                              domNode.children
                                                                .length > 0
                                                                ? domNode
                                                                    .children[0]
                                                                    .data
                                                                : '';
                                                            return <>{text}</>;
                                                          }

                                                          return '';
                                                        }
                                                      }
                                                    )
                                                  : parse(
                                                      blocks.translations
                                                        .strchoosecar['USA']
                                                        .content,
                                                      {
                                                        replace: function(
                                                          domNode
                                                        ) {
                                                          if (
                                                            domNode.hasOwnProperty(
                                                              'children'
                                                            )
                                                          ) {
                                                            let text =
                                                              domNode.children
                                                                .length > 0
                                                                ? domNode
                                                                    .children[0]
                                                                    .data
                                                                : '';
                                                            return <>{text}</>;
                                                          }

                                                          return '';
                                                        }
                                                      }
                                                    )}
                                              </>
                                            )}
                                          </>
                                        )}
                                      {choosedSegment && 'Add another car'}
                                    </p>
                                    {!choosedSegment && (
                                      <>
                                        <div className="searchCarsFeild">
                                          <Autosuggest
                                            id="general-choosecar"
                                            theme={theme}
                                            suggestions={suggestions}
                                            onSuggestionsFetchRequested={
                                              this.onSuggestionsFetchRequested
                                            }
                                            onSuggestionsClearRequested={
                                              this.onSuggestionsClearRequested
                                            }
                                            getSuggestionValue={suggestion =>
                                              suggestion.unique_id
                                            }
                                            renderSuggestion={
                                              this.renderSuggestion
                                            }
                                            renderSuggestionsContainer={
                                              this.renderSuggestionsContainer
                                            }
                                            inputProps={inputProps}
                                          />
                                        </div>
                                        {noSuggestions && (
                                          <div className="text-center">
                                            No Results
                                          </div>
                                        )}
                                        {this.state.isBestFetching && (
                                          <div>Loading...</div>
                                        )}
                                        {!this.state.isBestFetching &&
                                          this.state.bestSlider.length ===
                                            0 && <div>No data</div>}
                                        {!this.state.isBestFetching &&
                                          this.state.bestSlider.length > 0 && (
                                            <Slider {...settingsSlider}>
                                              {this.state.bestSlider.map(
                                                (value, index) => {
                                                  return (
                                                    <Card
                                                      key={makeHash(3, index)}
                                                    >
                                                      <Card.Img
                                                        variant="top"
                                                        className=""
                                                        src={
                                                          value.imageUrl != null
                                                            ? value.imageUrl
                                                            : require('../../assets/images/empty.png')
                                                        }
                                                      />
                                                      <Card.Title className="text-left">
                                                        <span className="cardBrandModel">
                                                          {value.brand}{' '}
                                                          {value.model}
                                                        </span>
                                                        <span className="cardVersion">
                                                          {value.version_name}
                                                        </span>
                                                      </Card.Title>
                                                      <button
                                                        className="btn btn-danger"
                                                        onClick={() =>
                                                          this.handleChildResult(
                                                            value,
                                                            'fromSlider'
                                                          )
                                                        }
                                                      >
                                                        {blocks.translations !==
                                                          undefined && (
                                                          <>
                                                            {blocks.translations.hasOwnProperty(
                                                              'straddcar'
                                                            ) && (
                                                              <>
                                                                {blocks
                                                                  .translations
                                                                  .straddcar[
                                                                  user.location
                                                                    .country
                                                                ].content !==
                                                                  undefined &&
                                                                blocks
                                                                  .translations
                                                                  .straddcar[
                                                                  user.location
                                                                    .country
                                                                ].content
                                                                  ? parse(
                                                                      blocks
                                                                        .translations
                                                                        .straddcar[
                                                                        user
                                                                          .location
                                                                          .country
                                                                      ].content,
                                                                      {
                                                                        replace: function(
                                                                          domNode
                                                                        ) {
                                                                          if (
                                                                            domNode.hasOwnProperty(
                                                                              'children'
                                                                            )
                                                                          ) {
                                                                            let text =
                                                                              domNode
                                                                                .children
                                                                                .length >
                                                                              0
                                                                                ? domNode
                                                                                    .children[0]
                                                                                    .data
                                                                                : '';
                                                                            return (
                                                                              <>
                                                                                {
                                                                                  text
                                                                                }
                                                                              </>
                                                                            );
                                                                          }

                                                                          return '';
                                                                        }
                                                                      }
                                                                    )
                                                                  : parse(
                                                                      blocks
                                                                        .translations
                                                                        .straddcar[
                                                                        'USA'
                                                                      ].content,
                                                                      {
                                                                        replace: function(
                                                                          domNode
                                                                        ) {
                                                                          if (
                                                                            domNode.hasOwnProperty(
                                                                              'children'
                                                                            )
                                                                          ) {
                                                                            let text =
                                                                              domNode
                                                                                .children
                                                                                .length >
                                                                              0
                                                                                ? domNode
                                                                                    .children[0]
                                                                                    .data
                                                                                : '';
                                                                            return (
                                                                              <>
                                                                                {
                                                                                  text
                                                                                }
                                                                              </>
                                                                            );
                                                                          }

                                                                          return '';
                                                                        }
                                                                      }
                                                                    )}
                                                              </>
                                                            )}
                                                          </>
                                                        )}
                                                      </button>
                                                    </Card>
                                                  );
                                                }
                                              )}
                                            </Slider>
                                          )}
                                      </>
                                    )}
                                    {choosedSegment && (
                                      <>
                                        <div className="searchCarsFeild">
                                          <MultiplyAutoSuggest
                                            id={'general-choosecar'}
                                            country={user}
                                            segment={choosedSegment}
                                            excludeCars={choosedCarsIds}
                                            chooseCar={this.handleChildResult}
                                            placeholder={''}
                                            onChange={this.onChangeMain}
                                            dontChange={this.state.dontChange}
                                          />
                                        </div>
                                        {this.state.multiplySliderFetch && (
                                          <div>Loading...</div>
                                        )}
                                        {!this.state.multiplySliderFetch &&
                                          this.state.additionalSlider.length ===
                                            0 && <div>No data</div>}
                                        {!this.state.multiplySliderFetch &&
                                          this.state.additionalSlider.length >
                                            0 && (
                                            <Slider {...settingsSlider}>
                                              {this.state.additionalSlider.map(
                                                (value, index) => {
                                                  return (
                                                    <Card
                                                      key={makeHash(3, index)}
                                                    >
                                                      <Card.Img
                                                        variant="top"
                                                        src={
                                                          value.imageUrl != null
                                                            ? value.imageUrl
                                                            : require('../../assets/images/empty.png')
                                                        }
                                                      />
                                                      <Card.Title className="text-left">
                                                        <span className="cardBrandModel">
                                                          {value.brand}{' '}
                                                          {value.model}
                                                        </span>
                                                        <span className="cardVersion">
                                                          {value.version_name}
                                                        </span>
                                                      </Card.Title>
                                                      <button
                                                        className="btn btn-danger"
                                                        onClick={() =>
                                                          this.handleChildResult(
                                                            value
                                                          )
                                                        }
                                                      >
                                                        {blocks.translations !==
                                                          undefined && (
                                                          <>
                                                            {blocks.translations.hasOwnProperty(
                                                              'straddcar'
                                                            ) && (
                                                              <>
                                                                {blocks
                                                                  .translations
                                                                  .straddcar[
                                                                  user.location
                                                                    .country
                                                                ].content !==
                                                                  undefined &&
                                                                blocks
                                                                  .translations
                                                                  .straddcar[
                                                                  user.location
                                                                    .country
                                                                ].content
                                                                  ? parse(
                                                                      blocks
                                                                        .translations
                                                                        .straddcar[
                                                                        user
                                                                          .location
                                                                          .country
                                                                      ].content,
                                                                      {
                                                                        replace: function(
                                                                          domNode
                                                                        ) {
                                                                          if (
                                                                            domNode.hasOwnProperty(
                                                                              'children'
                                                                            )
                                                                          ) {
                                                                            let text =
                                                                              domNode
                                                                                .children
                                                                                .length >
                                                                              0
                                                                                ? domNode
                                                                                    .children[0]
                                                                                    .data
                                                                                : '';
                                                                            return (
                                                                              <>
                                                                                {
                                                                                  text
                                                                                }
                                                                              </>
                                                                            );
                                                                          }

                                                                          return '';
                                                                        }
                                                                      }
                                                                    )
                                                                  : parse(
                                                                      blocks
                                                                        .translations
                                                                        .straddcar[
                                                                        'USA'
                                                                      ].content,
                                                                      {
                                                                        replace: function(
                                                                          domNode
                                                                        ) {
                                                                          if (
                                                                            domNode.hasOwnProperty(
                                                                              'children'
                                                                            )
                                                                          ) {
                                                                            let text =
                                                                              domNode
                                                                                .children
                                                                                .length >
                                                                              0
                                                                                ? domNode
                                                                                    .children[0]
                                                                                    .data
                                                                                : '';
                                                                            return (
                                                                              <>
                                                                                {
                                                                                  text
                                                                                }
                                                                              </>
                                                                            );
                                                                          }

                                                                          return '';
                                                                        }
                                                                      }
                                                                    )}
                                                              </>
                                                            )}
                                                          </>
                                                        )}
                                                      </button>
                                                    </Card>
                                                  );
                                                }
                                              )}
                                            </Slider>
                                          )}
                                      </>
                                    )}
                                  </div>
                                </Card.Body>
                              </Card>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </>
            )}

            {cards.length >= 2 && (
              <div className="compareBtnWrap">
                <div className="compareBtn text-center">
                  <Link
                    className=""
                    onClick={e => this.onFetchResults(e)}
                    to={{
                      pathname: '/compare-results',
                      state: { cars: cards }
                    }}
                  >
                    {!isEmpty(blocks.translations) && (
                      <>
                        {blocks.translations.hasOwnProperty('strcompare') && (
                          <>
                            {blocks.translations.strcompare[
                              user.location.country
                            ].content !== undefined &&
                            blocks.translations.strcompare[
                              user.location.country
                            ].content
                              ? parse(
                                  blocks.translations.strcompare[
                                    user.location.country
                                  ].content,
                                  {
                                    replace: function(domNode) {
                                      if (domNode.hasOwnProperty('children')) {
                                        let text =
                                          domNode.children.length > 0
                                            ? domNode.children[0].data
                                            : '';
                                        return <>{text}</>;
                                      }

                                      return '';
                                    }
                                  }
                                )
                              : parse(
                                  blocks.translations.strcompare['USA'].content,
                                  {
                                    replace: function(domNode) {
                                      if (domNode.hasOwnProperty('children')) {
                                        let text =
                                          domNode.children.length > 0
                                            ? domNode.children[0].data
                                            : '';
                                        return <>{text}</>;
                                      }

                                      return '';
                                    }
                                  }
                                )}
                          </>
                        )}
                      </>
                    )}
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
        <SubmitCompare
          show={this.state.showBeforeCompare}
          segment_info={this.props.segment_info}
          user={this.props.user}
          fetchCollection={this.props.fetchCollection}
          choosedSegment={this.state.choosedSegment}
          cards={this.state.cards}
          handleClose={this.handleCloseBeforeCompare}
        />
        <Modal show={this.state.show} onHide={() => this.handleClose(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Do you want to switch segment ?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-row align-items-center">
              <div className="col-auto my-1">
                <button onClick={this.setNewSegment}>Yes</button>
                <button onClick={() => this.handleClose(false)}>No</button>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.handleClose(false)}>
              {!isEmpty(blocks.translations) && (
                <>
                  {blocks.translations.hasOwnProperty('strclose') && (
                    <>
                      {blocks.translations.strclose[user.location.country]
                        .content !== undefined &&
                      blocks.translations.strclose[user.location.country]
                        .content
                        ? parse(
                            blocks.translations.strclose[user.location.country]
                              .content,
                            {
                              replace: function(domNode) {
                                if (domNode.hasOwnProperty('children')) {
                                  let text =
                                    domNode.children.length > 0
                                      ? domNode.children[0].data
                                      : '';
                                  return <>{text}</>;
                                }

                                return '';
                              }
                            }
                          )
                        : parse(blocks.translations.strclose['USA'].content, {
                            replace: function(domNode) {
                              if (domNode.hasOwnProperty('children')) {
                                let text =
                                  domNode.children.length > 0
                                    ? domNode.children[0].data
                                    : '';
                                return <>{text}</>;
                              }

                              return '';
                            }
                          })}
                    </>
                  )}
                </>
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    slider: state.bestseller,
    user: state.user,
    user_info: state.user_info,
    segment_info: state.segment.items,
    segment: state.segment,
    modals: state.modals,
    blocks: state.translations
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchCollection: collection => {
      dispatch(requestResultCollection());

      return fetch(collection)
        .then(response => response.data)
        .then(data => dispatch(receiveResultCollection(data)))
        .catch(error => {
          if (error.response.status === 401) {
            localStorage.removeItem('user');
            window.location = '/';
          }
          let data = {};
          data['payload'] = error.response.data.message;
          data['receivedAt'] = Date.now();
          dispatch({ type: FAIL_GET_RESULT, data });
        });
    },
    handleLoginModal: () => {
      dispatch(dispatchLogin('open'));
    },
    handleRegisterModal: () => {
      dispatch(dispatchRegister('open'));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Compare);
