import {
  REQUEST_SEGMENT_LIST,
  RECEIVE_SEGMENT_LIST,
  REQUEST_SEGMENT_BEST_SELLERS,
  SET_SEGMENT_BEST_SELLERS,
  RECEIVE_TRANSLATION_BLOCKS,
  REQUEST_TRANSLATION_BLOCKS
} from './types';
import axios from 'axios';
import Config from '../../config';

const uri = '/segments-info';
const uri_best_sellers = '/segments-best-sellers';

export const requestSegmentBestSeller = () => {
  return {
    type: REQUEST_SEGMENT_BEST_SELLERS
  };
};

export const requestSegmentList = () => {
  return {
    type: REQUEST_SEGMENT_LIST
  };
};

export const receiveSegmentList = data => {
  return {
    type: RECEIVE_SEGMENT_LIST,
    segment_list: data.segment_list,
    receivedAt: Date.now()
  };
};

export const requestTranslationBlocks = () => {
  return {
    type: REQUEST_TRANSLATION_BLOCKS
  };
};

export const receiveTranslationBlocks = data => {
  return {
    type: RECEIVE_TRANSLATION_BLOCKS,
    blocks: data.blocks,
    receivedAt: Date.now()
  };
};

export const receiveBestSeller = data => {
  return {
    type: SET_SEGMENT_BEST_SELLERS,
    items: data,
    receivedAt: Date.now()
  };
};

export const requestSegments = country => {
  return dispatch => {
    return fetchSegments(country)
      .then(response => response.data)
      .then(data => {
        dispatch(receiveSegmentList(data));
        dispatch(receiveTranslationBlocks(data));
      });
  };
};

const fetchSegments = country => {
  return axios.post(Config.LARAVEL_API.URL + uri, { country: country });
};

const fetchBestSellersForCountry = country => {
  return axios.post(Config.LARAVEL_API.URL + uri_best_sellers, {
    country: country
  });
};

export const fetchAll = (defaultCountry = 'USA') => {
  let country = defaultCountry;
  return (dispatch, getState) => {
    dispatch(requestSegments(country)).then(() => {
      let slides = [];
      Promise.resolve(fetchBestSellersForCountry(country)).then(value => {
        for (let segment in value.data.best_sellers) {
          let slider_slides = Array.prototype.slice
            .call(value.data.best_sellers[segment]['slides'])
            .sort(function(x, y) {
              return (
                Number(x.comparably_equipped_price) -
                Number(y.comparably_equipped_price)
              );
            });

          slides.push({
            best_seller: value.data.best_sellers[segment]['best_seller'][0],
            other: value.data.best_sellers[segment]['other_best_sellers'],
            best_hidden_car: slider_slides[0],
            best_cars: value.data.best_sellers[segment]['slides'],
            segment_name: segment
          });
        }

        dispatch(receiveBestSeller(slides));
      });
    });
  };
};
