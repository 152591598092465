import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import Config from '../../config';
import Service from '../../api';
import { USER_UPDATE_SUCCESS } from '../../redux/users/types';
import {
  success as successAlert,
  error as errorAlert
} from 'react-notification-system-redux';
import { authHeader } from '../../redux/users/services/auth-header';
import { countryShortCode } from '../../functions';
import Constant from '../../constants';

class SubmitPayment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: false,
      show: false
    };
  }

  handleClose = () => {
    this.setState({ show: false }, () => {
      this.props.handleParent();
    });
  };

  componentDidMount() {
    this.setState({ show: this.props.show });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.show !== nextProps.show) {
      this.setState({ show: nextProps.show });
    }

    return true;
  }

  handlePricingPlanChange = e => {
    this.setState({ isFetching: true });
    const pricing_plan = e.target.getAttribute('data-type');
    let service = new Service({ ...authHeader() });
    return service
      .put(Config.LARAVEL_API.URL + '/update-pricing-plan', {
        subscription: pricing_plan
      })
      .then(
        response => {
          let data = response.data;
          let user = data.user;
          this.setState(
            {
              isFetching: false
            },
            () => {
              localStorage.setItem('user', JSON.stringify(user));
              this.props.dispatch({ type: USER_UPDATE_SUCCESS, user });
              this.props.dispatch(
                successAlert({
                  title: '',
                  message: data.message,
                  position: 'br',
                  autoDismiss: 5,
                  action: {}
                })
              );
            }
          );
        },
        error => {
          this.setState(
            {
              isFetching: false
            },
            () => {
              this.props.dispatch(
                errorAlert({
                  title: '',
                  message: 'Error occurred.',
                  position: 'br',
                  autoDismiss: 5,
                  action: {}
                })
              );
            }
          );
        }
      )
      .catch(error => {
        this.setState(
          {
            isFetching: false
          },
          () => {
            this.props.dispatch(
              errorAlert({
                title: '',
                message: 'Error occurred.',
                position: 'br',
                autoDismiss: 5,
                action: {}
              })
            );
          }
        );
      });
  };

  render() {
    const { user } = this.props;
    const { isFetching, show } = this.state;
    let user_info = this.props.user_info.user;
    return (
      <Modal show={show} onHide={this.handleClose} dialogClassName="modal-90w">
        <Modal.Header id="pricing-plan-header" closeButton>
          <h2
            style={{ marginTop: '0px', width: '100%' }}
            className="text-center"
          >
            Choose pricing plan
          </h2>
        </Modal.Header>
        <Modal.Body>
          <div className="pricing row">
            {isFetching ? (
              <div className="cm-spinner" />
            ) : (
              <>
                <div className="col-lg-4">
                  <label style={{ display: 'block' }}>
                    <input
                      type="radio"
                      name="pricing_plan"
                      data-type="basic"
                      className="pricing_plan"
                      value={
                        Constant.pricing_plans.basic[user.location.country]
                      }
                      onChange={this.handlePricingPlanChange}
                    />
                    <div
                      className="card mb-5 mb-lg-0"
                      style={
                        user_info.subscription === 'basic'
                          ? {
                              'margin-top': '-0.25rem',
                              'margin-bottom': '0rem',
                              'box-shadow': '0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3)'
                            }
                          : {}
                      }
                    >
                      <div className="card-body">
                        <h5 className="card-title text-muted text-uppercase text-center">
                          Basic{' '}
                          {user_info.subscription === 'basic'
                            ? '(choosed)'
                            : ''}
                        </h5>
                        <h6 className="card-price text-center">
                          {Constant.pricing_plans.basic[user.location.country]}$
                          <span className="period">/month</span>
                        </h6>
                        {/*<hr />*/}
                        {/*<ul className="fa-ul">*/}
                        {/*  <li>*/}
                        {/*    <span className="fa-li">*/}
                        {/*      <i className="fas fa-check"></i>*/}
                        {/*    </span>*/}
                        {/*    Single User*/}
                        {/*  </li>*/}
                        {/*  <li>*/}
                        {/*    <span className="fa-li">*/}
                        {/*      <i className="fas fa-check"></i>*/}
                        {/*    </span>*/}
                        {/*    5GB Storage*/}
                        {/*  </li>*/}
                        {/*  <li>*/}
                        {/*    <span className="fa-li">*/}
                        {/*      <i className="fas fa-check"></i>*/}
                        {/*    </span>*/}
                        {/*    Unlimited Public Projects*/}
                        {/*  </li>*/}
                        {/*  <li>*/}
                        {/*    <span className="fa-li">*/}
                        {/*      <i className="fas fa-check"></i>*/}
                        {/*    </span>*/}
                        {/*    Community Access*/}
                        {/*  </li>*/}
                        {/*  <li className="text-muted">*/}
                        {/*    <span className="fa-li">*/}
                        {/*      <i className="fas fa-times"></i>*/}
                        {/*    </span>*/}
                        {/*    Unlimited Private Projects*/}
                        {/*  </li>*/}
                        {/*  <li className="text-muted">*/}
                        {/*    <span className="fa-li">*/}
                        {/*      <i className="fas fa-times"></i>*/}
                        {/*    </span>*/}
                        {/*    Dedicated Phone Support*/}
                        {/*  </li>*/}
                        {/*  <li className="text-muted">*/}
                        {/*    <span className="fa-li">*/}
                        {/*      <i className="fas fa-times"></i>*/}
                        {/*    </span>*/}
                        {/*    Free Subdomain*/}
                        {/*  </li>*/}
                        {/*  <li className="text-muted">*/}
                        {/*    <span className="fa-li">*/}
                        {/*      <i className="fas fa-times"></i>*/}
                        {/*    </span>*/}
                        {/*    Monthly Status Reports*/}
                        {/*  </li>*/}
                        {/*</ul>*/}
                      </div>
                    </div>
                  </label>
                </div>
                <div className="col-lg-4">
                  <label style={{ display: 'block' }}>
                    <input
                      type="radio"
                      name="pricing_plan"
                      data-type="comfort"
                      className="pricing_plan"
                      value={
                        Constant.pricing_plans.comfort[user.location.country]
                      }
                      onChange={this.handlePricingPlanChange}
                    />
                    <div
                      className="card mb-5 mb-lg-0"
                      style={
                        user_info.subscription === 'comfort'
                          ? {
                              'margin-top': '-0.25rem',
                              'margin-bottom': '0rem',
                              'box-shadow': '0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3)'
                            }
                          : {}
                      }
                    >
                      <div className="card-body">
                        <h5 className="card-title text-muted text-uppercase text-center">
                          Comfort{' '}
                          {user_info.subscription === 'comfort'
                            ? '(choosed)'
                            : ''}
                        </h5>
                        <h6 className="card-price text-center">
                          {
                            Constant.pricing_plans.comfort[
                              user.location.country
                            ]
                          }
                          $<span className="period">/month</span>
                        </h6>
                        {/*<hr />*/}
                        {/*<ul className="fa-ul">*/}
                        {/*  <li>*/}
                        {/*    <span className="fa-li">*/}
                        {/*      <i className="fas fa-check"></i>*/}
                        {/*    </span>*/}
                        {/*    <strong>5 Users</strong>*/}
                        {/*  </li>*/}
                        {/*  <li>*/}
                        {/*    <span className="fa-li">*/}
                        {/*      <i className="fas fa-check"></i>*/}
                        {/*    </span>*/}
                        {/*    50GB Storage*/}
                        {/*  </li>*/}
                        {/*  <li>*/}
                        {/*    <span className="fa-li">*/}
                        {/*      <i className="fas fa-check"></i>*/}
                        {/*    </span>*/}
                        {/*    Unlimited Public Projects*/}
                        {/*  </li>*/}
                        {/*  <li>*/}
                        {/*    <span className="fa-li">*/}
                        {/*      <i className="fas fa-check"></i>*/}
                        {/*    </span>*/}
                        {/*    Community Access*/}
                        {/*  </li>*/}
                        {/*  <li>*/}
                        {/*    <span className="fa-li">*/}
                        {/*      <i className="fas fa-check"></i>*/}
                        {/*    </span>*/}
                        {/*    Unlimited Private Projects*/}
                        {/*  </li>*/}
                        {/*  <li>*/}
                        {/*    <span className="fa-li">*/}
                        {/*      <i className="fas fa-check"></i>*/}
                        {/*    </span>*/}
                        {/*    Dedicated Phone Support*/}
                        {/*  </li>*/}
                        {/*  <li>*/}
                        {/*    <span className="fa-li">*/}
                        {/*      <i className="fas fa-check"></i>*/}
                        {/*    </span>*/}
                        {/*    Free Subdomain*/}
                        {/*  </li>*/}
                        {/*  <li className="text-muted">*/}
                        {/*    <span className="fa-li">*/}
                        {/*      <i className="fas fa-times"></i>*/}
                        {/*    </span>*/}
                        {/*    Monthly Status Reports*/}
                        {/*  </li>*/}
                        {/*</ul>*/}
                      </div>
                    </div>
                  </label>
                </div>
                <div className="col-lg-4">
                  <label style={{ display: 'block' }}>
                    <input
                      type="radio"
                      name="pricing_plan"
                      data-type="premium"
                      className="pricing_plan"
                      value={
                        Constant.pricing_plans.premium[user.location.country]
                      }
                      onChange={this.handlePricingPlanChange}
                    />
                    <div
                      className="card"
                      style={
                        user_info.subscription === 'premium'
                          ? {
                              'margin-top': '-0.25rem',
                              'margin-bottom': '0rem',
                              'box-shadow': '0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3)'
                            }
                          : {}
                      }
                    >
                      <div className="card-body">
                        <h5 className="card-title text-muted text-uppercase text-center">
                          Premium{' '}
                          {user_info.subscription === 'premium'
                            ? '(choosed)'
                            : ''}
                        </h5>
                        <h6 className="card-price text-center">
                          {
                            Constant.pricing_plans.premium[
                              user.location.country
                            ]
                          }
                          $<span className="period">/month</span>
                        </h6>
                        {/*<hr />*/}
                        {/*<ul className="fa-ul">*/}
                        {/*  <li>*/}
                        {/*    <span className="fa-li">*/}
                        {/*      <i className="fas fa-check"></i>*/}
                        {/*    </span>*/}
                        {/*    <strong>Unlimited Users</strong>*/}
                        {/*  </li>*/}
                        {/*  <li>*/}
                        {/*    <span className="fa-li">*/}
                        {/*      <i className="fas fa-check"></i>*/}
                        {/*    </span>*/}
                        {/*    150GB Storage*/}
                        {/*  </li>*/}
                        {/*  <li>*/}
                        {/*    <span className="fa-li">*/}
                        {/*      <i className="fas fa-check"></i>*/}
                        {/*    </span>*/}
                        {/*    Unlimited Public Projects*/}
                        {/*  </li>*/}
                        {/*  <li>*/}
                        {/*    <span className="fa-li">*/}
                        {/*      <i className="fas fa-check"></i>*/}
                        {/*    </span>*/}
                        {/*    Community Access*/}
                        {/*  </li>*/}
                        {/*  <li>*/}
                        {/*    <span className="fa-li">*/}
                        {/*      <i className="fas fa-check"></i>*/}
                        {/*    </span>*/}
                        {/*    Unlimited Private Projects*/}
                        {/*  </li>*/}
                        {/*  <li>*/}
                        {/*    <span className="fa-li">*/}
                        {/*      <i className="fas fa-check"></i>*/}
                        {/*    </span>*/}
                        {/*    Dedicated Phone Support*/}
                        {/*  </li>*/}
                        {/*  <li>*/}
                        {/*    <span className="fa-li">*/}
                        {/*      <i className="fas fa-check"></i>*/}
                        {/*    </span>*/}
                        {/*    <strong>Unlimited</strong> Free Subdomains*/}
                        {/*  </li>*/}
                        {/*  <li>*/}
                        {/*    <span className="fa-li">*/}
                        {/*      <i className="fas fa-check"></i>*/}
                        {/*    </span>*/}
                        {/*    Monthly Status Reports*/}
                        {/*  </li>*/}
                        {/*</ul>*/}
                      </div>
                    </div>
                  </label>
                </div>
                {user_info.subscription !== null && (
                  <form
                    className="col-lg-12 text-center"
                    action="https://secure.cardcom.solutions/Interface/LowProfile.aspx"
                    method="POST"
                    enctype="application/x-www-form-urlencoded"
                  >
                    <input type="hidden" name="TerminalNumber" value="1000" />
                    <input
                      type="hidden"
                      name="UserName"
                      value="kzFKfohEvL6AOF8aMEJz"
                    />
                    <input type="hidden" name="APILevel" value="10" />
                    <input type="hidden" name="codepage" value="65001" />
                    <input type="hidden" name="Operation" value="1" />
                    <input type="hidden" name="AutoRedirect" value="true" />
                    <input
                      type="hidden"
                      name="Language"
                      value={countryShortCode(user.location.country)}
                    />
                    <input type="hidden" name="CoinID" value="2" />
                    <input
                      type="hidden"
                      name="SumToBill"
                      value={
                        Constant.pricing_plans[user_info.subscription][
                          user.location.country
                        ]
                      }
                    />
                    <input
                      type="hidden"
                      name="ProductName"
                      value={
                        user_info.subscription.charAt(0).toUpperCase() +
                        user_info.subscription.slice(1) +
                        ' subscription'
                      }
                    />

                    <input
                      type="hidden"
                      name="SuccessRedirectUrl"
                      value={Config.STAGING_URL.URL + '/payment-verified'}
                    />
                    <input
                      type="hidden"
                      name="ErrorRedirectUrl"
                      value={Config.STAGING_URL.URL + '/payment-verified/1'}
                    />
                    <input type="hidden" name="CancelType" value="2" />
                    <input
                      type="hidden"
                      name="CancelUrl"
                      value={Config.STAGING_URL.URL + '/payment-verified'}
                    />
                    <input
                      type="hidden"
                      name="IndicatorUrl"
                      value={Config.STAGING_URL.URL + '/payment-verified'}
                    />
                    <input type="hidden" name="MaxNumOfPayments" value="1" />
                    <input
                      type="hidden"
                      name="InvoiceHead.CustName"
                      value={user_info.name}
                    />
                    <input
                      type="hidden"
                      name="InvoiceHead.SendByEmail"
                      value="true"
                    />
                    <input
                      type="hidden"
                      name="InvoiceHead.Language"
                      value={countryShortCode(user.location.country)}
                    />
                    <input type="hidden" name="InvoiceHead.CoinID" value="2" />
                    <input
                      type="hidden"
                      name="InvoiceHead.Email"
                      value={user_info.email}
                    />
                    <input
                      type="hidden"
                      name="InvoiceHead.ExtIsVatFree"
                      value={user.location.country === 'Israel' ? false : true}
                    />
                    <input type="hidden" name="IsCreateInvoice" value="true" />
                    <input
                      type="hidden"
                      name="InvoiceLines1.Description"
                      value="Monthly subscription"
                    />
                    <input
                      type="hidden"
                      name="InvoiceLines1.Price"
                      value={
                        Constant.pricing_plans[user_info.subscription][
                          user.location.country
                        ]
                      }
                    />
                    <input
                      type="hidden"
                      name="InvoiceLines1.Quantity"
                      value="1"
                    />
                    <button
                      className="btn btn-lg btn-success"
                      style={{ fontSize: '1.5rem' }}
                    >
                      Proceed with payment
                    </button>
                  </form>
                )}
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer id="pricing-plan-footer">
          <button onClick={this.handleClose} className="btn btn-primary">
            Close
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const { user, user_info } = state;
  return {
    user,
    user_info
  };
};

export default connect(mapStateToProps, null)(SubmitPayment);
