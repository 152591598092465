import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dealer from '../blocks/Dealer.js';
import Filter from '../filters/Filter';
import {
  requestDealersCollection,
  receiveDealersCollection,
  fetch
} from '../../redux/dealers/index';
import { FAIL_GET_RESULT } from '../../redux/dealers/types';
import Pagination from '../pagination/Pagination';
import { isEmpty } from '../../functions';
import parse from 'html-react-parser';

class FindDealer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalRecords: '',
      totalPages: '',
      pageLimit: '',
      currentPage: '',
      startIndex: '',
      endIndex: '',
      country: this.props.user.location.country
    };
  }

  componentDidMount() {
    document.body.className = '';
    document.body.classList.add('finddealer');

    if (Object.keys(this.props.dealers).length === 1) {
      this.props.fetchCollection({
        id: null,
        country: this.props.user.location.country,
        uc: this.props.user.location.lat + '|' + this.props.user.location.lon
      });
    }
  }

  showTable = (rowsPerPage, pageLimit, dealers) => {
    if (dealers.dealers.length > 0) {
      return (
        <>
          <div className="col-12 height985">
            {rowsPerPage.map((value, index) => {
              return (
                <Dealer
                  key={index}
                  data={value.dealer}
                  brands_array={value.brands_array}
                  cities_array={value.cities_array}
                />
              );
            })}
          </div>

          <div className="text-center">
            <Pagination
              totalRecords={dealers.dealers.length}
              pageLimit={pageLimit || 5}
              initialPage={1}
              pagesToShow={5}
              onChangePage={this.onChangePage}
            />
          </div>
        </>
      );
    }

    return <p className="text-center">No records for this matched criterias</p>;
  };

  onChangePage = data => {
    this.setState({
      pageLimit: data.pageLimit,
      totalPages: data.totalPages,
      currentPage: data.page,
      startIndex: data.startIndex,
      endIndex: data.endIndex
    });
  };

  render() {
    let { dealers, user, blocks } = this.props;
    let { pageLimit, startIndex, endIndex } = this.state;

    if (dealers.isFetching) {
      return <div className="cm-spinner" />;
    }

    if (dealers.hasOwnProperty('message')) {
      return (
        <div className="findDealerWrapper">
          <div className="d-flex align-items-center justify-content-center">
            <h1>
              {!isEmpty(blocks.translations) && (
                <>
                  {blocks.translations.hasOwnProperty('strfinddealer') && (
                    <>
                      {blocks.translations.strfinddealer[user.location.country]
                        .content !== undefined &&
                      blocks.translations.strfinddealer[user.location.country]
                        .content
                        ? parse(
                            blocks.translations.strfinddealer[
                              user.location.country
                            ].content,
                            {
                              replace: function(domNode) {
                                if (domNode.hasOwnProperty('children')) {
                                  let text =
                                    domNode.children.length > 0
                                      ? domNode.children[0].data
                                      : '';
                                  let second_text = domNode.children[1].hasOwnProperty(
                                    'children'
                                  )
                                    ? domNode.children[1].children[0].data
                                    : '';
                                  return (
                                    <>
                                      {text}
                                      <span className="cbddRed">
                                        {second_text}
                                      </span>
                                    </>
                                  );
                                }

                                return '';
                              }
                            }
                          )
                        : parse(
                            blocks.translations.strfinddealer['USA'].content,
                            {
                              replace: function(domNode) {
                                if (domNode.hasOwnProperty('children')) {
                                  let text =
                                    domNode.children.length > 0
                                      ? domNode.children[0].data
                                      : '';
                                  let second_text = domNode.children[1].hasOwnProperty(
                                    'children'
                                  )
                                    ? domNode.children[1].children[0].data
                                    : '';
                                  return (
                                    <>
                                      {text}
                                      <span className="cbddRed">
                                        {second_text}
                                      </span>
                                    </>
                                  );
                                }

                                return '';
                              }
                            }
                          )}
                    </>
                  )}
                </>
              )}
            </h1>
          </div>
          <div className="findDealerContent">
            <div className="container-fluid">
              <div className="row no-gutters justify-content-between text-danger">
                <h4 className="col-12 text-center">{dealers.message}</h4>
              </div>
            </div>
          </div>
        </div>
      );
    }

    let rowsPerPage = [];
    if (!dealers.isFetching && dealers.hasOwnProperty('dealers')) {
      rowsPerPage = dealers.dealers.slice(startIndex, endIndex + 1);
    }

    return (
      <>
        <div className="findDealerWrapper">
          <div className="d-flex align-items-center justify-content-center">
            <h1>
              {!isEmpty(blocks.translations) && (
                <>
                  {blocks.translations.hasOwnProperty('strfinddealer') && (
                    <>
                      {blocks.translations.strfinddealer[user.location.country]
                        .content !== undefined &&
                      blocks.translations.strfinddealer[user.location.country]
                        .content
                        ? parse(
                            blocks.translations.strfinddealer[
                              user.location.country
                            ].content,
                            {
                              replace: function(domNode) {
                                if (domNode.hasOwnProperty('children')) {
                                  let text =
                                    domNode.children.length > 0
                                      ? domNode.children[0].data
                                      : '';
                                  let second_text = domNode.children[1].hasOwnProperty(
                                    'children'
                                  )
                                    ? domNode.children[1].children[0].data
                                    : '';
                                  return (
                                    <>
                                      {text}
                                      <span className="cbddRed">
                                        {second_text}
                                      </span>
                                    </>
                                  );
                                }

                                return '';
                              }
                            }
                          )
                        : parse(
                            blocks.translations.strfinddealer['USA'].content,
                            {
                              replace: function(domNode) {
                                if (domNode.hasOwnProperty('children')) {
                                  let text =
                                    domNode.children.length > 0
                                      ? domNode.children[0].data
                                      : '';
                                  let second_text = domNode.children[1].hasOwnProperty(
                                    'children'
                                  )
                                    ? domNode.children[1].children[0].data
                                    : '';
                                  return (
                                    <>
                                      {text}
                                      <span className="cbddRed">
                                        {second_text}
                                      </span>
                                    </>
                                  );
                                }

                                return '';
                              }
                            }
                          )}
                    </>
                  )}
                </>
              )}
            </h1>
          </div>
          <div className="findDealerContent">
            <div className="container-fluid">
              <div className="row no-gutters justify-content-between padding20">
                {dealers.hasOwnProperty('brands') &&
                  dealers.hasOwnProperty('cities') && (
                    <div className="col-auto">
                      <Filter user={this.props.user} dealers={dealers} />
                    </div>
                  )}
                <div className="col-auto">
                  <div id="dealers">
                    {dealers.hasOwnProperty('dealers') && (
                      <>{this.showTable(rowsPerPage, pageLimit, dealers)}</>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    dealers: state.dealers,
    blocks: state.translations
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchCollection: collection => {
      dispatch(requestDealersCollection());
      return fetch(collection)
        .then(response => response.data)
        .then(data => dispatch(receiveDealersCollection(data)))
        .catch(error => {
          let data = {};
          let res = error.response;
          data['payload'] = res ? res.data.message : [];
          data['receivedAt'] = Date.now();
          dispatch({ type: FAIL_GET_RESULT, data });
        });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FindDealer);
