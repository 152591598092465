import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Home from './components/pages/Home';
import About from './components/pages/About';
import FindDealer from './components/pages/FindDealer';
import HowItWorks from './components/pages/HowItWorks';
import Compare from './components/pages/Compare';
import CompareResult from './components/pages/CompareResult';
import FreeBestComparsion from './components/pages/FreeBestComparsion';
import TermsOfService from './components/pages/TermsOfService';
import Confirm from './components/pages/Confirm';
import Reset from './components/pages/Reset';
import ResetPassword from './components/pages/ResetPassword';
import PersonalPage from './components/pages/PersonalPage';
import PaymentVerified from './components/pages/PaymentVerified';
import NotFound from './components/pages/NotFound';
import PrivacyPolicy from './components/pages/privacyPolicy';

export const Web = () => (
  <div>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route
        exact
        path="/find-a-deale"
        render={props => <FindDealer {...props} />}
      />
      <Route exact path="/how-it-works" component={HowItWorks} />
      <Route exact path="/about-cbdd" component={About} />
      <Route
        exact
        path="/demo-compare"
        render={props => <FreeBestComparsion {...props} />}
      />
      <Route
        exact
        path="/compare/:segmentName?/:car?"
        render={props => <Compare {...props} />}
      />
      <Route
        exact
        path="/terms-of-service"
        render={props => <TermsOfService {...props} />}
      />
      <Route
        exact
        path="/privacy-policy"
        render={props => <PrivacyPolicy {...props} />}
      />
      <Route
        exact
        path="/confirm/:token?"
        render={props => <Confirm {...props} />}
      />
      <Route exact path="/reset" render={props => <Reset {...props} />} />
      <Route
        exact
        path="/reset-password/:token"
        render={props => <ResetPassword {...props} />}
      />
      <Route
        exact
        path="/compare-results"
        render={props =>
          localStorage.getItem('user') ? (
            <CompareResult {...props} />
          ) : (
            <Redirect to={{ pathname: '/' }} />
          )
        }
      />
      <Route
        exact
        path="/personal-info"
        render={props =>
          localStorage.getItem('user') ? (
            <PersonalPage {...props} />
          ) : (
            <Redirect to={{ pathname: '/' }} />
          )
        }
      />
      <Route
        exact
        path="/payment-verified"
        render={props =>
          localStorage.getItem('user') ? (
            <PaymentVerified {...props} />
          ) : (
            <Redirect to={{ pathname: '/' }} />
          )
        }
      />
      <Route exact path="*" component={NotFound} />
    </Switch>
  </div>
);
