import React, { Component } from 'react';
import { getCookie, setCookie } from '../../redux/users/index';

class CookiePopUp extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: !getCookie('license') ? true : false
    };
  }

  handleClose = () => {
    setCookie('license', 1, 7);
    this.setState({ show: false });
  };

  render() {
    const { show } = this.state;
    return (
      <div className="cookies" style={{ display: show ? 'block' : 'none' }}>
        <div className="container">
          <div className="col-sm-12">
            We use cookies to help us give you the best experience on our
            website.
            <p
              onClick={() => this.handleClose()}
              className="close-cookie-warning"
            >
              <span>×</span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default CookiePopUp;
