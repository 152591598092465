import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import Config from '../../config';
import { FormErrors } from './FormErrors';
import { isEmpty } from '../../functions';
import parse from 'html-react-parser';

class Dealer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      full_name: null,
      email: null,
      phone_number: null,
      preferred_date: new Date(),
      option: false,
      errors: {
        fullName: '',
        email: '',
        phone: '',
        preferredDate: '',
        option: ''
      },
      formValid: false,
      fullNameValid: false,
      emailValid: false,
      phoneValid: false,
      preferredDateValid: false,
      optionValid: false
    };
  }

  handleShow = () => {
    this.setState({ show: true });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleUserInput = e => {
    const name = e.target.name;
    const value = e.target.value;
    const checked = e.target.checked;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value, checked);
    });
  };

  showBadges = (list = []) => {
    let new_list = [];
    for (let item in list) {
      new_list.push(
        <span className="badge badge-secondary">{list[item]}</span>
      );
    }

    return new_list;
  };

  validateField = (fieldName, value, checked) => {
    let fieldValidationErrors = this.state.errors;
    let emailValid = this.state.emailValid;
    let fullNameValid = this.state.fullNameValid;
    let phoneValid = this.state.phoneValid;
    let optionValid = this.state.optionValid;

    switch (fieldName) {
      case 'full_name':
        fullNameValid = true;
        fieldValidationErrors.fullName = '';

        if (value === '') {
          phoneValid = false;
          fieldValidationErrors.fullName += 'Please enter Full Name.';
        }

        if (value.length > 64 && value !== '') {
          fullNameValid = false;
          fieldValidationErrors.fullName +=
            'No more then 64 character is allowed in Full Name.';
        }

        if (!value.match(/^[а-яa-z\u0590-\u05fe ]+$/iu) && value !== '') {
          fullNameValid = false;
          fieldValidationErrors.fullName +=
            'Only russian, hebrew and english characters are allowed in Full Name.';
        }
        break;
      case 'email':
        emailValid = true;
        fieldValidationErrors.email = '';
        if (value === '') {
          emailValid = false;
          fieldValidationErrors.email += 'Please enter Email.';
        }

        if (
          !value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) &&
          value !== ''
        ) {
          emailValid = false;
          fieldValidationErrors.email += 'Email is invalid.';
        }
        break;
      case 'phone_number':
        phoneValid = true;
        fieldValidationErrors.phone = '';
        if (value === '') {
          phoneValid = false;
          fieldValidationErrors.phone += 'Please enter phone number.';
        }

        if (value.length > 32 && value !== '') {
          phoneValid = false;
          fieldValidationErrors.phone +=
            'No more then 32 character is allowed in phone.';
        }

        if (!value.match(/^[\d ()+-]+$/) && value !== '') {
          phoneValid = false;
          fieldValidationErrors.phone +=
            'Phone number is invalid. Example: +1(850) 555-1234';
        }

        break;
      case 'preferred_date':
        break;
      case 'option':
        optionValid = checked;
        fieldValidationErrors.option = optionValid
          ? ''
          : 'Please agree on terms';
        break;
      default:
        break;
    }

    this.setState(
      {
        errors: fieldValidationErrors,
        fullNameValid: fullNameValid,
        emailValid: emailValid,
        phoneValid: phoneValid,
        optionValid: optionValid,
        option: optionValid
      },
      this.validateForm
    );
  };

  validateForm = () => {
    this.setState({
      formValid:
        this.state.fullNameValid &&
        this.state.emailValid &&
        this.state.phoneValid &&
        this.state.optionValid
    });
  };

  handleChange = momentDate => {
    this.setState({
      preferredDate: momentDate
    });
  };

  sendRequest = (e, dealer_id) => {
    e.preventDefault();
    const { full_name, email, phone_number, preferred_date } = this.state;
    let errors = document.querySelector('.errors-block');
    let form = document.querySelector('.main-form');
    let verbose = document.querySelector('.process-submission');

    errors.style.display = 'none';
    form.style.display = 'none';
    verbose.style.display = 'block';
    let date =
      preferred_date.getFullYear() +
      '-' +
      ('0' + (preferred_date.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + preferred_date.getDate()).slice(-2) +
      ' ' +
      preferred_date.getHours() +
      ':' +
      preferred_date.getMinutes() +
      ':' +
      preferred_date.getSeconds();

    axios
      .post(Config.LARAVEL_API.URL + '/leads', {
        full_name: full_name,
        email: email,
        phone_number: phone_number,
        preferred_date: date,
        dealer_id: dealer_id
      })
      .then(function(response) {
        verbose.innerText = 'We will contact you soon. Please stay in touch!';
        setTimeout(() => {
          errors.style.display = 'block';
          form.style.display = 'block';
          verbose.style.display = 'none';
        }, 2000);
      })
      .catch(function(error) {
        verbose.innerText = 'Error occured during form submission.';
        setTimeout(() => {
          errors.style.display = 'block';
          form.style.display = 'block';
          verbose.style.display = 'none';
        }, 2000);
      });

    this.setState({
      full_name: '',
      email: '',
      phone_number: '',
      preferred_date: new Date(),
      option: false,
      errors: {
        fullName: '',
        email: '',
        phone: '',
        preferredDate: '',
        option: ''
      },
      formValid: false,
      fullNameValid: false,
      emailValid: false,
      phoneValid: false,
      preferredDateValid: false,
      optionValid: false
    });
  };

  handleOptionState = () => {};

  errorClass(error) {
    return error.length === 0 ? '' : 'has-error';
  }

  render() {
    const {
      full_name,
      email,
      phone_number,
      preferred_date,
      option,
      errors,
      formValid
    } = this.state;
    const { data, user, brands_array, cities_array, blocks } = this.props;
    return (
      <>
        <Card>
          <Card.Body className="d-flex justify-content-between align-items-center">
            <Card.Img src={require('../../assets/images/logoHeader.png')} />
            <div className="flex-grow-1">
              <Card.Text>{data.name}</Card.Text>
              <Card.Text>{data.company_name}</Card.Text>
              <Card.Text>{data.address}</Card.Text>
              <Card.Text>
                {this.showBadges(cities_array)} |{' '}
                {this.showBadges(brands_array)}
              </Card.Text>
            </div>
            <div>
              <Button onClick={this.handleShow}>
                {!isEmpty(blocks.translations) && (
                  <>
                    {blocks.translations.hasOwnProperty(
                      'strscheduletestdrive'
                    ) && (
                      <>
                        {blocks.translations.strscheduletestdrive[
                          user.location.country
                        ].content !== undefined &&
                        blocks.translations.strscheduletestdrive[
                          user.location.country
                        ].content
                          ? parse(
                              blocks.translations.strscheduletestdrive[
                                user.location.country
                              ].content,
                              {
                                replace: function(domNode) {
                                  if (domNode.hasOwnProperty('children')) {
                                    let text =
                                      domNode.children.length > 0
                                        ? domNode.children[0].data
                                        : '';
                                    return <>{text}</>;
                                  }

                                  return '';
                                }
                              }
                            )
                          : parse(
                              blocks.translations.strscheduletestdrive['USA']
                                .content,
                              {
                                replace: function(domNode) {
                                  if (domNode.hasOwnProperty('children')) {
                                    let text =
                                      domNode.children.length > 0
                                        ? domNode.children[0].data
                                        : '';
                                    return <>{text}</>;
                                  }

                                  return '';
                                }
                              }
                            )}
                      </>
                    )}
                  </>
                )}
              </Button>
            </div>
          </Card.Body>
        </Card>
        {!user.isFetching && (
          <Modal id={data.id} show={this.state.show} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                {!isEmpty(blocks.translations) && (
                  <>
                    {blocks.translations.hasOwnProperty('strfillformbelow') && (
                      <>
                        {blocks.translations.strfillformbelow[
                          user.location.country
                        ].content !== undefined &&
                        blocks.translations.strfillformbelow[
                          user.location.country
                        ].content
                          ? parse(
                              blocks.translations.strfillformbelow[
                                user.location.country
                              ].content,
                              {
                                replace: function(domNode) {
                                  if (domNode.hasOwnProperty('children')) {
                                    let text =
                                      domNode.children.length > 0
                                        ? domNode.children[0].data
                                        : '';
                                    return <>{text}</>;
                                  }

                                  return '';
                                }
                              }
                            )
                          : parse(
                              blocks.translations.strfillformbelow['USA']
                                .content,
                              {
                                replace: function(domNode) {
                                  if (domNode.hasOwnProperty('children')) {
                                    let text =
                                      domNode.children.length > 0
                                        ? domNode.children[0].data
                                        : '';
                                    return <>{text}</>;
                                  }

                                  return '';
                                }
                              }
                            )}
                      </>
                    )}
                  </>
                )}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div style={{ display: 'none' }} className="process-submission">
                Processing submission ...
              </div>
              <div className="errors-block panel panel-default">
                <FormErrors formErrors={errors} />
              </div>
              <div className="main-form form-row align-items-center">
                <div className="col-auto my-1">
                  <form>
                    <div
                      className={`form-group  ${this.errorClass(
                        errors.fullName
                      )}`}
                    >
                      <label htmlFor="FullName">
                        {!isEmpty(blocks.translations) && (
                          <>
                            {blocks.translations.hasOwnProperty(
                              'strfullname'
                            ) && (
                              <>
                                {blocks.translations.strfullname[
                                  user.location.country
                                ].content !== undefined &&
                                blocks.translations.strfullname[
                                  user.location.country
                                ].content
                                  ? parse(
                                      blocks.translations.strfullname[
                                        user.location.country
                                      ].content,
                                      {
                                        replace: function(domNode) {
                                          if (
                                            domNode.hasOwnProperty('children')
                                          ) {
                                            let text =
                                              domNode.children.length > 0
                                                ? domNode.children[0].data
                                                : '';
                                            return <>{text}</>;
                                          }

                                          return '';
                                        }
                                      }
                                    )
                                  : parse(
                                      blocks.translations.strfullname['USA']
                                        .content,
                                      {
                                        replace: function(domNode) {
                                          if (
                                            domNode.hasOwnProperty('children')
                                          ) {
                                            let text =
                                              domNode.children.length > 0
                                                ? domNode.children[0].data
                                                : '';
                                            return <>{text}</>;
                                          }

                                          return '';
                                        }
                                      }
                                    )}
                              </>
                            )}
                          </>
                        )}
                        *
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="full_name"
                        id="FullName"
                        placeholder="Enter Full Name"
                        value={full_name}
                        onChange={this.handleUserInput}
                        required
                      />
                    </div>
                    <div
                      className={`form-group  ${this.errorClass(errors.email)}`}
                    >
                      <label htmlFor="Email">
                        {!isEmpty(blocks.translations) && (
                          <>
                            {blocks.translations.hasOwnProperty('stremail') && (
                              <>
                                {blocks.translations.stremail[
                                  user.location.country
                                ].content !== undefined &&
                                blocks.translations.stremail[
                                  user.location.country
                                ].content
                                  ? parse(
                                      blocks.translations.stremail[
                                        user.location.country
                                      ].content,
                                      {
                                        replace: function(domNode) {
                                          if (
                                            domNode.hasOwnProperty('children')
                                          ) {
                                            let text =
                                              domNode.children.length > 0
                                                ? domNode.children[0].data
                                                : '';
                                            return <>{text}</>;
                                          }

                                          return '';
                                        }
                                      }
                                    )
                                  : parse(
                                      blocks.translations.stremail['USA']
                                        .content,
                                      {
                                        replace: function(domNode) {
                                          if (
                                            domNode.hasOwnProperty('children')
                                          ) {
                                            let text =
                                              domNode.children.length > 0
                                                ? domNode.children[0].data
                                                : '';
                                            return <>{text}</>;
                                          }

                                          return '';
                                        }
                                      }
                                    )}
                              </>
                            )}
                          </>
                        )}
                        *
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="Email"
                        placeholder="Email"
                        value={email}
                        onChange={this.handleUserInput}
                        required
                      />
                    </div>
                    <div
                      className={`form-group  ${this.errorClass(errors.phone)}`}
                    >
                      <label htmlFor="Phone">
                        {!isEmpty(blocks.translations) && (
                          <>
                            {blocks.translations.hasOwnProperty('strphone') && (
                              <>
                                {blocks.translations.strphone[
                                  user.location.country
                                ].content !== undefined &&
                                blocks.translations.strphone[
                                  user.location.country
                                ].content
                                  ? parse(
                                      blocks.translations.strphone[
                                        user.location.country
                                      ].content,
                                      {
                                        replace: function(domNode) {
                                          if (
                                            domNode.hasOwnProperty('children')
                                          ) {
                                            let text =
                                              domNode.children.length > 0
                                                ? domNode.children[0].data
                                                : '';
                                            return <>{text}</>;
                                          }

                                          return '';
                                        }
                                      }
                                    )
                                  : parse(
                                      blocks.translations.strphone['USA']
                                        .content,
                                      {
                                        replace: function(domNode) {
                                          if (
                                            domNode.hasOwnProperty('children')
                                          ) {
                                            let text =
                                              domNode.children.length > 0
                                                ? domNode.children[0].data
                                                : '';
                                            return <>{text}</>;
                                          }

                                          return '';
                                        }
                                      }
                                    )}
                              </>
                            )}
                          </>
                        )}
                        *
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="Phone"
                        name="phone_number"
                        placeholder="Enter phone number"
                        onChange={this.handleUserInput}
                        value={phone_number}
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="preferredDate">
                        {!isEmpty(blocks.translations) && (
                          <>
                            {blocks.translations.hasOwnProperty(
                              'strpreferreddate'
                            ) && (
                              <>
                                {blocks.translations.strpreferreddate[
                                  user.location.country
                                ].content !== undefined &&
                                blocks.translations.strpreferreddate[
                                  user.location.country
                                ].content
                                  ? parse(
                                      blocks.translations.strpreferreddate[
                                        user.location.country
                                      ].content,
                                      {
                                        replace: function(domNode) {
                                          if (
                                            domNode.hasOwnProperty('children')
                                          ) {
                                            let text =
                                              domNode.children.length > 0
                                                ? domNode.children[0].data
                                                : '';
                                            return <>{text}</>;
                                          }

                                          return '';
                                        }
                                      }
                                    )
                                  : parse(
                                      blocks.translations.strpreferreddate[
                                        'USA'
                                      ].content,
                                      {
                                        replace: function(domNode) {
                                          if (
                                            domNode.hasOwnProperty('children')
                                          ) {
                                            let text =
                                              domNode.children.length > 0
                                                ? domNode.children[0].data
                                                : '';
                                            return <>{text}</>;
                                          }

                                          return '';
                                        }
                                      }
                                    )}
                              </>
                            )}
                          </>
                        )}
                      </label>
                      <DatePicker
                        id="preferredDate"
                        name="preferred_date"
                        className="red-border"
                        selected={preferred_date}
                        onChange={this.handleChange}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat="MMMM d, yyyy h:mm aa"
                        timeCaption="time"
                      />
                    </div>

                    <div className="form-group">
                      <div className="form-check">
                        <label className="form-check-label">
                          <input
                            type="checkbox"
                            name="option"
                            className="form-check-input"
                            defaultChecked={option}
                            onChange={this.handleUserInput}
                            required
                          />{' '}
                          {!isEmpty(blocks.translations) && (
                            <>
                              {blocks.translations.hasOwnProperty(
                                'strapprovecontact'
                              ) && (
                                <>
                                  {blocks.translations.strapprovecontact[
                                    user.location.country
                                  ].content !== undefined &&
                                  blocks.translations.strapprovecontact[
                                    user.location.country
                                  ].content
                                    ? parse(
                                        blocks.translations.strapprovecontact[
                                          user.location.country
                                        ].content,
                                        {
                                          replace: function(domNode) {
                                            if (
                                              domNode.hasOwnProperty('children')
                                            ) {
                                              let text =
                                                domNode.children.length > 0
                                                  ? domNode.children[0].data
                                                  : '';
                                              return <>{text}</>;
                                            }

                                            return '';
                                          }
                                        }
                                      )
                                    : parse(
                                        blocks.translations.strapprovecontact[
                                          'USA'
                                        ].content,
                                        {
                                          replace: function(domNode) {
                                            if (
                                              domNode.hasOwnProperty('children')
                                            ) {
                                              let text =
                                                domNode.children.length > 0
                                                  ? domNode.children[0].data
                                                  : '';
                                              return <>{text}</>;
                                            }

                                            return '';
                                          }
                                        }
                                      )}
                                </>
                              )}
                            </>
                          )}
                        </label>
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={!formValid}
                      onClick={e => this.sendRequest(e, data.id)}
                    >
                      {!isEmpty(blocks.translations) && (
                        <>
                          {blocks.translations.hasOwnProperty(
                            'strsendrequest'
                          ) && (
                            <>
                              {blocks.translations.strsendrequest[
                                user.location.country
                              ].content !== undefined &&
                              blocks.translations.strsendrequest[
                                user.location.country
                              ].content
                                ? parse(
                                    blocks.translations.strsendrequest[
                                      user.location.country
                                    ].content,
                                    {
                                      replace: function(domNode) {
                                        if (
                                          domNode.hasOwnProperty('children')
                                        ) {
                                          let text =
                                            domNode.children.length > 0
                                              ? domNode.children[0].data
                                              : '';
                                          return <>{text}</>;
                                        }

                                        return '';
                                      }
                                    }
                                  )
                                : parse(
                                    blocks.translations.strsendrequest['USA']
                                      .content,
                                    {
                                      replace: function(domNode) {
                                        if (
                                          domNode.hasOwnProperty('children')
                                        ) {
                                          let text =
                                            domNode.children.length > 0
                                              ? domNode.children[0].data
                                              : '';
                                          return <>{text}</>;
                                        }

                                        return '';
                                      }
                                    }
                                  )}
                            </>
                          )}
                        </>
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                {!isEmpty(blocks.translations) && (
                  <>
                    {blocks.translations.hasOwnProperty('strclose') && (
                      <>
                        {blocks.translations.strclose[user.location.country]
                          .content !== undefined &&
                        blocks.translations.strclose[user.location.country]
                          .content
                          ? parse(
                              blocks.translations.strclose[
                                user.location.country
                              ].content,
                              {
                                replace: function(domNode) {
                                  if (domNode.hasOwnProperty('children')) {
                                    let text =
                                      domNode.children.length > 0
                                        ? domNode.children[0].data
                                        : '';
                                    return <>{text}</>;
                                  }

                                  return '';
                                }
                              }
                            )
                          : parse(blocks.translations.strclose['USA'].content, {
                              replace: function(domNode) {
                                if (domNode.hasOwnProperty('children')) {
                                  let text =
                                    domNode.children.length > 0
                                      ? domNode.children[0].data
                                      : '';
                                  return <>{text}</>;
                                }

                                return '';
                              }
                            })}
                      </>
                    )}
                  </>
                )}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    blocks: state.translations
  };
};

export default connect(mapStateToProps, null)(Dealer);
