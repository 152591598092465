import React, { Component } from 'react';
import { preFetchUserLocation } from '../../redux/users/index';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';

class ChooseCountry extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: true,
      countries: ['USA', 'Russia', 'Israel']
    };
  }

  switchCountry = (country, isModal = false) => {
    this.setState({ show: false });
    this.props.reFetchData(country, true, isModal);
  };

  handleClose = country => {
    this.setState({ show: false });
  };

  render() {
    const { user } = this.props;
    const { countries } = this.state;
    return (
      <>
        {user.location.hasOwnProperty('showOnce') && (
          <>
            {user.location.showOnce && (
              <Modal show={this.state.show} onHide={this.handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Choose country</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Please choose country from list below</p>
                  <div className="form-row align-items-center">
                    <div className="col-auto my-1">
                      {countries.map((value, index) => {
                        return (
                          <div
                            key={index}
                            className="custom-control custom-radio custom-control-inline mr-sm-2"
                          >
                            <input
                              name="country"
                              type="radio"
                              className="custom-control-input"
                              id={value}
                              onClick={() => this.switchCountry(value)}
                              checked={
                                user.location.country === value ? 'checked' : ''
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={value}
                            >
                              {value}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Modal.Body>

                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => this.handleClose(user.location.country)}
                  >
                    Proceed with choosed country
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
          </>
        )}
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    reFetchData: country => {
      dispatch(preFetchUserLocation(country, true));
    }
  };
};

export default connect(null, mapDispatchToProps)(ChooseCountry);
