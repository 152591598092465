import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  requestDealersCollection,
  receiveDealersCollection,
  fetch
} from '../../redux/dealers/index';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { showStringStars, getSuffixForPlace } from '../../functions';

am4core.useTheme(am4themes_animated);

class Chart extends Component {
  setPlaceForCar = id => {
    let place = 1;
    const collection = this.props.places;
    for (let item in collection) {
      if (parseInt(collection[item].car_id) === parseInt(id)) {
        place = parseInt(item) + 1;
        break;
      }
    }

    return place;
  };

  componentDidMount() {
    let chart = am4core.create('chartdiv', am4charts.XYChart);
    am4core.useTheme(am4themes_animated);

    am4core.options.autoSetClassName = true;
    chart.maxZoomLevel = 1;
    chart.paddingRight = 20;
    chart.minMarginLeft = 20;
    chart.seriesContainer.draggable = false;
    chart.seriesContainer.resizable = false;
    chart.zoomOutButton.disabled = true;
    chart.backgroundImage = 'http://www.amcharts.com/lib/images/bg.jpg';

    let data = [];
    let prices = [];
    let place = 1;
    let grades = [];
    let currency = '';
    let bencmarkTransactionPrice = 0;
    const { info, cars } = this.props;
    if (!this.props.user.isFetching) {
      let className =
        this.props.user.location.currency === 'NIS'
          ? 'fal fa-shekel-sign'
          : this.props.user.location.currency === 'RUB'
          ? 'fal fa-ruble-sign'
          : 'fal fa-dollar-sign';

      currency = '<i style="font-size: 0.9em;" class="' + className + '"></i>';
    }
    for (let car in cars) {
      if (
        cars[car].hasOwnProperty('isBenchMark') ||
        cars[car].hasOwnProperty('isBenchmark')
      ) {
        bencmarkTransactionPrice = cars[car].transaction_price;
      }
      prices.push(info[car].transaction_price);
      place = this.setPlaceForCar(cars[car].id);
      grades.push(info[car].grade);
      let image =
        info[car].imageUrl != null
          ? encodeURI(info[car].imageUrl)
          : require('../../assets/images/empty.png');
      data.push({
        index: car,
        place: place,
        place_class:
          'class="cardCalc card' + (place === 1 ? ' winner' : '') + '"',
        name: cars[car].brand,
        suffix: getSuffixForPlace(place),
        model: cars[car].model,
        brand: cars[car].brand,
        version_name: cars[car].version_name,
        grade: info[car].grade,
        transaction_price: info[car].transaction_price,
        list_price: info[car].list_price,
        src: image,
        stars: showStringStars(info[car].grade),
        currency: currency
      });
    }
    chart.data = data;

    prices.sort((a, b) => {
      return a - b;
    });

    // value
    let carNamesAxis = chart.xAxes.push(new am4charts.ValueAxis());
    carNamesAxis.autoGridCount = true;
    carNamesAxis.min = prices[0] - prices[0] * 0.1;
    carNamesAxis.max =
      prices[prices.length - 1] + prices[prices.length - 1] * 0.1;
    carNamesAxis.renderer.grid.template.disabled = true;

    let marksAxis = chart.yAxes.push(new am4charts.ValueAxis());
    marksAxis.min = 0;
    marksAxis.max = 20;
    marksAxis.renderer.minGridDistance = 1;
    marksAxis.renderer.grid.template.disabled = true;
    marksAxis.renderer.line.strokeOpacity = 1;
    marksAxis.renderer.line.strokeWidth = 6;
    marksAxis.renderer.line.stroke = am4core.color('#a3a3a3');
    marksAxis.renderer.ticks.template.disabled = false;
    marksAxis.renderer.ticks.template.zIndex = 100;
    marksAxis.renderer.ticks.template.strokeOpacity = 1;
    marksAxis.renderer.ticks.template.stroke = am4core.color('#a3a3a3');
    marksAxis.renderer.ticks.template.strokeWidth = 2;
    marksAxis.renderer.ticks.template.length = 10;
    marksAxis.renderer.ticks.template.location = 1;
    marksAxis.renderer.ticks.template.horizontalCenter = 'middle';
    marksAxis.renderer.labels.template.fill = am4core.color('#a3a3a3');

    // this adapter show lables to the left
    marksAxis.renderer.labels.template.adapter.add(
      'text',
      (grade, target, key) => {
        return '';
      }
    );

    let coeficientAxis = chart.yAxes.push(new am4charts.ValueAxis());
    coeficientAxis.min = 0;
    coeficientAxis.max = 20;
    coeficientAxis.renderer.opposite = true;
    coeficientAxis.renderer.minGridDistance = 1;
    coeficientAxis.renderer.grid.template.disabled = true;
    coeficientAxis.renderer.line.strokeOpacity = 1;
    coeficientAxis.renderer.line.strokeWidth = 6;
    coeficientAxis.renderer.line.stroke = am4core.color('#a3a3a3');
    coeficientAxis.renderer.ticks.template.disabled = false;
    coeficientAxis.renderer.ticks.template.zIndex = 100;
    coeficientAxis.renderer.ticks.template.strokeOpacity = 1;
    coeficientAxis.renderer.ticks.template.stroke = am4core.color('#a3a3a3');
    coeficientAxis.renderer.ticks.template.strokeWidth = 2;
    coeficientAxis.renderer.ticks.template.length = 10;
    coeficientAxis.renderer.ticks.template.location = 1;
    coeficientAxis.renderer.ticks.template.horizontalCenter = 'middle';
    coeficientAxis.renderer.labels.template.fill = am4core.color('#a3a3a3');
    coeficientAxis.cursorTooltipEnabled = false;

    coeficientAxis.renderer.labels.template.adapter.add(
      'text',
      (grade, target, key) => {
        switch (true) {
          case parseInt(grade) === 19:
            return Math.ceil((bencmarkTransactionPrice * 0.256) / 500) * 500;
          case parseInt(grade) === 14:
            return Math.ceil((bencmarkTransactionPrice * 0.106) / 500) * 500;
          case parseInt(grade) === 10:
            return 0;
          case parseInt(grade) === 6:
            return (
              Math.ceil((bencmarkTransactionPrice * 0.106) / 500) * 500 * -1
            );
          case parseInt(grade) === 1:
            return (
              Math.ceil((bencmarkTransactionPrice * 0.256) / 500) * 500 * -1
            );
          default:
            return '';
        }
      }
    );

    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = 'grade';
    series.dataFields.valueX = 'transaction_price';
    series.strokeWidth = 0;

    let carBullet = series.bullets.push(new am4charts.CircleBullet());

    let line1 = carBullet.createChild(am4core.Line);
    line1.rotation = 90;
    line1.y = -1;
    line1.width = 1;
    line1.height = 1000;
    line1.strokeOpacity = 1;
    line1.stroke = am4core.color('#000000');
    line1.strokeDasharray = '2,2';
    line1.align = 'center';
    line1.zIndex = -1;

    let line2 = carBullet.createChild(am4core.Line);
    line2.width = 1;
    line2.height = 1000;
    line2.strokeOpacity = 1;
    line2.stroke = am4core.color('#000000');
    line2.strokeDasharray = '2,2';
    line2.align = 'center';
    line2.zIndex = -1;

    carBullet.circle.radius = 10;
    carBullet.circle.fill = am4core.color('#a3a3a3');
    carBullet.circle.fillOpacity = 1;
    carBullet.circle.zIndex = 1000;
    carBullet.tooltipHTML = `
    <div class="cardTpWrap" style="padding: 10px;">
    <div style="width: 178px;" {place_class} id="chartToolTip">
      <div class="card-body shadow-sm">
        <div class="card-title h5" style="color: #565656; font-size: 12px; line-height: 14px;">
          <span class="cardBrandModel">{brand} {model}</span>
          <span class="cardVersion">{version_name}</span>
          <span class="chartStars" style="color: #d70808;">{stars}</span>
          <div className="rating"></div>
        </div>
        <img class="card-img-top" src={src} width="102" height="" style="margin-bottom:15px;"/></td>
        <div class="card__prices">
          <p class="text-center"><span class="text-center card_transPrice" style="font-size:16px;">{currency} {transaction_price}</span></p>
        </div>
      </div>
      </div>
      </div>`;

    let labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.text = '{name} {model}';
    labelBullet.label.zIndex = 2;

    let values = [];
    let counter = 0;
    labelBullet.label.adapter.add('verticalCenter', (center, target) => {
      if (!target.dataItem) {
        return center;
      }
      const currentValue = target.dataItem.values.valueY.value;
      const item = target.dataItem;
      let currentItem = item.sprites[1];

      if (values.length !== 4) {
        values.push(currentValue);
      }

      let indexes = [],
        i = -1;
      while ((i = values.indexOf(currentValue, i + 1)) !== -1) {
        indexes.push(currentValue);
      }

      if (counter < 4) {
        counter++;
        if (indexes.length > 1) {
          let index = indexes.lastIndexOf(currentValue);
          currentItem.dy = (index + 1) % 2 ? -30 : 15;
          return (currentItem.dy = (index + 1) % 2 ? 'top' : 'bottom');
        } else {
          currentItem.dy = -30;
          return 'top';
        }
      }
    });
    series.tooltip.id = 'car-tooltip';
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = am4core.color('#ffffff');
    series.tooltip.pointerOrientation = 'vertical';
    // delete after debug
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = am4core.color('#ffffff');
    series.tooltip.label.interactionsEnabled = true;
    series.tooltip.pointerOrientation = 'vertical';
    series.tooltip.hiddenState.properties.opacity = 1;
    series.tooltip.hiddenState.properties.visible = true;

    var hiddenState = series.tooltip.states.create('hidden');
    hiddenState.properties.opacity = 1;
    hiddenState.properties.visible = true;

    chart.cursor = new am4charts.XYCursor();

    chart.cursor.events.on(
      'cursorpositionchanged',
      () => {
        series.tooltip.validate();
      },
      this
    );
    // delete after debug
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = 'none';

    chart.plotContainer.id = 'special-gradient';

    this.chart = chart;

    this.chart.events.on('validated', e => {
      let rectangle = document.querySelector(
        '.amcharts-Sprite-group.amcharts-Container-group.amcharts-Tooltip-group.amcharts-car-tooltip>g'
      );
      rectangle.setAttribute('fill-opacity', 0);
      rectangle.setAttribute('stroke-width', 0);
      let getPlotContainer = document.querySelector(
        '.amcharts-special-gradient>g'
      );
      let getRectangle = document.querySelector(
        '.amcharts-special-gradient>g>rect'
      );
      let image = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'image'
      );
      let width = getRectangle.getAttribute('width');
      let height = getRectangle.getAttribute('height');
      image.setAttributeNS(
        'http://www.w3.org/1999/xlink',
        'xlink:href',
        require('../../assets/images/cahrtGradBg.jpg')
      );
      image.setAttribute('height', height);
      image.setAttribute('width', width);
      getPlotContainer.appendChild(image);

      let stars = document.getElementById('stars');
      stars.style.height = height + 'px';
    });
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    const { user } = this.props;
    return (
      <div>
        <div>
          <p className="float-left">
            CE (Comparably Equipped)
            <br />
            Market Value Grade
          </p>
          <p className="float-right">
            Difference in (Equipment & Power)
            <br />
            Market Value, {!user.isFetching && user.location.currency}
          </p>
        </div>
        <div style={{ clear: 'both' }}>
          <div
            id="stars"
            style={{
              marginTop: '12px',
              verticalAlign: 'top',
              display: 'inline-block',
              width: '220px'
            }}
          >
            <div
              className="gradient-scale"
              style={{
                width: '20px',
                height: '100%',
                display: 'inline-block',
                verticalAlign: 'top'
              }}
            />
            <div
              className="star-scale"
              style={{
                width: '200px',
                height: '100%',
                display: 'inline-block',
                verticalAlign: 'top',
                paddingTop: '22px'
              }}
            >
              <div className="rating-19">
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
              </div>
              <div className="rating-18">
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star-half-alt" />
                </span>
              </div>
              <div className="rating-17">
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
              </div>
              <div className="rating-16">
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star-half-alt" />
                </span>
              </div>
              <div className="rating-15">
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
              </div>
              <div className="rating-14">
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star-half-alt" />
                </span>
              </div>
              <div className="rating-13">
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
              </div>
              <div className="rating-12">
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star-half-alt" />
                </span>
              </div>
              <div className="rating-11">
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
              </div>
              <div className="rating-10">
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star-half-alt" />
                </span>
              </div>
              <div className="rating-9">
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
              </div>
              <div className="rating-8">
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star-half-alt" />
                </span>
              </div>
              <div className="rating-7">
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
              </div>
              <div className="rating-6">
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star-half-alt" />
                </span>
              </div>
              <div className="rating-5">
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star" />
                </span>
              </div>
              <div className="rating-4">
                <span>
                  <i className="fas fa-star" />
                </span>
                <span>
                  <i className="fas fa-star-half-alt" />
                </span>
              </div>
              <div className="rating-3">
                <span>
                  <i className="fas fa-star" />
                </span>
              </div>
            </div>
          </div>
          <div
            id="chartdiv"
            style={{
              verticalAlign: 'top',
              display: 'inline-block',
              width: '80%',
              height: '700px'
            }}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchCollection: collection => {
      dispatch(requestDealersCollection());
      return fetch(collection)
        .then(response => response.data)
        .then(data => dispatch(receiveDealersCollection(data)));
    }
  };
};

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chart);
