import {
  USER_REQUEST_INFO,
  USER_RECEIVE_INFO,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAILURE,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILURE,
  USER_LOGOUT
} from './types';

import { RECEIVE_RESULT_COLLECTION } from '../cars/types';

let user = localStorage.getItem('user')
  ? JSON.parse(localStorage.getItem('user'))
  : null;
const initialState = user ? { loggedIn: true, user } : { loggedIn: false };

export function userAuthenticationReducer(state = initialState, action) {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        loggedIn: false,
        user: action.user
      };
      break;
    case USER_LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user,
        lastUpdated: action.receivedAt
      };
      break;
    case USER_LOGIN_FAILURE:
      return {
        loggedIn: false,
        message: action.error.response.data.message
      };
      break;
    case USER_UPDATE_REQUEST:
      return {
        loggedIn: true,
        isFetching: true
      };
    case USER_UPDATE_SUCCESS:
      return {
        loggedIn: true,
        isFetching: false,
        user: action.user ? action.user : initialState.user,
        lastUpdated: action.receivedAt
      };
      break;
    case USER_UPDATE_FAILURE:
      return {
        loggedIn: true,
        isFetching: false,
        user: initialState.user
      };
      break;
    case USER_LOGOUT:
      return {
        loggedIn: false
      };
      break;
    case RECEIVE_RESULT_COLLECTION:
      if (action.user) {
        return {
          loggedIn: true,
          user: action.user,
          lastUpdated: action.receivedAt
        };
      }

      return state;
      break;
    default:
      return state;
      break;
  }
}

export function userRegistrationReducer(state = {}, action) {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { registering: true };
    case USER_REGISTER_SUCCESS:
      return action.user;
    case USER_REGISTER_FAILURE:
      return action.error;
    default:
      return state;
  }
}

export function userReducer(state = {}, action) {
  switch (action.type) {
    case USER_REQUEST_INFO:
      return {
        isFetching: true,
        didInvalidate: false,
        location: {}
      };
    case USER_RECEIVE_INFO:
      return {
        isFetching: false,
        didInvalidate: false,
        location: action.payload,
        lastUpdated: action.receivedAt
      };
    default:
      return state;
  }
}
