import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Nav, Tab } from 'react-bootstrap';
import Result from '../blocks/Result';
import Chart from '../blocks/Chart';
import Characteristics from '../blocks/Charateristics.js';
import parse from 'html-react-parser';
//import Depreciation from '../blocks/Depreciation.js';
import {
  requestResultCollection,
  receiveResultCollection,
  fetch
} from '../../redux/cars/index';
import { FAIL_GET_RESULT } from '../../redux/cars/types';
import { isEmpty } from '../../functions';

class CompareResult extends Component {
  constructor(props) {
    super(props);

    this.state = {
      key: 'first'
    };
  }

  UNSAFE_componentWillMount() {
    document.body.className = '';
    document.body.classList.add('compareresult');

    let result = [];
    if (
      this.props.location.state &&
      this.props.location.state.hasOwnProperty('cars')
    ) {
      result = this.props.location.state.cars;
    }

    this.setState({ result: result });
  }

  componentDidUpdate(prevProps) {
    const { result } = this.state;
    if (
      isEmpty(this.props.collection) &&
      this.props.user.isFetching === false &&
      this.props.segment_info.isFetching === false &&
      result.length > 0
    ) {
      this.onFetchResults(result);
    }
  }

  onFetchResults = payload => {
    let dataToSend = {
      cars_ids: [],
      country: this.props.user.location.country,
      segment_id: null
    };

    payload.map(value => {
      dataToSend.cars_ids.push({
        id: value.id,
        isBenchMark: value.hasOwnProperty('isBenchmark')
          ? value.isBenchmark
          : value.isBenchMark
      });
      return value;
    });

    this.props.segment_info.items.map(value => {
      if (value.name === payload[0].segment)
        dataToSend.segment_id = parseInt(value.id);
      return value;
    });

    //dispatch
    this.props.fetchCollection(dataToSend);
  };

  handleSelect = key => {
    this.setState({ key });
  };

  render() {
    const { result, key } = this.state;
    const { collection, user, blocks } = this.props;
    return (
      <React.Fragment>
        <div className="compareResults">
          <div className="compareResultsInner">
            <div className="d-flex justify-content-center">
              <h1>
                Compare<span className="cbddRed"> Results</span>
              </h1>
            </div>
            {collection.isFetching && <div className="cm-spinner" />}
            {!collection.isFetching && collection.hasOwnProperty('message') && (
              <>
                {collection.message.map((value, index) => {
                  return <p className="text-center text-danger">{value}</p>;
                })}
              </>
            )}
            {!collection.isFetching && collection.hasOwnProperty('collection') && (
              <>
                <Tab.Container
                  id="compare-results"
                  activeKey={key}
                  onSelect={this.handleSelect}
                >
                  <Nav
                    variant="tabs"
                    className="text-center d-flex justify-content-between top"
                  >
                    <Nav.Item>
                      <Nav.Link eventKey="first">
                        <i className="fal fa-trophy-alt" />
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">
                        <i className="fal fa-chart-line" />
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">
                        <i className="fal fa-th-large" />
                      </Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                      <Nav.Link eventKey="four">
                        <i className="fal fa-funnel-dollar" />
                      </Nav.Link>
                    </Nav.Item> */}
                  </Nav>
                  <div className="container-fluid">
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <Result
                          cars={result}
                          info={collection.collection}
                          places={collection.places}
                          user={user}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Chart
                          cars={result}
                          info={collection.collection}
                          places={collection.places}
                          user={user}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <Characteristics
                          cars={result}
                          info={collection.collection}
                          places={collection.places}
                          user={user}
                        />
                      </Tab.Pane>
                      {/* <Tab.Pane eventKey="four">
                        <Depreciation
                          cars={result}
                          info={collection.collection}
                          places={collection.places}
                          user={user}
                        />
                      </Tab.Pane> */}
                    </Tab.Content>
                  </div>
                  <div className="clearFix" />
                </Tab.Container>
                <div className="compareBtnWrap">
                  <Nav
                    variant="tabs"
                    className="resultPagin text-center d-flex justify-content-center"
                  >
                    {key === 'first' && (
                      <Nav.Item className="">
                        <Nav.Link
                          className="compareBtn"
                          onClick={() => this.handleSelect('second')}
                        >
                          {!isEmpty(blocks.translations) && (
                            <>
                              {blocks.translations.hasOwnProperty(
                                'strgotochart'
                              ) && (
                                <>
                                  {blocks.translations.strgotochart[
                                    user.location.country
                                  ].content !== undefined &&
                                  blocks.translations.strgotochart[
                                    user.location.country
                                  ].content
                                    ? parse(
                                        blocks.translations.strgotochart[
                                          user.location.country
                                        ].content,
                                        {
                                          replace: function(domNode) {
                                            if (
                                              domNode.hasOwnProperty('children')
                                            ) {
                                              let text =
                                                domNode.children.length > 0
                                                  ? domNode.children[0].data
                                                  : '';
                                              return <>{text}</>;
                                            }

                                            return '';
                                          }
                                        }
                                      )
                                    : parse(
                                        blocks.translations.strgotochart['USA']
                                          .content,
                                        {
                                          replace: function(domNode) {
                                            if (
                                              domNode.hasOwnProperty('children')
                                            ) {
                                              let text =
                                                domNode.children.length > 0
                                                  ? domNode.children[0].data
                                                  : '';
                                              return <>{text}</>;
                                            }

                                            return '';
                                          }
                                        }
                                      )}
                                </>
                              )}
                            </>
                          )}
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {key === 'second' && (
                      <>
                        <Nav.Item className="">
                          <Nav.Link
                            className="compareBtn"
                            onClick={() => this.handleSelect('first')}
                          >
                            {!isEmpty(blocks.translations) && (
                              <>
                                {blocks.translations.hasOwnProperty(
                                  'strgotosummary'
                                ) && (
                                  <>
                                    {blocks.translations.strgotosummary[
                                      user.location.country
                                    ].content !== undefined &&
                                    blocks.translations.strgotosummary[
                                      user.location.country
                                    ].content
                                      ? parse(
                                          blocks.translations.strgotosummary[
                                            user.location.country
                                          ].content,
                                          {
                                            replace: function(domNode) {
                                              if (
                                                domNode.hasOwnProperty(
                                                  'children'
                                                )
                                              ) {
                                                let text =
                                                  domNode.children.length > 0
                                                    ? domNode.children[0].data
                                                    : '';
                                                return <>{text}</>;
                                              }

                                              return '';
                                            }
                                          }
                                        )
                                      : parse(
                                          blocks.translations.strgotosummary[
                                            'USA'
                                          ].content,
                                          {
                                            replace: function(domNode) {
                                              if (
                                                domNode.hasOwnProperty(
                                                  'children'
                                                )
                                              ) {
                                                let text =
                                                  domNode.children.length > 0
                                                    ? domNode.children[0].data
                                                    : '';
                                                return <>{text}</>;
                                              }

                                              return '';
                                            }
                                          }
                                        )}
                                  </>
                                )}
                              </>
                            )}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="">
                          <Nav.Link
                            className="compareBtn"
                            onClick={() => this.handleSelect('third')}
                          >
                            {!isEmpty(blocks.translations) && (
                              <>
                                {blocks.translations.hasOwnProperty(
                                  'strgotocharacteristics'
                                ) && (
                                  <>
                                    {blocks.translations.strgotocharacteristics[
                                      user.location.country
                                    ].content !== undefined &&
                                    blocks.translations.strgotocharacteristics[
                                      user.location.country
                                    ].content
                                      ? parse(
                                          blocks.translations
                                            .strgotocharacteristics[
                                            user.location.country
                                          ].content,
                                          {
                                            replace: function(domNode) {
                                              if (
                                                domNode.hasOwnProperty(
                                                  'children'
                                                )
                                              ) {
                                                let text =
                                                  domNode.children.length > 0
                                                    ? domNode.children[0].data
                                                    : '';
                                                return <>{text}</>;
                                              }

                                              return '';
                                            }
                                          }
                                        )
                                      : parse(
                                          blocks.translations
                                            .strgotocharacteristics['USA']
                                            .content,
                                          {
                                            replace: function(domNode) {
                                              if (
                                                domNode.hasOwnProperty(
                                                  'children'
                                                )
                                              ) {
                                                let text =
                                                  domNode.children.length > 0
                                                    ? domNode.children[0].data
                                                    : '';
                                                return <>{text}</>;
                                              }

                                              return '';
                                            }
                                          }
                                        )}
                                  </>
                                )}
                              </>
                            )}
                          </Nav.Link>
                        </Nav.Item>
                      </>
                    )}
                    {key === 'third' && (
                      <>
                        <Nav.Item className="">
                          <Nav.Link
                            className="compareBtn"
                            onClick={() => this.handleSelect('second')}
                          >
                            {!isEmpty(blocks.translations) && (
                              <>
                                {blocks.translations.hasOwnProperty(
                                  'strgotochart'
                                ) && (
                                  <>
                                    {blocks.translations.strgotochart[
                                      user.location.country
                                    ].content !== undefined &&
                                    blocks.translations.strgotochart[
                                      user.location.country
                                    ].content
                                      ? parse(
                                          blocks.translations.strgotochart[
                                            user.location.country
                                          ].content,
                                          {
                                            replace: function(domNode) {
                                              if (
                                                domNode.hasOwnProperty(
                                                  'children'
                                                )
                                              ) {
                                                let text =
                                                  domNode.children.length > 0
                                                    ? domNode.children[0].data
                                                    : '';
                                                return <>{text}</>;
                                              }

                                              return '';
                                            }
                                          }
                                        )
                                      : parse(
                                          blocks.translations.strgotochart[
                                            'USA'
                                          ].content,
                                          {
                                            replace: function(domNode) {
                                              if (
                                                domNode.hasOwnProperty(
                                                  'children'
                                                )
                                              ) {
                                                let text =
                                                  domNode.children.length > 0
                                                    ? domNode.children[0].data
                                                    : '';
                                                return <>{text}</>;
                                              }

                                              return '';
                                            }
                                          }
                                        )}
                                  </>
                                )}
                              </>
                            )}
                          </Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item className="">
                          <Nav.Link
                            className="compareBtn"
                            onClick={() => this.handleSelect('four')}
                          >
                            Go to depreciation
                          </Nav.Link>
                        </Nav.Item> */}
                      </>
                    )}
                    {/* {key === 'four' && (
                      <Nav.Item className="">
                        <Nav.Link
                          className="compareBtn"
                          onClick={() => this.handleSelect('third')}
                        >
                          Go to Charateristics
                        </Nav.Link>
                      </Nav.Item>
                    )} */}
                  </Nav>
                </div>
              </>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    collection: state.cars,
    user: state.user,
    segment_info: state.segment,
    blocks: state.translations
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchCollection: collection => {
      dispatch(requestResultCollection());
      return fetch(collection)
        .then(response => response.data)
        .then(data => dispatch(receiveResultCollection(data)))
        .catch(error => {
          if (error.response.status === 401) {
            localStorage.removeItem('user');
            window.location = '/';
          }
          let data = {};
          data['payload'] = error.response.data.message;
          data['receivedAt'] = Date.now();
          dispatch({ type: FAIL_GET_RESULT, data });
        });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompareResult);
