import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { dispatchLogin, dispatchRegister } from '../redux/modals/index';
import { connect } from 'react-redux';
import { logout } from '../redux/users/index';
import { info as infoAlert } from 'react-notification-system-redux';
import parse from 'html-react-parser';
import { isEmpty } from './../functions';

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLogin: false,
      showRegister: false
    };
  }

  handleLogin = () => {
    this.props.handleLoginModal();
  };

  handleRegister = () => {
    this.props.handleRegisterModal();
  };

  handleOut = () => {
    this.props.handleLogOut();
  };

  render() {
    const { user, blocks } = this.props;
    return (
      <footer className="border-footer-header row">
        <div className="col-4 left">
          <div className="leftContent py-5">
            <img
              src={require('../assets/images/logoFooter.png')}
              alt="CBDD Logo"
            />
            <p className="my-5 text-uppercase">
              © Car Business
              <br />
              Data Dynamics Ltd.
              <br />
              All Rights Reserved.
            </p>
          </div>
        </div>
        <div className="col-2 middle py-5">
          <nav>
            <ul>
              <li>
                {!isEmpty(blocks.translations) && (
                  <>
                    {blocks.translations.hasOwnProperty('strmainnavigaion') && (
                      <>
                        {blocks.translations.strmainnavigaion[
                          user.location.country
                        ].content !== undefined &&
                        blocks.translations.strmainnavigaion[
                          user.location.country
                        ].content
                          ? parse(
                              blocks.translations.strmainnavigaion[
                                user.location.country
                              ].content,
                              {
                                replace: function(domNode) {
                                  if (domNode.hasOwnProperty('children')) {
                                    let text =
                                      domNode.children.length > 0
                                        ? domNode.children[0].data
                                        : '';
                                    return <>{text}</>;
                                  }

                                  return '';
                                }
                              }
                            )
                          : parse(
                              blocks.translations.strmainnavigaion['USA']
                                .content,
                              {
                                replace: function(domNode) {
                                  if (domNode.hasOwnProperty('children')) {
                                    let text =
                                      domNode.children.length > 0
                                        ? domNode.children[0].data
                                        : '';
                                    return <>{text}</>;
                                  }

                                  return '';
                                }
                              }
                            )}
                      </>
                    )}
                  </>
                )}
              </li>
              <li>
                <Link to="/how-it-works">
                  {!isEmpty(blocks.translations) && (
                    <>
                      {blocks.translations.hasOwnProperty(
                        'strhowdoesitwork'
                      ) && (
                        <>
                          {blocks.translations.strhowdoesitwork[
                            user.location.country
                          ].content !== undefined &&
                          blocks.translations.strhowdoesitwork[
                            user.location.country
                          ].content
                            ? parse(
                                blocks.translations.strhowdoesitwork[
                                  user.location.country
                                ].content,
                                {
                                  replace: function(domNode) {
                                    if (domNode.hasOwnProperty('children')) {
                                      let text =
                                        domNode.children.length > 0
                                          ? domNode.children[0].data
                                          : '';
                                      return <>{text}</>;
                                    }

                                    return '';
                                  }
                                }
                              )
                            : parse(
                                blocks.translations.strhowdoesitwork['USA']
                                  .content,
                                {
                                  replace: function(domNode) {
                                    if (domNode.hasOwnProperty('children')) {
                                      let text =
                                        domNode.children.length > 0
                                          ? domNode.children[0].data
                                          : '';
                                      return <>{text}</>;
                                    }

                                    return '';
                                  }
                                }
                              )}
                        </>
                      )}
                    </>
                  )}
                </Link>
              </li>
              <li>
                <Link to="/about-cbdd">
                  {!isEmpty(blocks.translations) && (
                    <>
                      {blocks.translations.hasOwnProperty('straboutus') && (
                        <>
                          {blocks.translations.straboutus[user.location.country]
                            .content !== undefined &&
                          blocks.translations.straboutus[user.location.country]
                            .content
                            ? parse(
                                blocks.translations.straboutus[
                                  user.location.country
                                ].content,
                                {
                                  replace: function(domNode) {
                                    if (domNode.hasOwnProperty('children')) {
                                      let text =
                                        domNode.children.length > 0
                                          ? domNode.children[0].data
                                          : '';
                                      return <>{text}</>;
                                    }

                                    return '';
                                  }
                                }
                              )
                            : parse(
                                blocks.translations.straboutus['USA'].content,
                                {
                                  replace: function(domNode) {
                                    if (domNode.hasOwnProperty('children')) {
                                      let text =
                                        domNode.children.length > 0
                                          ? domNode.children[0].data
                                          : '';
                                      return <>{text}</>;
                                    }

                                    return '';
                                  }
                                }
                              )}
                        </>
                      )}
                    </>
                  )}
                </Link>
              </li>
              <li>
                {!isEmpty(blocks.translations) && (
                  <>
                    {blocks.translations.hasOwnProperty('strnews') && (
                      <>
                        {blocks.translations.strnews[user.location.country]
                          .content !== undefined &&
                        blocks.translations.strnews[user.location.country]
                          .content
                          ? parse(
                              blocks.translations.strnews[user.location.country]
                                .content,
                              {
                                replace: function(domNode) {
                                  if (domNode.hasOwnProperty('children')) {
                                    let text =
                                      domNode.children.length > 0
                                        ? domNode.children[0].data
                                        : '';
                                    return <>{text}</>;
                                  }

                                  return '';
                                }
                              }
                            )
                          : parse(blocks.translations.strnews['USA'].content, {
                              replace: function(domNode) {
                                if (domNode.hasOwnProperty('children')) {
                                  let text =
                                    domNode.children.length > 0
                                      ? domNode.children[0].data
                                      : '';
                                  return <>{text}</>;
                                }

                                return '';
                              }
                            })}
                      </>
                    )}
                  </>
                )}
              </li>
              {!this.props.loggedIn && (
                <>
                  <li onClick={this.handleLogin}>
                    {!isEmpty(blocks.translations) && (
                      <>
                        {blocks.translations.hasOwnProperty('strlogin') && (
                          <>
                            {blocks.translations.strlogin[user.location.country]
                              .content !== undefined &&
                            blocks.translations.strlogin[user.location.country]
                              .content
                              ? parse(
                                  blocks.translations.strlogin[
                                    user.location.country
                                  ].content,
                                  {
                                    replace: function(domNode) {
                                      if (domNode.hasOwnProperty('children')) {
                                        let text =
                                          domNode.children.length > 0
                                            ? domNode.children[0].data
                                            : '';
                                        return <>{text}</>;
                                      }

                                      return '';
                                    }
                                  }
                                )
                              : parse(
                                  blocks.translations.strlogin['USA'].content,
                                  {
                                    replace: function(domNode) {
                                      if (domNode.hasOwnProperty('children')) {
                                        let text =
                                          domNode.children.length > 0
                                            ? domNode.children[0].data
                                            : '';
                                        return <>{text}</>;
                                      }

                                      return '';
                                    }
                                  }
                                )}
                          </>
                        )}
                      </>
                    )}
                  </li>
                  <li onClick={this.handleRegister}>
                    {!isEmpty(blocks.translations) && (
                      <>
                        {blocks.translations.hasOwnProperty('strregister') && (
                          <>
                            {blocks.translations.strregister[
                              user.location.country
                            ].content !== undefined &&
                            blocks.translations.strregister[
                              user.location.country
                            ].content
                              ? parse(
                                  blocks.translations.strregister[
                                    user.location.country
                                  ].content,
                                  {
                                    replace: function(domNode) {
                                      if (domNode.hasOwnProperty('children')) {
                                        let text =
                                          domNode.children.length > 0
                                            ? domNode.children[0].data
                                            : '';
                                        return <>{text}</>;
                                      }

                                      return '';
                                    }
                                  }
                                )
                              : parse(
                                  blocks.translations.strregister['USA']
                                    .content,
                                  {
                                    replace: function(domNode) {
                                      if (domNode.hasOwnProperty('children')) {
                                        let text =
                                          domNode.children.length > 0
                                            ? domNode.children[0].data
                                            : '';
                                        return <>{text}</>;
                                      }

                                      return '';
                                    }
                                  }
                                )}
                          </>
                        )}
                      </>
                    )}
                  </li>
                </>
              )}
              {this.props.loggedIn && <li onClick={this.handleOut}>Log-out</li>}
            </ul>
          </nav>
          <nav>
            <ul>
              <li>
                {!isEmpty(blocks.translations) && (
                  <>
                    {blocks.translations.hasOwnProperty(
                      'strlegalinformation'
                    ) && (
                      <>
                        {blocks.translations.strlegalinformation[
                          user.location.country
                        ].content !== undefined &&
                        blocks.translations.strlegalinformation[
                          user.location.country
                        ].content
                          ? parse(
                              blocks.translations.strlegalinformation[
                                user.location.country
                              ].content,
                              {
                                replace: function(domNode) {
                                  if (domNode.hasOwnProperty('children')) {
                                    let text =
                                      domNode.children.length > 0
                                        ? domNode.children[0].data
                                        : '';
                                    return <>{text}</>;
                                  }

                                  return '';
                                }
                              }
                            )
                          : parse(
                              blocks.translations.strlegalinformation['USA']
                                .content,
                              {
                                replace: function(domNode) {
                                  if (domNode.hasOwnProperty('children')) {
                                    let text =
                                      domNode.children.length > 0
                                        ? domNode.children[0].data
                                        : '';
                                    return <>{text}</>;
                                  }

                                  return '';
                                }
                              }
                            )}
                      </>
                    )}
                  </>
                )}
              </li>
              <li>
                <Link to="/terms-of-service">
                  {!isEmpty(blocks.translations) && (
                    <>
                      {blocks.translations.hasOwnProperty(
                        'strtermsofservice'
                      ) && (
                        <>
                          {blocks.translations.strtermsofservice[
                            user.location.country
                          ].content !== undefined &&
                          blocks.translations.strtermsofservice[
                            user.location.country
                          ].content
                            ? parse(
                                blocks.translations.strtermsofservice[
                                  user.location.country
                                ].content,
                                {
                                  replace: function(domNode) {
                                    if (domNode.hasOwnProperty('children')) {
                                      let text =
                                        domNode.children.length > 0
                                          ? domNode.children[0].data
                                          : '';
                                      return <>{text}</>;
                                    }

                                    return '';
                                  }
                                }
                              )
                            : parse(
                                blocks.translations.strtermsofservice['USA']
                                  .content,
                                {
                                  replace: function(domNode) {
                                    if (domNode.hasOwnProperty('children')) {
                                      let text =
                                        domNode.children.length > 0
                                          ? domNode.children[0].data
                                          : '';
                                      return <>{text}</>;
                                    }

                                    return '';
                                  }
                                }
                              )}
                        </>
                      )}
                    </>
                  )}
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy">
                  {!isEmpty(blocks.translations) && (
                    <>
                      {blocks.translations.hasOwnProperty(
                        'strprivacypolicy'
                      ) && (
                        <>
                          {blocks.translations.strprivacypolicy[
                            user.location.country
                          ].content !== undefined &&
                          blocks.translations.strprivacypolicy[
                            user.location.country
                          ].content
                            ? parse(
                                blocks.translations.strprivacypolicy[
                                  user.location.country
                                ].content,
                                {
                                  replace: function(domNode) {
                                    if (domNode.hasOwnProperty('children')) {
                                      let text =
                                        domNode.children.length > 0
                                          ? domNode.children[0].data
                                          : '';
                                      return <>{text}</>;
                                    }

                                    return '';
                                  }
                                }
                              )
                            : parse(
                                blocks.translations.strprivacypolicy['USA']
                                  .content,
                                {
                                  replace: function(domNode) {
                                    if (domNode.hasOwnProperty('children')) {
                                      let text =
                                        domNode.children.length > 0
                                          ? domNode.children[0].data
                                          : '';
                                      return <>{text}</>;
                                    }

                                    return '';
                                  }
                                }
                              )}
                        </>
                      )}
                    </>
                  )}
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="col right py-5">
          <nav>
            <ul>
              <li>
                {!isEmpty(blocks.translations) && (
                  <>
                    {blocks.translations.hasOwnProperty('strlegalnotehead') && (
                      <>
                        {blocks.translations.strlegalnotehead[
                          user.location.country
                        ].content !== undefined &&
                        blocks.translations.strlegalnotehead[
                          user.location.country
                        ].content
                          ? parse(
                              blocks.translations.strlegalnotehead[
                                user.location.country
                              ].content,
                              {
                                replace: function(domNode) {
                                  if (domNode.hasOwnProperty('children')) {
                                    let text =
                                      domNode.children.length > 0
                                        ? domNode.children[0].data
                                        : '';
                                    return <>{text}</>;
                                  }

                                  return '';
                                }
                              }
                            )
                          : parse(
                              blocks.translations.strlegalnotehead['USA']
                                .content,
                              {
                                replace: function(domNode) {
                                  if (domNode.hasOwnProperty('children')) {
                                    let text =
                                      domNode.children.length > 0
                                        ? domNode.children[0].data
                                        : '';
                                    return <>{text}</>;
                                  }

                                  return '';
                                }
                              }
                            )}
                      </>
                    )}
                  </>
                )}
              </li>
              <li>
                {!isEmpty(blocks.translations) && (
                  <>
                    {blocks.translations.hasOwnProperty('strlegalnote') && (
                      <>
                        {blocks.translations.strlegalnote[user.location.country]
                          .content !== undefined &&
                        blocks.translations.strlegalnote[user.location.country]
                          .content
                          ? parse(
                              blocks.translations.strlegalnote[
                                user.location.country
                              ].content,
                              {
                                replace: function(domNode) {
                                  if (domNode.hasOwnProperty('children')) {
                                    let text =
                                      domNode.children.length > 0
                                        ? domNode.children[0].data
                                        : '';
                                    return <>{text}</>;
                                  }

                                  return '';
                                }
                              }
                            )
                          : parse(
                              blocks.translations.strlegalnote['USA'].content,
                              {
                                replace: function(domNode) {
                                  if (domNode.hasOwnProperty('children')) {
                                    let text =
                                      domNode.children.length > 0
                                        ? domNode.children[0].data
                                        : '';
                                    return <>{text}</>;
                                  }

                                  return '';
                                }
                              }
                            )}
                      </>
                    )}
                  </>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = state => {
  const { loggedIn } = state.user_info;
  return {
    user: state.user,
    blocks: state.translations,
    loggedIn
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleLogOut: () => {
      dispatch(logout());
      dispatch(
        infoAlert({
          title: '',
          message: 'Hope that you will return:)',
          position: 'br',
          autoDismiss: 5,
          action: {}
        })
      );
    },
    handleLoginModal: () => {
      dispatch(dispatchLogin('open'));
    },
    handleRegisterModal: () => {
      dispatch(dispatchRegister('open'));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
