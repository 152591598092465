import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Service from '../../api';
import Config from '../../config';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { USER_UPDATE_SUCCESS } from '../../redux/users/types';
import {
  success as successAlert,
  error as errorAlert
} from 'react-notification-system-redux';
import { parse } from 'flatted/esm';
import { authHeader } from '../../redux/users/services/auth-header';

class PaymentVerified extends Component {
  state = {
    confirming: true,
    success: false,
    message: ''
  };

  componentDidMount() {
    const payment = queryString.parse(this.props.location.search);
    let service = new Service({ ...authHeader() });
    return service
      .post(Config.LARAVEL_API.URL + '/verify-payment', {
        lowprofilecode: payment.lowprofilecode,
        ResponeCode: payment.ResponeCode
      })
      .then(
        response => {
          let data = response.data;
          let user = data.user;
          this.setState(
            {
              confirming: false,
              success: data.success,
              message: data.message
            },
            () => {
              if (user) {
                localStorage.setItem('user', JSON.stringify(user));
              }
              this.props.dispatch({ type: USER_UPDATE_SUCCESS, user });
              this.props.dispatch(
                successAlert({
                  title: '',
                  message: data.message,
                  position: 'br',
                  autoDismiss: 5,
                  action: {}
                })
              );
            }
          );
        },
        error => {
          this.setState(
            {
              confirming: false
            },
            () => {
              this.props.dispatch(
                errorAlert({
                  title: '',
                  message: 'Error occurred.',
                  position: 'br',
                  autoDismiss: 5,
                  action: {}
                })
              );
            }
          );
        }
      )
      .catch(error => {
        this.setState(
          {
            confirming: false
          },
          () => {
            this.props.dispatch(
              errorAlert({
                title: '',
                message: 'Error occurred.',
                position: 'br',
                autoDismiss: 5,
                action: {}
              })
            );
          }
        );
      });
  }

  handleCompare = e => {
    e.preventDefault();
    let object = parse(localStorage.getItem('saved-result'));
    localStorage.removeItem('saved-result');
    this.props.history.push({
      pathname: `/compare`,
      state: {
        resetState: object,
        fromHistory: true
      }
    });
  };

  render() {
    const { confirming, message, success } = this.state;
    return (
      <div className="row">
        <div className="container">
          <div className="confirm">
            {confirming ? (
              <p className="text-center">
                Verifying your payment. Please wait ...
              </p>
            ) : (
              <div className="text-center">
                {success ? (
                  <>
                    <p>{message}</p>
                    {localStorage.getItem('saved-result') === null ? (
                      <Link to="/personal-info">Details</Link>
                    ) : (
                      <Link onClick={e => this.handleCompare(e)}>
                        Go to comparison
                      </Link>
                    )}
                  </>
                ) : (
                  <p>
                    Something went wrong during verification. Please try again,
                    or contact our support.
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;
  return {
    user
  };
};

export default connect(
  mapStateToProps,
  null
)(PaymentVerified);
