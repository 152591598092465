import axios from 'axios';

export default class Service {
  constructor(headers = {}) {
    let service = axios.create({ headers: headers });
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
  }

  handleSuccess(response) {
    return response;
  }

  handleError = error => {
    switch (error.response.status) {
      case 401:
        localStorage.removeItem('user');
        window.location = '/';
        break;
      case 404:
        this.redirectTo(document, '/404');
        break;
      default:
        break;
    }

    return Promise.reject(error);
  };

  redirectTo = (document, path) => {
    document.location = path;
  };

  get(path) {
    return this.service.get(path);
  }

  getWithParams(path, params = {}) {
    return this.service.get(path, params);
  }

  patch(path, payload) {
    return this.service.request({
      method: 'PATCH',
      url: path,
      responseType: 'json',
      data: payload
    });
  }

  post(path, payload) {
    return this.service.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload
    });
  }

  put(path, payload) {
    return this.service.request({
      method: 'PUT',
      url: path,
      responseType: 'json',
      data: payload
    });
  }
}
