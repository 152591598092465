import React, { Component } from 'react';
import ChooseSegmentList from '../lists/ChooseSegmentList';
import HomeSlider from '../sliders/HomeSlider';
import { connect } from 'react-redux';
import HomeSearch from '../blocks/HomeSearch';
import { Nav, Tab, Row } from 'react-bootstrap';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { isEmpty } from './../../functions';

class Home extends Component {
  UNSAFE_componentWillMount() {
    document.body.className = '';
    document.body.classList.add('home');
  }

  render() {
    const { user, blocks } = this.props;
    return (
      <div id="home">
        <Row className="justify-content-center home-search">
          <div className="col-6">
            <Tab.Container id="home-search" defaultActiveKey="first">
              <Nav variant="tabs" className="text-center row no-gutters">
                <Nav.Item className="col-6 pr-1">
                  <Nav.Link eventKey="first">
                    {!isEmpty(blocks.translations) && (
                      <>
                        {blocks.translations.hasOwnProperty(
                          'strselectmodel'
                        ) && (
                          <>
                            {blocks.translations.strselectmodel[
                              user.location.country
                            ].content !== undefined &&
                            blocks.translations.strselectmodel[
                              user.location.country
                            ].content
                              ? parse(
                                  blocks.translations.strselectmodel[
                                    user.location.country
                                  ].content,
                                  {
                                    replace: function(domNode) {
                                      if (domNode.hasOwnProperty('children')) {
                                        let text =
                                          domNode.children.length > 0
                                            ? domNode.children[0].data
                                            : '';
                                        return <>{text}</>;
                                      }

                                      return '';
                                    }
                                  }
                                )
                              : parse(
                                  blocks.translations.strselectmodel['USA']
                                    .content,
                                  {
                                    replace: function(domNode) {
                                      if (domNode.hasOwnProperty('children')) {
                                        let text =
                                          domNode.children.length > 0
                                            ? domNode.children[0].data
                                            : '';
                                        return <>{text}</>;
                                      }

                                      return '';
                                    }
                                  }
                                )}
                          </>
                        )}
                      </>
                    )}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="col-6 pl-1">
                  <Nav.Link eventKey="second">
                    {!isEmpty(blocks.translations) && (
                      <>
                        {blocks.translations.hasOwnProperty(
                          'strselectsegment'
                        ) && (
                          <>
                            {blocks.translations.strselectsegment[
                              user.location.country
                            ].content !== undefined &&
                            blocks.translations.strselectsegment[
                              user.location.country
                            ].content
                              ? parse(
                                  blocks.translations.strselectsegment[
                                    user.location.country
                                  ].content,
                                  {
                                    replace: function(domNode) {
                                      if (domNode.hasOwnProperty('children')) {
                                        let text =
                                          domNode.children.length > 0
                                            ? domNode.children[0].data
                                            : '';
                                        return <>{text}</>;
                                      }

                                      return '';
                                    }
                                  }
                                )
                              : parse(
                                  blocks.translations.strselectsegment['USA']
                                    .content,
                                  {
                                    replace: function(domNode) {
                                      if (domNode.hasOwnProperty('children')) {
                                        let text =
                                          domNode.children.length > 0
                                            ? domNode.children[0].data
                                            : '';
                                        return <>{text}</>;
                                      }

                                      return '';
                                    }
                                  }
                                )}
                          </>
                        )}
                      </>
                    )}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="first" className="w-75 m-auto">
                  <HomeSearch
                    user={this.props.user}
                    blocks={this.props.blocks}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <ChooseSegmentList />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </Row>
        <Row className="justify-content-center">
          <div className="col homeSlider">
            <HomeSlider />
          </div>
        </Row>
        <Row className="no-gutters howWorks">
          <div className="col-6">
            <img
              src={require('../../assets/images/hpHowWorksImg.jpg')}
              alt=""
            />
          </div>
          <div className="col-6 d-flex">
            <div className="hpContent align-self-center">
              {!isEmpty(blocks.translations) && (
                <>
                  {blocks.translations.hasOwnProperty('how_does_it_work') && (
                    <>
                      {blocks.translations.how_does_it_work[
                        user.location.country
                      ].content !== undefined &&
                      blocks.translations.how_does_it_work[
                        user.location.country
                      ].content
                        ? parse(
                            blocks.translations.how_does_it_work[
                              user.location.country
                            ].content
                          )
                        : parse(
                            blocks.translations.how_does_it_work['USA'].content
                          )}
                    </>
                  )}
                </>
              )}
              <div className="readMoreBtn">
                <Link to="/how-it-works">
                  {!isEmpty(blocks.translations) && (
                    <>
                      {blocks.translations.hasOwnProperty('strfindoutmore') && (
                        <>
                          {blocks.translations.strfindoutmore[
                            user.location.country
                          ].content !== undefined &&
                          blocks.translations.strfindoutmore[
                            user.location.country
                          ].content
                            ? parse(
                                blocks.translations.strfindoutmore[
                                  user.location.country
                                ].content,
                                {
                                  replace: function(domNode) {
                                    if (domNode.hasOwnProperty('children')) {
                                      let text =
                                        domNode.children.length > 0
                                          ? domNode.children[0].data
                                          : '';
                                      return <>{text}</>;
                                    }

                                    return '';
                                  }
                                }
                              )
                            : parse(
                                blocks.translations.strfindoutmore['USA']
                                  .content,
                                {
                                  replace: function(domNode) {
                                    if (domNode.hasOwnProperty('children')) {
                                      let text =
                                        domNode.children.length > 0
                                          ? domNode.children[0].data
                                          : '';
                                      return <>{text}</>;
                                    }

                                    return '';
                                  }
                                }
                              )}
                        </>
                      )}
                    </>
                  )}{' '}
                  <i className={'far fa-chevron-right'} />
                </Link>
              </div>
            </div>
          </div>
        </Row>
        <Row className="no-gutters howWorks">
          <div className="col-6 d-flex">
            <div className="hpContent align-self-center">
              {!isEmpty(blocks.translations) && (
                <>
                  {blocks.translations.hasOwnProperty('about_us') && (
                    <>
                      {blocks.translations.about_us[user.location.country]
                        .content !== undefined &&
                      blocks.translations.about_us[user.location.country]
                        .content
                        ? parse(
                            blocks.translations.about_us[user.location.country]
                              .content
                          )
                        : parse(blocks.translations.about_us['USA'].content)}
                    </>
                  )}
                </>
              )}
              <div className="readMoreBtn">
                <Link to="/about-cbdd">
                  {!isEmpty(blocks.translations) && (
                    <>
                      {blocks.translations.hasOwnProperty('strreadmore') && (
                        <>
                          {blocks.translations.strreadmore[
                            user.location.country
                          ].content !== undefined &&
                          blocks.translations.strreadmore[user.location.country]
                            .content
                            ? parse(
                                blocks.translations.strreadmore[
                                  user.location.country
                                ].content,
                                {
                                  replace: function(domNode) {
                                    if (domNode.hasOwnProperty('children')) {
                                      let text =
                                        domNode.children.length > 0
                                          ? domNode.children[0].data
                                          : '';
                                      return <>{text}</>;
                                    }

                                    return '';
                                  }
                                }
                              )
                            : parse(
                                blocks.translations.strreadmore['USA'].content,
                                {
                                  replace: function(domNode) {
                                    if (domNode.hasOwnProperty('children')) {
                                      let text =
                                        domNode.children.length > 0
                                          ? domNode.children[0].data
                                          : '';
                                      return <>{text}</>;
                                    }

                                    return '';
                                  }
                                }
                              )}
                        </>
                      )}
                    </>
                  )}{' '}
                  <i className={'far fa-chevron-right'} />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-6">
            <img src={require('../../assets/images/hpAboutUsImg.jpg')} alt="" />
          </div>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    blocks: state.translations
  };
};

export default connect(mapStateToProps, null)(Home);
