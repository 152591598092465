import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  requestResultCollection,
  receiveResultCollection,
  freeFetch
} from '../../redux/cars/index';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Slider.css';
import { showStars, makeHash, isEmpty } from '../../functions';
import parse from 'html-react-parser';

class HomeSlider extends Component {
  onFetchResults = collection => {
    let dataToSend = {
      country: this.props.user.location.country,
      segment_id: null
    };

    this.props.segment_info.map(value => {
      if (value.name === collection.segment_name)
        dataToSend.segment_id = parseInt(value.id);
      return value;
    });

    //dispatch
    this.props.fetchCollection(dataToSend);
  };

  createSlider = settings => {
    if (
      !this.props.slider.isFetching &&
      this.props.slider.hasOwnProperty('isFetching')
    ) {
      const { user, blocks } = this.props;
      return (
        <Slider {...settings}>
          {this.props.slider.items.map((slide, index) => {
            return slide.best_seller ? (
              <div className="text-center" key={makeHash(2, index)}>
                <div className="row justify-content-center">
                  <div className="col-12">
                    <div className="d-flex align-items-center justify-content-center">
                      <h2>
                        {!isEmpty(this.props.blocks.translations) && (
                          <>
                            {this.props.blocks.translations.hasOwnProperty(
                              'strpopularcomparisons'
                            ) && (
                              <>
                                {this.props.blocks.translations[
                                  'strpopularcomparisons'
                                ][this.props.user.location.country].content !==
                                  undefined &&
                                this.props.blocks.translations[
                                  'strpopularcomparisons'
                                ][this.props.user.location.country].content
                                  ? parse(
                                      this.props.blocks.translations[
                                        'strpopularcomparisons'
                                      ][this.props.user.location.country]
                                        .content,
                                      {
                                        replace: function(domNode) {
                                          if (
                                            domNode.hasOwnProperty('children')
                                          ) {
                                            let text =
                                              domNode.children.length > 0
                                                ? domNode.children[0].data
                                                : '';

                                            if (text) {
                                              let first_half = text.match(
                                                new RegExp('(.*)<span')
                                              );
                                              if (first_half) {
                                                text = first_half[1];
                                              }
                                            }

                                            return <>{text}</>;
                                          }

                                          return '';
                                        }
                                      }
                                    )
                                  : parse(
                                      this.props.blocks.translations[
                                        'strpopularcomparisons'
                                      ]['USA'].content,
                                      {
                                        replace: function(domNode) {
                                          if (
                                            domNode.hasOwnProperty('children')
                                          ) {
                                            let text =
                                              domNode.children.length > 0
                                                ? domNode.children[0].data
                                                : '';

                                            if (text) {
                                              let first_half = text.match(
                                                new RegExp('(.*)<span')
                                              );
                                              if (first_half) {
                                                text = first_half[1];
                                              }
                                            }

                                            return <>{text}</>;
                                          }

                                          return '';
                                        }
                                      }
                                    )}
                              </>
                            )}
                          </>
                        )}
                        <span className="cbddRed">
                          {' '}
                          {!isEmpty(this.props.blocks.translations) && (
                            <>
                              {this.props.blocks.translations.hasOwnProperty(
                                'strpopularcomparisons'
                              ) && (
                                <>
                                  {this.props.blocks.translations[
                                    'strpopularcomparisons'
                                  ][this.props.user.location.country]
                                    .content !== undefined &&
                                  this.props.blocks.translations[
                                    'strpopularcomparisons'
                                  ][this.props.user.location.country].content
                                    ? parse(
                                        this.props.blocks.translations[
                                          'strpopularcomparisons'
                                        ][this.props.user.location.country]
                                          .content,
                                        {
                                          replace: function(domNode) {
                                            if (
                                              domNode.hasOwnProperty('children')
                                            ) {
                                              let text =
                                                domNode.children.length > 0
                                                  ? domNode.children[0].data
                                                  : '';

                                              if (text) {
                                                let second_half = text.match(
                                                  new RegExp('>(.*)</span')
                                                );
                                                if (second_half) {
                                                  text = second_half[1];
                                                  text = text.replace(
                                                    '&nbsp;',
                                                    ''
                                                  );
                                                }
                                              }

                                              return <>{text}</>;
                                            }

                                            return '';
                                          }
                                        }
                                      )
                                    : parse(
                                        this.props.blocks.translations[
                                          'strpopularcomparisons'
                                        ]['USA'].content,
                                        {
                                          replace: function(domNode) {
                                            if (
                                              domNode.hasOwnProperty('children')
                                            ) {
                                              let text =
                                                domNode.children.length > 0
                                                  ? domNode.children[0].data
                                                  : '';

                                              if (text) {
                                                let second_half = text.match(
                                                  new RegExp('>(.*)</span')
                                                );
                                                if (second_half) {
                                                  text = second_half[1];
                                                  text = text.replace(
                                                    '&nbsp;',
                                                    ''
                                                  );
                                                }
                                              }

                                              return <>{text}</>;
                                            }

                                            return '';
                                          }
                                        }
                                      )}
                                </>
                              )}
                            </>
                          )}
                        </span>
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-12">
                    <div className="d-flex align-items-center justify-content-center">
                      <p className="slider__segmentName mb-4">
                        {this.props.segment_info.map((value, index) => {
                          if (value.name === slide.segment_name) {
                            let vowels = ['\\\\', '%', '&', '.', '/', ' '];
                            let updatedString = value.alias.toLowerCase();
                            let pattern = new RegExp(
                              '[' + vowels.join(',') + ']',
                              'g'
                            );
                            updatedString = updatedString.replace(pattern, '_');
                            pattern = new RegExp('&amp;', 'g');
                            updatedString = updatedString.replace(pattern, '_');

                            if (updatedString === 'm_mpv_large') {
                              updatedString = 'm_mpv_med___large';
                            }

                            return (
                              !isEmpty(blocks.translations) && (
                                <>
                                  {blocks.translations.hasOwnProperty(
                                    updatedString
                                  ) && (
                                    <>
                                      {blocks.translations[updatedString][
                                        user.location.country
                                      ].content !== undefined &&
                                      blocks.translations[updatedString][
                                        user.location.country
                                      ].content
                                        ? parse(
                                            blocks.translations[updatedString][
                                              user.location.country
                                            ].content,
                                            {
                                              replace: function(domNode) {
                                                if (
                                                  domNode.hasOwnProperty(
                                                    'children'
                                                  )
                                                ) {
                                                  let text =
                                                    domNode.children.length > 0
                                                      ? domNode.children[0].data
                                                      : '';
                                                  return <>{text}</>;
                                                }

                                                return '';
                                              }
                                            }
                                          )
                                        : parse(
                                            blocks.translations[updatedString][
                                              'USA'
                                            ].content,
                                            {
                                              replace: function(domNode) {
                                                if (
                                                  domNode.hasOwnProperty(
                                                    'children'
                                                  )
                                                ) {
                                                  let text =
                                                    domNode.children.length > 0
                                                      ? domNode.children[0].data
                                                      : '';
                                                  return <>{text}</>;
                                                }

                                                return '';
                                              }
                                            }
                                          )}
                                    </>
                                  )}
                                </>
                              )
                            );
                          }

                          return '';
                        })}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-end">
                  <div className="col-auto big-car">
                    <Link className="highlight-image" to={'#'}>
                      <img
                        src={
                          slide.best_hidden_car.imageUrl != null
                            ? slide.best_hidden_car.imageUrl
                            : require('../../assets/images/empty.png')
                        }
                        className="mx-auto d-block"
                        alt={slide.best_hidden_car.unique_id}
                      />
                    </Link>
                  </div>
                  <div className="col-auto">
                    <div className="slider__carName w220 text-left">
                      {slide.best_hidden_car.brand}{' '}
                      {slide.best_hidden_car.model}
                      <br />
                      {slide.best_hidden_car.version_name}
                      <br />
                      <div className="rating">
                        {showStars(parseFloat(slide.best_hidden_car.grade))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col comparedWith">
                    <p className="pt-4 pb-4 m-0">
                      {!isEmpty(this.props.blocks.translations) && (
                        <>
                          {this.props.blocks.translations.hasOwnProperty(
                            'strcomparedwith'
                          ) && (
                            <>
                              {this.props.blocks.translations.strcomparedwith[
                                this.props.user.location.country
                              ].content !== undefined &&
                              this.props.blocks.translations.strcomparedwith[
                                this.props.user.location.country
                              ].content
                                ? parse(
                                    this.props.blocks.translations
                                      .strcomparedwith[
                                      this.props.user.location.country
                                    ].content,
                                    {
                                      replace: function(domNode) {
                                        if (
                                          domNode.hasOwnProperty('children')
                                        ) {
                                          let text =
                                            domNode.children.length > 0
                                              ? domNode.children[0].data
                                              : '';
                                          return <>{text}</>;
                                        }

                                        return '';
                                      }
                                    }
                                  )
                                : parse(
                                    this.props.blocks.translations
                                      .strcomparedwith['USA'].content,
                                    {
                                      replace: function(domNode) {
                                        if (
                                          domNode.hasOwnProperty('children')
                                        ) {
                                          let text =
                                            domNode.children.length > 0
                                              ? domNode.children[0].data
                                              : '';
                                          return <>{text}</>;
                                        }

                                        return '';
                                      }
                                    }
                                  )}
                            </>
                          )}
                        </>
                      )}
                    </p>
                  </div>
                </div>
                <div className="row">
                  {slide.best_cars.map((value, index) => {
                    return slide.best_hidden_car.id !== value.id ? (
                      <div key={makeHash(3, index)} className="col-4">
                        <div className="sliderBorders pt-4 pb-4">
                          <div className="sliderInrImg">
                            <Link className="highlight-image" to={'#'}>
                              <img
                                src={
                                  value.imageUrl != null
                                    ? value.imageUrl
                                    : require('../../assets/images/empty.png')
                                }
                                className="align-center"
                                alt={value.unique_id}
                              />
                            </Link>
                          </div>

                          <div className="slider__carName text-left ml-3 mt-3">
                            {value.brand} {value.model}
                            <br />
                            <span className="cardBrandModel">
                              {value.version_name}
                            </span>
                            <div className="rating">
                              {showStars(parseFloat(value.grade))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    );
                  })}
                </div>
                <div className="row">
                  <div className="col">
                    <Link
                      onClick={() => this.onFetchResults(slide)}
                      className="compareBtn d-inline-block"
                      to={{
                        pathname: '/demo-compare',
                        state: { cars: slide.best_cars }
                      }}
                    >
                      {!isEmpty(this.props.blocks.translations) && (
                        <>
                          {this.props.blocks.translations.hasOwnProperty(
                            'strseecomparedetails'
                          ) && (
                            <>
                              {this.props.blocks.translations
                                .strseecomparedetails[
                                this.props.user.location.country
                              ].content !== undefined &&
                              this.props.blocks.translations
                                .strseecomparedetails[
                                this.props.user.location.country
                              ].content
                                ? parse(
                                    this.props.blocks.translations
                                      .strseecomparedetails[
                                      this.props.user.location.country
                                    ].content,
                                    {
                                      replace: function(domNode) {
                                        if (
                                          domNode.hasOwnProperty('children')
                                        ) {
                                          let text =
                                            domNode.children.length > 0
                                              ? domNode.children[0].data
                                              : '';
                                          return <>{text}</>;
                                        }

                                        return '';
                                      }
                                    }
                                  )
                                : parse(
                                    this.props.blocks.translations
                                      .strseecomparedetails['USA'].content,
                                    {
                                      replace: function(domNode) {
                                        if (
                                          domNode.hasOwnProperty('children')
                                        ) {
                                          let text =
                                            domNode.children.length > 0
                                              ? domNode.children[0].data
                                              : '';
                                          return <>{text}</>;
                                        }

                                        return '';
                                      }
                                    }
                                  )}
                            </>
                          )}
                        </>
                      )}
                    </Link>
                  </div>
                </div>
              </div>
            ) : (
              ''
            );
          })}
        </Slider>
      );
    }
    return <div className="center-Y cm-spinner cm-spinner-slider" />;
  };

  render() {
    const settings = {
      className: 'center text-center',
      centerMode: true,
      infinite: true,
      centerPadding: '0px',
      slidesToShow: 1,
      speed: 500,
      lazyLoad: true
    };
    return <div>{this.createSlider(settings)}</div>;
  }
}

const mapStateToProps = state => {
  return {
    slider: state.bestseller,
    user: state.user,
    blocks: state.translations,
    segment_info: state.segment.items
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchCollection: collection => {
      dispatch(requestResultCollection());
      return freeFetch(collection)
        .then(response => response.data)
        .then(data => dispatch(receiveResultCollection(data)));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeSlider);
