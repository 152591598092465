import {
  REQUEST_DEALERS_COLLECTION,
  RECEIVE_DEALERS_COLLECTION
} from './types';
import axios from 'axios';
import Config from '../../config';

const uri_cars_dealers = '/dealers';
const uri_cars_filter_dealers = '/dealers/filter';

export const requestDealersCollection = () => {
  return {
    type: REQUEST_DEALERS_COLLECTION
  };
};

export const receiveDealersCollection = data => {
  return {
    type: RECEIVE_DEALERS_COLLECTION,
    dealers: data['dealers'],
    brands: data['brands'],
    cities: data['cities'],
    receivedAt: Date.now()
  };
};

export const fetch = data => {
  return axios.get(
    Config.LARAVEL_API.URL + uri_cars_dealers,
    { params: data },
    {
      'Content-Type': 'application/json'
    }
  );
};

export const fetchFilter = data => {
  return axios.get(
    Config.LARAVEL_API.URL + uri_cars_filter_dealers,
    { params: data },
    {
      'Content-Type': 'application/json'
    }
  );
};
