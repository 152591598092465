import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  requestDealersCollection,
  receiveDealersCollection,
  fetch
} from '../../redux/dealers/index';
import { FAIL_GET_RESULT } from '../../redux/dealers/types';
import { showStars, getSuffixForPlace, isEmpty } from '../../functions';
import { Table, Card, Button, Collapse } from 'react-bootstrap';
import parse from 'html-react-parser';

class Charateristics extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      open_first: true,
      open_second: false,
      diffTable: {},
      propertiesTable: {}
    };
  }

  UNSAFE_componentWillMount() {
    this.formTables(this.props.info);
  }

  setPlaceForCar = id => {
    let place = 1;
    const collection = this.props.places;
    for (let item in collection) {
      if (parseInt(collection[item].car_id) === parseInt(id)) {
        place = parseInt(item) + 1;
        break;
      }
    }

    return <h1 className="text-center">{place}</h1>;
  };

  fetchDealers = id => {
    this.props.fetchCollection({
      id: parseInt(id),
      country: this.props.user.location.country,
      uc: this.props.user.location.lat + '|' + this.props.user.location.lon
    });
  };

  showRows = (data, fieldName) => {
    return data.map((value, index) => {
      return (
        <td width="250px" align="center" key={index}>
          {value[fieldName]}
        </td>
      );
    });
  };

  removeDuplicates(array) {
    let result = [];
    let len = array.length;
    let assoc = {};

    while (len--) {
      let item = array[len];

      if (!assoc[item]) {
        result.unshift(item);
        assoc[item] = true;
      }
    }

    return result;
  }

  formTables(info) {
    let row = {};
    let diffRows = {};
    let info_length = info.length;
    let properties_list = [];
    for (let i = 0; i < info_length; i++) {
      properties_list = properties_list.concat(Object.keys(info[i].properties));
    }

    properties_list.sort();
    properties_list = this.removeDuplicates(properties_list);
    let length = properties_list.length;

    for (let j = 0; j < length; j++) {
      for (let i = 0; i < info_length; i++) {
        let property = info[i].properties[properties_list[j]];
        if (property) {
          if (!row.hasOwnProperty(property.name)) {
            row[property.name] = [];
            diffRows[property.name] = [];
          }
          row[property.name].push(
            property.yesNo === 'Yes' ? (
              <i className="fal fa-check-circle" />
            ) : (
              <i className="fal fa-times-circle" />
            )
          );
          diffRows[property.name].push(property.yesNo === 'Yes' ? true : false);
        }
      }
    }

    for (let value in row) {
      if (row[value].length < info.length) {
        let amount_to_apply = info.length - row[value].length;
        for (let i = 0; i < amount_to_apply; i++) {
          row[value].push(<i className="fal fa-times-circle" />);
          diffRows[value].push(false);
        }
      }
    }

    let object = {};
    let positiveOrNegative = {};
    for (let dataItem in diffRows) {
      let counterTrue = 0,
        counterFalse = 0;
      for (let i = 0; i < diffRows[dataItem].length; i++) {
        diffRows[dataItem][i] ? counterTrue++ : counterFalse++;
      }

      if (counterTrue > 0 && counterFalse > 0) {
        if (!object.hasOwnProperty(dataItem)) object[dataItem] = [];
        object[dataItem] = row[dataItem];
      }

      if (
        counterTrue === diffRows[dataItem].length ||
        counterFalse === diffRows[dataItem].length
      ) {
        if (!positiveOrNegative.hasOwnProperty(dataItem))
          positiveOrNegative[dataItem] = [];
        positiveOrNegative[dataItem] = row[dataItem];
      }
    }

    this.setState({
      open_first: Object.keys(object).length === 0 ? false : true,
      diffTable: object,
      propertiesTable: positiveOrNegative
    });

    diffRows = {};
    object = {};
    positiveOrNegative = {};
  }

  displayPropertiesTables = row => {
    return (
      <>
        {Object.keys(row).map((value, index) => {
          return (
            <tr key={index}>
              <td width="">{value}</td>
              {row[value].map((data, index) => {
                return (
                  <td width="250px" align="center" key={index}>
                    {data}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </>
    );
  };

  render() {
    const { cars, info, user, blocks } = this.props;
    const { open_first, open_second, diffTable, propertiesTable } = this.state;
    return (
      <div className="resaultContainer">
        <div className="container-fluid">
          <div className="row d-flex justify-content-end">
            {cars.map((car, index) => {
              return (
                <Card
                  key={index}
                  className={'col-auto' + (car.place === 1 ? ' winner' : '')}
                >
                  <Card.Body>
                    <Card.Title className="text-left">
                      <div className="carPlace">
                        <span className="carPlace__number">{car.place}</span>
                        <span className="carPlace__sufx">
                          {getSuffixForPlace(car.place)}
                        </span>
                      </div>
                      <span className="cardBrandModel">
                        {car.brand} {car.model}
                      </span>
                      <span className="cardVersion">{car.version_name}</span>
                      <span className="rating">
                        {showStars(parseFloat(info[index].grade))}
                      </span>
                    </Card.Title>
                    <Card.Img
                      variant="top"
                      src={
                        info[index].imageUrl != null
                          ? info[index].imageUrl
                          : require('../../assets/images/empty.png')
                      }
                    />
                    <div className="card__prices">
                      <p className="resaultListPrice">
                        {!isEmpty(blocks.translations) && (
                          <>
                            {blocks.translations.hasOwnProperty(
                              'strlistprice'
                            ) && (
                              <>
                                {blocks.translations.strlistprice[
                                  user.location.country
                                ].content !== undefined &&
                                blocks.translations.strlistprice[
                                  user.location.country
                                ].content
                                  ? parse(
                                      blocks.translations.strlistprice[
                                        user.location.country
                                      ].content,
                                      {
                                        replace: function(domNode) {
                                          if (
                                            domNode.hasOwnProperty('children')
                                          ) {
                                            let text =
                                              domNode.children.length > 0
                                                ? domNode.children[0].data
                                                : '';
                                            return <>{text}</>;
                                          }

                                          return '';
                                        }
                                      }
                                    )
                                  : parse(
                                      blocks.translations.strlistprice['USA']
                                        .content,
                                      {
                                        replace: function(domNode) {
                                          if (
                                            domNode.hasOwnProperty('children')
                                          ) {
                                            let text =
                                              domNode.children.length > 0
                                                ? domNode.children[0].data
                                                : '';
                                            return <>{text}</>;
                                          }

                                          return '';
                                        }
                                      }
                                    )}
                              </>
                            )}
                          </>
                        )}
                        <span className="float-right card_listPrice">
                          {!user.isFetching && (
                            <i
                              className={
                                user.location.currency === 'NIS'
                                  ? 'fal fa-shekel-sign'
                                  : user.location.currency === 'RUB'
                                  ? 'fal fa-ruble-sign'
                                  : 'fal fa-dollar-sign'
                              }
                            />
                          )}
                          {info[index].list_price}
                        </span>
                      </p>
                      <p>
                        {!isEmpty(blocks.translations) && (
                          <>
                            {blocks.translations.hasOwnProperty(
                              'strtransactionprice'
                            ) && (
                              <>
                                {blocks.translations.strtransactionprice[
                                  user.location.country
                                ].content !== undefined &&
                                blocks.translations.strtransactionprice[
                                  user.location.country
                                ].content
                                  ? parse(
                                      blocks.translations.strtransactionprice[
                                        user.location.country
                                      ].content,
                                      {
                                        replace: function(domNode) {
                                          if (
                                            domNode.hasOwnProperty('children')
                                          ) {
                                            let text =
                                              domNode.children.length > 0
                                                ? domNode.children[0].data
                                                : '';
                                            return <>{text}</>;
                                          }

                                          return '';
                                        }
                                      }
                                    )
                                  : parse(
                                      blocks.translations.strtransactionprice[
                                        'USA'
                                      ].content,
                                      {
                                        replace: function(domNode) {
                                          if (
                                            domNode.hasOwnProperty('children')
                                          ) {
                                            let text =
                                              domNode.children.length > 0
                                                ? domNode.children[0].data
                                                : '';
                                            return <>{text}</>;
                                          }

                                          return '';
                                        }
                                      }
                                    )}
                              </>
                            )}
                          </>
                        )}
                        <span className="float-right card_transPrice">
                          {!user.isFetching && (
                            <i
                              className={
                                user.location.currency === 'NIS'
                                  ? 'fal fa-shekel-sign'
                                  : user.location.currency === 'RUB'
                                  ? 'fal fa-ruble-sign'
                                  : 'fal fa-dollar-sign'
                              }
                            />
                          )}
                          {info[index].transaction_price}
                        </span>
                      </p>
                      <span className="chooseBtn">
                        <Link
                          className="btn btn-primary"
                          onClick={() => this.fetchDealers(car.id)}
                          to="/find-a-deale"
                        >
                          {!isEmpty(blocks.translations) && (
                            <>
                              {blocks.translations.hasOwnProperty(
                                'strchoosethiscar'
                              ) && (
                                <>
                                  {blocks.translations.strchoosethiscar[
                                    user.location.country
                                  ].content !== undefined &&
                                  blocks.translations.strchoosethiscar[
                                    user.location.country
                                  ].content
                                    ? parse(
                                        blocks.translations.strchoosethiscar[
                                          user.location.country
                                        ].content,
                                        {
                                          replace: function(domNode) {
                                            if (
                                              domNode.hasOwnProperty('children')
                                            ) {
                                              let text =
                                                domNode.children.length > 0
                                                  ? domNode.children[0].data
                                                  : '';
                                              return <>{text}</>;
                                            }

                                            return '';
                                          }
                                        }
                                      )
                                    : parse(
                                        blocks.translations.strchoosethiscar[
                                          'USA'
                                        ].content,
                                        {
                                          replace: function(domNode) {
                                            if (
                                              domNode.hasOwnProperty('children')
                                            ) {
                                              let text =
                                                domNode.children.length > 0
                                                  ? domNode.children[0].data
                                                  : '';
                                              return <>{text}</>;
                                            }

                                            return '';
                                          }
                                        }
                                      )}
                                </>
                              )}
                            </>
                          )}
                        </Link>
                      </span>
                    </div>
                  </Card.Body>
                </Card>
              );
            })}
          </div>
        </div>
        <Table responsive>
          <tbody>
            <tr>
              <td>
                {!isEmpty(blocks.translations) && (
                  <>
                    {blocks.translations.hasOwnProperty('strlistprice') && (
                      <>
                        {blocks.translations.strlistprice[user.location.country]
                          .content !== undefined &&
                        blocks.translations.strlistprice[user.location.country]
                          .content
                          ? parse(
                              blocks.translations.strlistprice[
                                user.location.country
                              ].content,
                              {
                                replace: function(domNode) {
                                  if (domNode.hasOwnProperty('children')) {
                                    let text =
                                      domNode.children.length > 0
                                        ? domNode.children[0].data
                                        : '';
                                    return <>{text}</>;
                                  }

                                  return '';
                                }
                              }
                            )
                          : parse(
                              blocks.translations.strlistprice['USA'].content,
                              {
                                replace: function(domNode) {
                                  if (domNode.hasOwnProperty('children')) {
                                    let text =
                                      domNode.children.length > 0
                                        ? domNode.children[0].data
                                        : '';
                                    return <>{text}</>;
                                  }

                                  return '';
                                }
                              }
                            )}
                      </>
                    )}
                  </>
                )}
              </td>
              {this.showRows(info, 'list_price')}
            </tr>
            <tr>
              <td>
                {!isEmpty(blocks.translations) && (
                  <>
                    {blocks.translations.hasOwnProperty(
                      'strtransactionprice'
                    ) && (
                      <>
                        {blocks.translations.strtransactionprice[
                          user.location.country
                        ].content !== undefined &&
                        blocks.translations.strtransactionprice[
                          user.location.country
                        ].content
                          ? parse(
                              blocks.translations.strtransactionprice[
                                user.location.country
                              ].content,
                              {
                                replace: function(domNode) {
                                  if (domNode.hasOwnProperty('children')) {
                                    let text =
                                      domNode.children.length > 0
                                        ? domNode.children[0].data
                                        : '';
                                    return <>{text}</>;
                                  }

                                  return '';
                                }
                              }
                            )
                          : parse(
                              blocks.translations.strtransactionprice['USA']
                                .content,
                              {
                                replace: function(domNode) {
                                  if (domNode.hasOwnProperty('children')) {
                                    let text =
                                      domNode.children.length > 0
                                        ? domNode.children[0].data
                                        : '';
                                    return <>{text}</>;
                                  }

                                  return '';
                                }
                              }
                            )}
                      </>
                    )}
                  </>
                )}
              </td>
              {this.showRows(info, 'transaction_price')}
            </tr>
            <tr>
              <td>Comperably equipped price grade</td>
              {this.showRows(info, 'grade')}
            </tr>
          </tbody>
        </Table>
        <div className="allParamsBtnWrap align-items-center d-flex">
          <div className="flex-fill" />
          <Button
            onClick={() => this.setState({ open_first: !open_first })}
            aria-controls="example-collapse-text"
            className={
              open_first ? 'allParamsBtn minus-clps' : 'allParamsBtn clps'
            }
            aria-expanded={open_first}
          >
            {!isEmpty(blocks.translations) && (
              <>
                {blocks.translations.hasOwnProperty(
                  'stradvantagesdisadvantages'
                ) && (
                  <>
                    {blocks.translations.stradvantagesdisadvantages[
                      user.location.country
                    ].content !== undefined &&
                    blocks.translations.stradvantagesdisadvantages[
                      user.location.country
                    ].content
                      ? parse(
                          blocks.translations.stradvantagesdisadvantages[
                            user.location.country
                          ].content,
                          {
                            replace: function(domNode) {
                              if (domNode.hasOwnProperty('children')) {
                                let text =
                                  domNode.children.length > 0
                                    ? domNode.children[0].data
                                    : '';
                                return <>{text}</>;
                              }

                              return '';
                            }
                          }
                        )
                      : parse(
                          blocks.translations.stradvantagesdisadvantages['USA']
                            .content,
                          {
                            replace: function(domNode) {
                              if (domNode.hasOwnProperty('children')) {
                                let text =
                                  domNode.children.length > 0
                                    ? domNode.children[0].data
                                    : '';
                                return <>{text}</>;
                              }

                              return '';
                            }
                          }
                        )}
                  </>
                )}
              </>
            )}
          </Button>
          <div className="flex-fill" />
        </div>
        <Collapse in={open_first}>
          <Table className="advDis" responsive>
            <tbody>{this.displayPropertiesTables(diffTable)}</tbody>
          </Table>
        </Collapse>
        <div className="allParamsBtnWrap align-items-center d-flex">
          <div className="flex-fill" />
          <Button
            onClick={() => this.setState({ open_second: !open_second })}
            aria-controls="example-collapse-text"
            className={
              !open_second ? 'allParamsBtn clps' : 'allParamsBtn minus-clps'
            }
            aria-expanded={open_second}
          >
            {!isEmpty(blocks.translations) && (
              <>
                {blocks.translations.hasOwnProperty('strallparameters') && (
                  <>
                    {blocks.translations.strallparameters[user.location.country]
                      .content !== undefined &&
                    blocks.translations.strallparameters[user.location.country]
                      .content
                      ? parse(
                          blocks.translations.strallparameters[
                            user.location.country
                          ].content,
                          {
                            replace: function(domNode) {
                              if (domNode.hasOwnProperty('children')) {
                                let text =
                                  domNode.children.length > 0
                                    ? domNode.children[0].data
                                    : '';
                                return <>{text}</>;
                              }

                              return '';
                            }
                          }
                        )
                      : parse(
                          blocks.translations.strallparameters['USA'].content,
                          {
                            replace: function(domNode) {
                              if (domNode.hasOwnProperty('children')) {
                                let text =
                                  domNode.children.length > 0
                                    ? domNode.children[0].data
                                    : '';
                                return <>{text}</>;
                              }

                              return '';
                            }
                          }
                        )}
                  </>
                )}
              </>
            )}
          </Button>
          <div className="flex-fill" />
        </div>
        <Collapse in={open_second}>
          <Table className="allpar" responsive>
            <tbody>{this.displayPropertiesTables(propertiesTable)}</tbody>
          </Table>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    blocks: state.translations
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchCollection: collection => {
      dispatch(requestDealersCollection());
      return fetch(collection)
        .then(response => response.data)
        .then(data => dispatch(receiveDealersCollection(data)))
        .catch(error => {
          let data = {};
          data['payload'] = error.response.data.message;
          data['receivedAt'] = Date.now();
          dispatch({ type: FAIL_GET_RESULT, data });
        });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Charateristics);
