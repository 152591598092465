import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Service from '../../api';
import Config from '../../config';
import { authHeader } from '../../redux/users/services/auth-header';
import { dispatchLogin } from '../../redux/modals/index';

class Confirm extends Component {
  state = {
    message: '',
    confirming: false
  };

  componentDidMount() {
    const { token } = this.props.match.params;

    this.setState({ confirming: true });
    let service = new Service({ ...authHeader() });
    return service
      .get(Config.LARAVEL_API.URL + '/confirm/' + token)
      .then(
        response => {
          this.setState({
            message: response.data.message,
            confirming: false
          });
        },
        error => {
          this.setState({
            message: error.response.data.message,
            confirming: false
          });
        }
      )
      .catch(error => {
        this.setState({
          message: error.response.data.message,
          confirming: false
        });
      });
  }

  render() {
    const { user_info } = this.props;
    const { confirming, message } = this.state;
    return (
      <div className="row">
        <div className="container">
          <div className="confirm">
            {confirming ? (
              <div className="cm-spinner" />
            ) : (
              <div className="text-center">
                <p>{message}</p>
                {!user_info.loggedIn && (
                  <>
                    <div className="compareBtnWrap">
                      <div
                        className="compareBtn text-center"
                        onClick={e => this.props.handleLoginModal()}
                      >
                        Log-in
                      </div>
                    </div>
                  </>
                )}
                {user_info.loggedIn && (
                  <Link to="/personal-info">Go to personal page</Link>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user_info: state.user_info
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleLoginModal: () => {
      dispatch(dispatchLogin('open'));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Confirm);
