import {
  REQUEST_RESULT_COLLECTION,
  RECEIVE_RESULT_COLLECTION,
  FAIL_GET_RESULT
} from './types';

export function carsReducer(state = {}, action = {}) {
  switch (action.type) {
    case REQUEST_RESULT_COLLECTION:
      return {
        isFetching: true,
        didInvalidate: false
      };
    case RECEIVE_RESULT_COLLECTION:
      return {
        isFetching: false,
        didInvalidate: false,
        collection: action.collection,
        places: action.places,
        lastUpdated: action.receivedAt
      };
    case FAIL_GET_RESULT:
      return {
        isFetching: false,
        didInvalidate: false,
        message: action.data.payload,
        lastUpdated: action.data.receivedAt
      };
    default:
      return state;
  }
}
