import React, { Component } from 'react';

class NotFound extends Component {
  componentDidMount() {
    document.body.className = '';
    document.body.classList.add('notfound');
  }

  render() {
    return <h2 className="text-center">Page not found(404)</h2>;
  }
}

export default NotFound;
