import React, { Component } from 'react';

class PrivacyPolicy extends Component {
  componentDidMount() {
    document.body.className = '';
    document.body.classList.add('privacyPolicyPage');
  }

  render() {
    return (
      <React.Fragment>
        <div className="pageTitle d-flex justify-content-center">
          <h1>
            <span className="cbddRed">Privacy Policy</span>
          </h1>
        </div>
        <div className="container justify-content-center privacyPolicy">
          <p>
            This Car Business Data Dynamics Ltd. web-site WWW.CHOOSEYOURCAR.COM
            privacy policy has been compiled to better serve those who are
            concerned with how their ‘Personally identifiable information’ (PII)
            is being used online. PII, as used in US privacy law and information
            security, is information that can be used on its own or with other
            information to identify, contact, or locate a single person, or to
            identify an individual in context. Please read our privacy policy
            carefully to get a clear understanding of how we collect, use,
            protect or otherwise handle your Personally Identifiable Information
            in accordance with our website.
          </p>
          <p className="question">
            What personal information do we collect from the people that visit
            our blog, website or app?
          </p>
          <p>
            When ordering or registering on our site, as appropriate, you may be
            asked to enter your name, email address, mailing address, phone
            number, Company Name or other details to help you with your
            experience.
          </p>
          <p className="question">When do we collect information?</p>
          <p>
            We collect information from you when you place an order, fill out a
            form or enter information on our site.
          </p>
          <p className="question">How do we use your information?</p>
          <p>
            We may use the information we collect from you when you register,
            make a purchase, sign up for our newsletter, respond to a survey or
            marketing communication, surf the website, or use certain other site
            features in the following ways:
          </p>
          <ul>
            <li>
              To personalize user’s experience and to allow us to deliver the
              type of content and product offerings in which you are most
              interested.
            </li>
            <li>
              To allow us to better service you in responding to your customer
              service requests.
            </li>
            <li>To quickly process your transactions.</li>
            <li>
              To send periodic emails regarding your order or other products and
              services.
            </li>
          </ul>
          <p className="question">How do we protect visitor information?</p>
          <p>
            We do not use vulnerability scanning and/or scanning to PCI
            standards.
          </p>
          <p>We use regular Malware Scanning.</p>
          <p>
            Your personal information is contained behind secured networks and
            is only accessible by a limited number of persons who have special
            access rights to such systems, and are required to keep the
            information confidential. In addition, all sensitive/credit
            information you supply is encrypted via Secure Socket Layer (SSL)
            technology.
          </p>
          <p>
            We implement a variety of security measures when a user places an
            order enters, submits, or accesses their information to maintain the
            safety of your personal information.
          </p>
          <p className="question">
            All transactions are processed through a gateway provider (PayPal)
            and are not stored or processed on our servers.
          </p>
          <p className="question">Do we use ‘cookies’?</p>
          <p>
            Yes. Cookies are small files that a site or its service provider
            transfers to your computer’s hard drive through your Web browser (if
            you allow) that enables the site’s or service provider’s systems to
            recognize your browser and capture and remember certain information.
            For instance, we use cookies to help us remember and process the
            items in your shopping cart. They are also used to help us
            understand your preferences based on previous or current site
            activity, which enables us to provide you with improved services. We
            also use cookies to help us compile aggregate data about site
            traffic and site interaction so that we can offer better site
            experiences and tools in the future.
          </p>
          <p className="question">We use cookies to:</p>
          <ul>
            <li>Help remember and process the items in the shopping cart.</li>
            <li>Understand and save user’s preferences for future visits.</li>
            <li>Keep track of advertisements.</li>
            <li>
              Compile aggregate data about site traffic and site interactions in
              order to offer better site experiences and tools in the future. We
              may also use trusted third-party services that track this
              information on our behalf.
            </li>
          </ul>
          <p>
            You can choose to have your computer warn you each time a cookie is
            being sent, or you can choose to turn off all cookies. You do this
            through your browser (like Internet Explorer) settings. Each browser
            is a little different, so look at your browser’s Help menu to learn
            the correct way to modify your cookies.
          </p>
          <p className="question">If users disable cookies in their browser:</p>
          <p>
            If you disable cookies off, some features will be disabled It will
            turn off some of the features that make your site experience more
            efficient and some of our services will not function properly.
          </p>
          <p>
            However, you can still place orders via the telephone by contacting
            CBDD customer service.
          </p>
          <p className="question">Third-party disclosure</p>
          <p>
            We do not sell, trade, or otherwise transfer to outside parties your
            personally identifiable information unless we provide users with
            advance notice. This does not include website hosting partners and
            other parties who assist us in operating our website, conducting our
            business, or serving our users, so long as those parties agree to
            keep this information confidential. We may also release information
            when it’s release is appropriate to comply with the law, enforce our
            site policies, or protect ours or others’ rights, property, or
            safety. However, non-personally identifiable visitor information may
            be provided to other parties for marketing, advertising, or other
            uses.
          </p>
          <p className="question">Third-party links</p>
          <p>
            Occasionally, at our discretion, we may include or offer third-party
            products or services on our website. These third-party sites have
            separate and independent privacy policies. We therefore have no
            responsibility or liability for the content and activities of these
            linked sites. Nonetheless, we seek to protect the integrity of our
            site and welcome any feedback about these sites.
          </p>
          <p className="question">Google</p>
          <p>
            Google’s advertising requirements can be summed up by Google’s
            Advertising Principles. They are put in place to provide a positive
            experience for users.
            https://support.google.com/adwordspolicy/answer/1316548?hl=en
          </p>
          <p>We use Google AdSense Advertising on our website.</p>
          <p>
            Google, as a third-party vendor, uses cookies to serve ads on our
            site. Google’s use of the DART cookie enables it to serve ads to our
            users based on previous visits to our site and other sites on the
            Internet. Users may opt-out of the use of the DART cookie by
            visiting the Google Ad and Content Network privacy policy.
          </p>
          <p className="question">We have implemented the following:</p>
          <ul>
            <li>Remarketing with Google AdSense</li>
            <li>Google Display Network Impression Reporting</li>
            <li>Demographics and Interests Reporting</li>
            <li>DoubleClick Platform Integration</li>
          </ul>
          <p>
            We along with third-party vendors, such as Google use first-party
            cookies (such as the Google Analytics cookies) and third-party
            cookies (such as the DoubleClick cookie) or other third-party
            identifiers together to compile data regarding user interactions
            with ad impressions and other ad service functions as they relate to
            our website.
          </p>
          <p>
            Opting out: users can set preferences for how Google advertises to
            you using the Google Ad Settings page. Alternatively, you can opt
            out by visiting the Network Advertising initiative opt out page or
            permanently using the Google Analytics Opt Out Browser add on.
          </p>
          <p className="question">
            We collect your email address in order to: send e-mails to a client
            in sake of providing CBDD data ordered and improving Customer
            service.
          </p>
          <p>
            <span className="question">
              If at any time you would like to unsubscribe from receiving future
              emails, you can email us (support@chooseyourcar.com)
            </span>
            and we will promptly remove you from{' '}
            <span className="question">ALL</span> correspondence.
          </p>
          <p className="question">Contacting Us</p>
          <p>
            If there are any questions regarding this privacy policy you may
            contact us using the information below.
          </p>
          <p>Car Business Data Dynamics, Ltd.</p>
          <p>www.chooseyourcar.com</p>
          <p>E-mail: support@chooseyourcar.com</p>
        </div>
      </React.Fragment>
    );
  }
}

export default PrivacyPolicy;
