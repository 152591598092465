import React, { Component } from 'react';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import { isEmpty } from './../../functions';

class HowItWorks extends Component {
  componentDidMount() {
    document.body.className = '';
    document.body.classList.add('howitworks');
  }

  render() {
    const { user, blocks } = this.props;
    return (
      <div style={{ background: '#f7f7f7' }}>
        <div className="container aboutCBDD">
          {!isEmpty(blocks.translations) && (
            <>
              {blocks.translations.hasOwnProperty('how_does_it_work_page') && (
                <>
                  {blocks.translations.how_does_it_work_page[
                    user.location.country
                  ].content !== undefined &&
                  blocks.translations.how_does_it_work_page[
                    user.location.country
                  ].content
                    ? parse(
                        blocks.translations.how_does_it_work_page[
                          user.location.country
                        ].content
                      )
                    : parse(
                        blocks.translations.how_does_it_work_page['USA'].content
                      )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    blocks: state.translations
  };
};

export default connect(mapStateToProps, null)(HowItWorks);
