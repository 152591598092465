import {
  REQUEST_DEALERS_COLLECTION,
  RECEIVE_DEALERS_COLLECTION,
  FAIL_GET_RESULT
} from './types';

export function dealersReducer(state = { isFetching: false }, action = {}) {
  switch (action.type) {
    case REQUEST_DEALERS_COLLECTION:
      return {
        isFetching: true,
        didInvalidate: false
      };
    case RECEIVE_DEALERS_COLLECTION:
      return {
        isFetching: false,
        didInvalidate: false,
        dealers: action.dealers,
        brands: action.brands,
        cities: action.cities,
        lastUpdated: action.receivedAt
      };
    case FAIL_GET_RESULT:
      return {
        isFetching: false,
        didInvalidate: false,
        message: action.data.payload,
        lastUpdated: action.data.receivedAt
      };
    default:
      return state;
  }
}
