import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Autosuggest from 'react-autosuggest';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import axios from 'axios';
import { debounce } from 'throttle-debounce';
import Config from '../../config';
import parse from 'html-react-parser';
import { isEmpty } from './../../functions';

class HomeSearch extends Component {
  state = {
    value: '',
    suggestions: [],
    noSuggestions: false,
    isFetching: false
  };

  UNSAFE_componentWillMount() {
    this.onSuggestionsFetchRequested = debounce(
      500,
      this.onSuggestionsFetchRequested
    );
  }

  renderSuggestionsContainer = ({ containerProps, children, query }) => {
    if (!this.state.isFetching) {
      return <div {...containerProps}>{children}</div>;
    }
  };

  renderSuggestion = (suggestion, { query }) => {
    const suggestionText = `${suggestion.unique_id}`;
    const matches = AutosuggestHighlightMatch(suggestionText, query);
    const parts = AutosuggestHighlightParse(suggestionText, matches);
    return (
      <Link
        to={{
          pathname:
            '/compare/' +
            encodeURIComponent(suggestion.segment) +
            '/' +
            encodeURIComponent(suggestion.unique_id),
          state: {
            single_id: suggestion.id,
            single_img_url: suggestion.imageUrl,
            single_unique_id: suggestion.unique_id,
            single_segment: suggestion.segment,
            single_brand: suggestion.brand,
            single_model: suggestion.model,
            single_version_name: suggestion.version_name,
            single_list_price: suggestion.list_price,
            single_transaction_price: suggestion.transaction_price
          }
        }}
      >
        <span className="name">
          {parts.map((part, index) => {
            const className = part.highlight ? 'highlight' : null;

            return (
              <span className={className} key={index}>
                {part.text}
              </span>
            );
          })}
        </span>
      </Link>
    );
  };

  onChange = (event, { newValue }) => {
    this.setState({ value: newValue });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    const uri_cars = '/suggestions-fetch-requested';
    const isInputBlank = value.trim() === '';
    let noSuggestions = [];
    this.setState({ isFetching: true });

    axios
      .post(Config.LARAVEL_API.URL + uri_cars, {
        search: value,
        country: this.props.user.location.country
      })
      .then(
        response => {
          const results = response.data.hits.hits.map(h => h._source);
          noSuggestions = !isInputBlank && results.length === 0;
          this.setState({
            suggestions: results,
            noSuggestions: noSuggestions,
            isFetching: false
          });
        },
        error => {
          this.setState({
            suggestions: [],
            noSuggestions: false,
            isFetching: false
          });
        }
      )
      .catch(error => {
        this.setState({
          suggestions: [],
          noSuggestions: false,
          isFetching: false
        });
      });
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  render() {
    const { value, suggestions, noSuggestions, isFetching } = this.state;

    const theme = {
      container: 'autosuggest',
      input: 'form-control',
      suggestionsContainer: 'dropdown',
      suggestionsList: `dropdown-menu min-wp-100 ${
        suggestions.length ? 'show' : ''
      }`,
      suggestion: 'dropdown-item',
      suggestionFocused: 'active'
    };

    let placeholder = '';
    if (!isEmpty(this.props.blocks.translations)) {
      if (
        this.props.blocks.translations.strtypebrandmodelname[
          this.props.user.location.country
        ].content !== undefined &&
        this.props.blocks.translations.strtypebrandmodelname[
          this.props.user.location.country
        ].content
      ) {
        placeholder = parse(
          this.props.blocks.translations.strtypebrandmodelname[
            this.props.user.location.country
          ].content,
          {
            replace: function(domNode) {
              if (domNode.hasOwnProperty('children')) {
                let text =
                  domNode.children.length > 0 ? domNode.children[0].data : '';
                return <>{text}</>;
              }

              return '';
            }
          }
        ).props.children;
      } else {
        placeholder = '';
        if (!isEmpty(this.props.blocks.translations)) {
          placeholder = parse(
            this.props.blocks.translations.strtypebrandmodelname['USA'].content,
            {
              replace: function(domNode) {
                if (domNode.hasOwnProperty('children')) {
                  let text =
                    domNode.children.length > 0 ? domNode.children[0].data : '';
                  return <>{text}</>;
                }

                return '';
              }
            }
          ).props.children;
        }
      }
    }

    const inputProps = {
      placeholder: placeholder,
      value,
      onChange: this.onChange
    };

    return (
      <>
        <Autosuggest
          id="home-search"
          theme={theme}
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={suggestion => suggestion.unique_id}
          renderSuggestion={this.renderSuggestion}
          renderSuggestionsContainer={this.renderSuggestionsContainer}
          inputProps={inputProps}
        />
        {isFetching && (
          <div className="no-results-home-search text-center">Loading ...</div>
        )}
        {suggestions.length === 0 && value && !isFetching && noSuggestions && (
          <div className="no-results-home-search text-center">No Results</div>
        )}
      </>
    );
  }
}

export default HomeSearch;
