import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import axios from 'axios';
import { debounce } from 'throttle-debounce';
import Config from '../../config';

class MultiplyAutoSuggest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      suggestions: []
    };
  }

  componentDidMount() {
    this.setState({ props: this.props }, () => {
      this.onSuggestionsFetchRequested = debounce(
        50,
        this.onSuggestionsFetchRequested
      );
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.dontChange) {
      this.setState({ props: this.props }, () => {
        this.onSuggestionsFetchRequested = debounce(
          50,
          this.onSuggestionsFetchRequested
        );
      });
    }
  }

  onChange = (_, { newValue, method }) => {
    const { id, onChange } = this.props;
    this.setState({
      value:
        typeof newValue !== 'undefined'
          ? method === 'click' || method === 'enter'
            ? ''
            : newValue
          : ''
    });
    onChange(id, newValue);
  };

  onSuggestionsFetchRequested = ({ value }) => {
    const uri_cars = '/suggestions-multiply-fetch-requested';
    const onlyInSegment = this.state.props.segment;
    let excludeCars = this.state.props.excludeCars.map(car => car.id);
    let country = this.state.props.country.location.country;
    const isInputBlank =
      value !== undefined ? (value.trim() === '' ? true : false) : false;
    let noSuggestions = [];
    axios
      .post(Config.LARAVEL_API.URL + uri_cars, {
        search: value,
        onlyInSegment: onlyInSegment,
        country: country,
        excludeCars: excludeCars
      })
      .then(res => {
        const results = res.data.hits.hits.map(h => h._source);
        noSuggestions = !isInputBlank && results.length === 0;

        this.setState({
          suggestions: results,
          noSuggestions: noSuggestions
        });
      });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    this.props.chooseCar({
      id: parseInt(suggestion.id),
      unique_id: suggestion.unique_id,
      segment: suggestion.segment,
      brand: suggestion.brand,
      model: suggestion.model,
      version_name: suggestion.version_name,
      list_price: suggestion.list_price,
      imageUrl: suggestion.imageUrl,
      transaction_price: suggestion.transaction_price
    });
  };

  renderSuggestion = (suggestion, { query }) => {
    const suggestionText = `${suggestion.unique_id}`;
    const matches = AutosuggestHighlightMatch(suggestionText, query);
    const parts = AutosuggestHighlightParse(suggestionText, matches);
    return (
      <div className="result">
        <span className="name">
          {parts.map((part, index) => {
            const className = part.highlight ? 'highlight' : null;
            return (
              <span className={className} key={index}>
                {part.text}
              </span>
            );
          })}
        </span>
      </div>
    );
  };

  render() {
    const { id, placeholder } = this.props;
    const { value, suggestions, noSuggestions } = this.state;
    const inputProps = {
      placeholder,
      value,
      onChange: this.onChange
    };

    const theme = {
      container: 'autosuggest',
      input: 'form-control',
      suggestionsContainer: 'dropdown',
      suggestionsList: `dropdown-menu min-wp-100 ${
        suggestions.length ? 'show' : ''
      }`,
      suggestion: 'dropdown-item',
      suggestionFocused: 'active'
    };

    return (
      <>
        <Autosuggest
          id={id}
          theme={theme}
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          getSuggestionValue={suggestion => suggestion.unique_id}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
        />
        {noSuggestions && <div className="text-center">No Results</div>}
      </>
    );
  }
}

export default MultiplyAutoSuggest;
