import React, { Component } from 'react';

class FilterCheckboxes extends Component {
  state = {
    text: ''
  };

  onChange = (event, filterName) => {
    this.setState(
      {
        text: event.target.value
      },
      () => {
        this.props.onEnter(this.state.text, filterName);
      }
    );
  };

  onSearchClick = (event, filterName) => {
    let input = event.target.closest('input');
    this.setState(
      {
        text: input.value
      },
      () => {
        this.props.onSearchClick(this.state.text, filterName);
      }
    );
  };

  render() {
    const { filterName } = this.props;
    const textName =
      filterName === 'cities'
        ? 'city'
        : filterName.substr(0, filterName.length - 1);
    return (
      <div className="input-group">
        <input
          type="text"
          className="form-control"
          placeholder={'Enter ' + textName + ' name'}
          onKeyUp={event => this.onChange(event, filterName)}
        />
        <div className="input-group-btn">
          {/*<button className="btn btn-default" onClick={(event) => this.onSearchClick(event, filterName)}>
                        <i className="fas fa-search"></i>
                    </button>*/}
        </div>
      </div>
    );
  }
}

export default FilterCheckboxes;
