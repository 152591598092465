import {
  REQUEST_SEGMENT_LIST,
  RECEIVE_SEGMENT_LIST,
  REQUEST_SEGMENT_BEST_SELLERS,
  SET_SEGMENT_BEST_SELLERS,
  REQUEST_TRANSLATION_BLOCKS,
  RECEIVE_TRANSLATION_BLOCKS
} from './types';

let translationBlocks = localStorage.getItem('translations')
  ? JSON.parse(localStorage.getItem('translations'))
  : {};

export function segmentReducer(state = {}, action = {}) {
  switch (action.type) {
    case REQUEST_SEGMENT_LIST:
      return {
        isFetching: true,
        didInvalidate: false,
        items: []
      };
    case RECEIVE_SEGMENT_LIST:
      return {
        isFetching: false,
        didInvalidate: false,
        items: action.segment_list,
        lastUpdated: action.receivedAt
      };
    default:
      return state;
  }
}

export function translationReducer(state = {}, action = {}) {
  switch (action.type) {
    case REQUEST_TRANSLATION_BLOCKS:
      return {
        isFetching: true,
        translations: translationBlocks
      };
    case RECEIVE_TRANSLATION_BLOCKS:
      localStorage.setItem('translations', JSON.stringify(action.blocks));
      return {
        translations: translationBlocks,
        isFetching: false,
        lastUpdated: action.receivedAt
      };
    default:
      return state;
  }
}

export function bestsellerReducer(state = {}, action = {}) {
  switch (action.type) {
    case SET_SEGMENT_BEST_SELLERS:
      return {
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt
      };
    case REQUEST_SEGMENT_BEST_SELLERS:
      return {
        isFetching: true,
        didInvalidate: false,
        items: []
      };
    default:
      return state;
  }
}
