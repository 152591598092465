import {
  USER_REQUEST_INFO,
  USER_RECEIVE_INFO,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAILURE,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILURE,
  USER_LOGOUT
} from './types';
import axios from 'axios';
import Config from '../../config';
import { userService } from './services/service';
import {
  fetchAll,
  requestSegmentBestSeller,
  requestSegmentList,
  requestTranslationBlocks
} from '../../redux/segment/index';
import {
  success as successAlert,
  error as errorAlert
} from 'react-notification-system-redux';
const cookieExpire = 7; // cookie expiration time in days
const country_list = ['usa', 'russia', 'israel'];
const uri_geocode_reverse = '/geocode-reverse';
const uri_get_currency = '/get-currency';

export const userRequestInfo = (data = {}) => {
  return {
    type: USER_REQUEST_INFO
  };
};

export const userReceiveInfo = (data = {}) => {
  return {
    type: USER_RECEIVE_INFO,
    payload: data,
    receivedAt: Date.now()
  };
};

export const login = (email, password) => {
  return dispatch => {
    dispatch(request({ email }));

    return userService
      .login(email, password)
      .then(
        user => {
          dispatch(success(user));
          dispatch(
            successAlert({
              title: '',
              message: 'Logged in!',
              position: 'br',
              autoDismiss: 5,
              action: {}
            })
          );
          return { dontShow: true };
        },
        error => {
          dispatch(failure(error));
          dispatch(
            errorAlert({
              title: '',
              message: 'Error occurred.',
              position: 'br',
              autoDismiss: 5,
              action: {}
            })
          );
          return { dontShow: false };
        }
      )
      .catch(error => {
        dispatch(failure(error));
        dispatch(
          errorAlert({
            title: '',
            message: 'Error occurred.',
            position: 'br',
            autoDismiss: 5,
            action: {}
          })
        );
        return { dontShow: false };
      });
  };

  function request(user) {
    return { type: USER_LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: USER_LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: USER_LOGIN_FAILURE, error };
  }
};

export const register = user => {
  return dispatch => {
    dispatch(request(user));

    userService
      .register(user)
      .then(
        user => {
          dispatch(success(user));
        },
        error => {
          let data = error.response.data;
          if (error.response.status === 500) {
            data = {};
          }
          dispatch(failure(data));
          dispatch(
            errorAlert({
              title: '',
              message: 'Error occurred.',
              position: 'br',
              autoDismiss: 5,
              action: {}
            })
          );
        }
      )
      .catch(error => {
        let data = {};
        if (error.hasOwnProperty('response')) {
          data = error.response.data;
        }
        dispatch(failure(data));
        dispatch(
          errorAlert({
            title: '',
            message: 'Error occurred.',
            position: 'br',
            autoDismiss: 5,
            action: {}
          })
        );
      });
  };

  function request(user) {
    return { type: USER_REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: USER_REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: USER_REGISTER_FAILURE, error };
  }
};

export const update = user => {
  return dispatch => {
    dispatch(request());

    userService
      .update(user)
      .then(
        user => {
          dispatch(success(user));
          dispatch(
            successAlert({
              title: '',
              message: 'Successfully updated!',
              position: 'br',
              autoDismiss: 5,
              action: {}
            })
          );
        },
        error => {
          dispatch(fail());
          dispatch(
            errorAlert({
              title: '',
              message: 'Error occurred during update.',
              position: 'br',
              autoDismiss: 5,
              action: {}
            })
          );
        }
      )
      .catch(error => {
        dispatch(fail());
        dispatch(
          errorAlert({
            title: '',
            message: 'Error occurred during update.',
            position: 'br',
            autoDismiss: 5,
            action: {}
          })
        );
      });
  };

  function request() {
    return { type: USER_UPDATE_REQUEST };
  }
  function success(user) {
    return { type: USER_UPDATE_SUCCESS, user };
  }
  function fail() {
    return { type: USER_UPDATE_FAILURE };
  }
};

export const logout = () => {
  return dispatch => {
    userService
      .logout()
      .then(response => response.data)
      .then(
        data => {
          localStorage.removeItem('user');
          dispatch(request()).then(() => {
            window.location = '/';
          });
        },
        error => {
          localStorage.removeItem('user');
          window.location = '/';
        }
      )
      .catch(error => {
        localStorage.removeItem('user');
        window.location = '/';
      });
  };

  function request() {
    return { type: USER_LOGOUT };
  }
};

export const fetchCurrencyDependingOnLocation = country => {
  return axios.post(Config.LARAVEL_API.URL + uri_get_currency, {
    country: country
  });
};

// fetch User location on back-end
export const fetchUserLocation = position => {
  return axios.post(Config.LARAVEL_API.URL + uri_geocode_reverse, {
    latitude: position.coords.latitude,
    longitude: position.coords.longitude
  });
};

export const fetchUserLocationByIP = () => {
  return axios.get('http://ip-api.com/json');
};

export const setCookie = (cname, cvalue, exdays) => {
  let date = new Date();
  date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = 'expires=' + date.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

export const getCookie = cname => {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

const checkCountry = (country = '') => {
  for (let i = 0; i < country_list.length; i++) {
    if (country.toLowerCase() === country_list[i]) {
      return true;
    }
  }

  return false;
};

export const preFetchUserLocation = (
  defaultCountry = 'USA',
  switched = false,
  showOnce = false
) => {
  return (dispatch, getState) => {
    dispatch(userRequestInfo());
    dispatch(requestSegmentList());
    dispatch(requestTranslationBlocks());
    dispatch(requestSegmentBestSeller());
    //let html = document.documentElement;

    if (navigator.geolocation && !switched) {
      if (getCookie('country')) {
        if (process.env.NODE_ENV === 'development') {
          console.log(4);
          console.log('Country set from cookie.');
        }

        Promise.resolve(
          fetchCurrencyDependingOnLocation(getCookie('country'))
        ).then(result => {
          let country = getCookie('country');
          let lat = getCookie('location')
            ? getCookie('location').split('|')[0]
            : 0;
          let lon = getCookie('location')
            ? getCookie('location').split('|')[1]
            : 0;

          dispatch(
            userReceiveInfo({
              country: country,
              currency: result.data.currency,
              lat: lat,
              lon: lon,
              geolocation: false,
              incorrectCountry: false,
              showOnce: false
            })
          );

          dispatch(fetchAll(country));
          //if (getCookie('country') === 'Israel') {
          //  html.setAttribute('dir', 'rtl');
          //} else {
          //  html.removeAttribute('dir');
          //}
        });

        return;
      }

      let getPosition = function(options) {
        return new Promise(function(resolve, reject) {
          navigator.geolocation.getCurrentPosition(resolve, reject, {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0
          });
        });
      };

      getPosition()
        .then(position => {
          Promise.resolve(fetchUserLocation(position)).then(
            result => {
              let country = defaultCountry;
              let incorrectCountry = true;
              if (
                country_list.indexOf(result.data.country.toLowerCase()) !== -1
              ) {
                country = result.data.country;
                incorrectCountry = false;
              }

              if (process.env.NODE_ENV === 'development') {
                console.log(1);
                console.log('geolocation worked successfully');
                console.log(
                  incorrectCountry
                    ? 'Country resolved'
                    : 'Country not in the list of suported.'
                );
                console.log(result.data.country.toLowerCase());
              }

              dispatch(
                userReceiveInfo({
                  country: country,
                  currency: result.data.currency,
                  lat: position.coords.latitude,
                  lon: position.coords.longitude,
                  geolocation: false,
                  incorrectCountry: incorrectCountry,
                  showOnce: !showOnce ? false : true
                })
              );

              // if( country === 'Israel' ) {
              //   html.setAttribute('dir', 'rtl')
              // } else {
              //   html.removeAttribute('dir')
              // }

              dispatch(fetchAll(country));

              setCookie('country', country, cookieExpire);
              setCookie(
                'location',
                position.coords.latitude + '|' + position.coords.longitude,
                cookieExpire
              );
            },
            error => {
              if (process.env.NODE_ENV === 'development') {
                console.log(2);
                console.log('error in resolving promise.');
              }
              let country = getCookie('country')
                ? getCookie('country')
                : defaultCountry;

              let coords = getCookie('location')
                ? getCookie('location')
                : position
                ? position.coords.latitude + '|' + position.coords.longitude
                : false;

              Promise.resolve(fetchCurrencyDependingOnLocation(country)).then(
                result => {
                  dispatch(
                    userReceiveInfo({
                      country: country,
                      currency: result.data.currency,
                      lat: coords ? coords.split('|')[0] : 0,
                      lon: coords ? coords.split('|')[1] : 0,
                      geolocation: false,
                      incorrectCountry: false,
                      showOnce: !showOnce ? false : true
                    })
                  );

                  //if (country === 'Israel') {
                  //  html.setAttribute('dir', 'rtl');
                  //} else {
                  //  html.removeAttribute('dir');
                  //}

                  dispatch(fetchAll(country));

                  return;
                }
              );

              setCookie('country', country, cookieExpire);
              setCookie(
                'location',
                coords === false ? '0|0' : coords,
                cookieExpire
              );
            }
          );
        })
        .catch(err => {
          if (process.env.NODE_ENV === 'development') {
            console.log(3);
            console.log('error while selecting country via geolocation.');
          }

          Promise.resolve(fetchUserLocationByIP())
            .then(result => {
              let country = getCookie('country')
                ? getCookie('country')
                : defaultCountry;

              let coords = getCookie('location')
                ? getCookie('location')
                : false;

              if (result.data.status === 'success') {
                if (!switched) {
                  country = checkCountry(result.data.country)
                    ? result.data.country[0].toUpperCase() +
                      result.data.country.substring(1)
                    : country;
                }
                coords = result.data.lat + '|' + result.data.lon;
              }

              Promise.resolve(fetchCurrencyDependingOnLocation(country)).then(
                result => {
                  dispatch(
                    userReceiveInfo({
                      country: country,
                      currency: result.data.currency,
                      lat: coords ? coords.split('|')[0] : 0,
                      lon: coords ? coords.split('|')[1] : 0,
                      geolocation: false,
                      incorrectCountry: false,
                      showOnce: !showOnce ? false : true
                    })
                  );

                  // if( country === 'Israel' ) {
                  //   html.setAttribute('dir', 'rtl')
                  // } else {
                  //   html.removeAttribute('dir')
                  // }

                  dispatch(fetchAll(country));

                  return;
                }
              );

              setCookie('country', country, cookieExpire);
              setCookie(
                'location',
                coords === false ? '0|0' : coords,
                cookieExpire
              );
            })
            .catch(err => {
              let country = defaultCountry;
              let coords = false;

              Promise.resolve(fetchCurrencyDependingOnLocation(country)).then(
                result => {
                  dispatch(
                    userReceiveInfo({
                      country: country,
                      currency: result.data.currency,
                      lat: coords ? coords.split('|')[0] : 0,
                      lon: coords ? coords.split('|')[1] : 0,
                      geolocation: false,
                      incorrectCountry: false,
                      showOnce: !showOnce ? false : true
                    })
                  );

                  // if( country === 'Israel' ) {
                  //   html.setAttribute('dir', 'rtl')
                  // } else {
                  //   html.removeAttribute('dir')
                  // }
                  dispatch(fetchAll(country));

                  return;
                }
              );

              setCookie('country', country, cookieExpire);
              setCookie(
                'location',
                coords === false ? '0|0' : coords,
                cookieExpire
              );
            });
        });
    } else {
      if (process.env.NODE_ENV === 'development') {
        console.log(5);
        console.log('country switched and cookie re set.');
      }

      Promise.resolve(fetchUserLocationByIP())
        .then(result => {
          let country = defaultCountry;
          let coords = '0|0';

          if (result.data.status === 'success') {
            if (!switched) {
              country = checkCountry(result.data.country)
                ? result.data.country[0].toUpperCase() +
                  result.data.country.substring(1)
                : country;
            }
            coords = result.data.lat + '|' + result.data.lon;
          }

          setCookie('country', country, cookieExpire);
          setCookie('location', coords, cookieExpire);

          Promise.resolve(
            fetchCurrencyDependingOnLocation(defaultCountry)
          ).then(result => {
            dispatch(
              userReceiveInfo({
                country: country,
                currency: result.data.currency,
                lat: coords ? coords.split('|')[0] : 0,
                lon: coords ? coords.split('|')[1] : 0,
                geolocation: false,
                incorrectCountry: false,
                showOnce: !showOnce ? false : true
              })
            );

            // if( defaultCountry === 'Israel' ) {
            //   html.setAttribute('dir', 'rtl')
            // } else {
            //   html.removeAttribute('dir')
            // }

            dispatch(fetchAll(country));

            return;
          });
        })
        .catch(err => {
          let country = defaultCountry;
          let coords = false;

          Promise.resolve(fetchCurrencyDependingOnLocation(country)).then(
            result => {
              dispatch(
                userReceiveInfo({
                  country: country,
                  currency: result.data.currency,
                  lat: coords ? coords.split('|')[0] : 0,
                  lon: coords ? coords.split('|')[1] : 0,
                  geolocation: false,
                  incorrectCountry: false,
                  showOnce: !showOnce ? false : true
                })
              );

              // if( country === 'Israel' ) {
              //   html.setAttribute('dir', 'rtl')
              // } else {
              //   html.removeAttribute('dir')
              // }

              dispatch(fetchAll(country));

              return;
            }
          );

          setCookie('country', country, cookieExpire);
          setCookie(
            'location',
            coords === false ? '0|0' : coords,
            cookieExpire
          );
        });
    }
  };
};
