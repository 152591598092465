import React, { Component } from 'react';
import Service from '../../api';
import Config from '../../config';

class ResetPassword extends Component {
  state = {
    password: '',
    password_confirmation: '',
    token: '',
    message: '',
    submitted: false,
    isLoading: false
  };

  componentDidMount() {
    if (this.props.match.params.token) {
      this.setState({
        token: this.props.match.params.token
      });
    }
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  updatePassword = e => {
    e.preventDefault();

    let service = new Service();
    this.setState({ isLoading: true, submitted: true });
    const { password_confirmation, password, token } = this.state;

    if (
      password_confirmation &&
      password &&
      token &&
      password === password_confirmation
    ) {
      service
        .put(Config.LARAVEL_API.URL + '/change-password', {
          password: password,
          password_confirmation: password_confirmation,
          password_token: token
        })
        .then(
          response => {
            this.setState({
              isLoading: false,
              showOk: true,
              message: response.data.message
            });
          },
          error => {
            this.setState({
              isLoading: false,
              error_token: error.response.data.hasOwnProperty('password_token')
                ? true
                : false,
              message: error.response.data.message
            });
          }
        )
        .catch(error => {
          this.setState({
            isLoading: false,
            message: ''
          });
        });
    } else {
      this.setState({ isLoading: false });
    }
  };

  showErrors = list => {
    let collection = [];
    for (let i = 0; i < list.length; i++) {
      collection.push(<p>{list[i]}</p>);
    }

    return collection;
  };

  render() {
    const {
      password_confirmation,
      password,
      token,
      isLoading,
      submitted,
      message
    } = this.state;

    if (isLoading) {
      return (
        <div className="row">
          <div className="container">Verifying data...</div>
        </div>
      );
    }

    return (
      <div className="row">
        <div className="container">
          {this.state.hasOwnProperty('showOk') ? (
            <p>{message}</p>
          ) : (
            <form>
              <div
                className={
                  'form-group' + (submitted && !password ? ' has-error' : '')
                }
              >
                <label for="password">Enter password:</label>
                <input
                  id="password"
                  name="password"
                  onChange={this.handleChange}
                  value={password}
                  type="password"
                />
                {submitted && !password && (
                  <div className="help-block text-danger">
                    Password is required
                  </div>
                )}
              </div>

              <div
                className={
                  'form-group' +
                  (submitted && !password_confirmation ? ' has-error' : '')
                }
              >
                <label for="password_confirmation">Confirm password:</label>
                <input
                  id="password_confirmation"
                  name="password_confirmation"
                  onChange={this.handleChange}
                  value={password_confirmation}
                  type="password"
                />

                {submitted && !password_confirmation && (
                  <div className="help-block text-danger">
                    Please confirm the password
                  </div>
                )}

                {submitted &&
                  password_confirmation !== password &&
                  password_confirmation &&
                  password && (
                    <div className="help-block text-danger">
                      The passwords you entered do not match. Please re-enter
                      your passwords.
                    </div>
                  )}
              </div>

              {Object.keys(message).length !== 0 && (
                <>
                  {message.hasOwnProperty('password') && (
                    <div className="help-block text-danger">
                      {this.showErrors(message.password)}
                    </div>
                  )}
                </>
              )}

              {this.state.hasOwnProperty('error_token') && (
                <>
                  {this.state.error_token && (
                    <div className="help-block text-danger">{message}</div>
                  )}
                </>
              )}
              <input type="hidden" name="password-token" value={token} />
              <button onClick={this.updatePassword} className="btn btn-primary">
                Update password
              </button>
            </form>
          )}
        </div>
      </div>
    );
  }
}

export default ResetPassword;
