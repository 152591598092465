import React, { Component } from 'react';

class Delay extends Component {
  startTimer = () => {
    setTimeout(
      function() {
        this.props.onStopTime();
      }.bind(this),
      500
    );
  };

  render() {
    if (!this.props.hide) {
      return <></>;
    }

    return (
      <>
        {this.startTimer()}
        <div className="spinner-container">
          <div className="center-Y cm-spinner" />
        </div>
      </>
    );
  }
}

export default Delay;
