/*
 * action types
 */
export const REQUEST_SEGMENT_LIST = 'REQUEST_SEGMENT_LIST';
export const RECEIVE_SEGMENT_LIST = 'FETCH_SEGMENT_LIST';

export const REQUEST_SEGMENT_BEST_SELLERS = 'REQUEST_SEGMENT_BEST_SELLERS';
export const SET_SEGMENT_BEST_SELLERS = 'SET_SEGMENT_BEST_SELLERS';

export const REQUEST_TRANSLATION_BLOCKS = 'REQUEST_TRANSLATION_BLOCKS';
export const RECEIVE_TRANSLATION_BLOCKS = 'FETCH_TRANSLATION_BLOCKS';
