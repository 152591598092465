import React, { Component } from 'react';

class TermsOfService extends Component {
  componentDidMount() {
    document.body.className = '';
    document.body.classList.add('tos');
  }

  render() {
    return (
      <React.Fragment>
        <div className="pageTitle d-flex justify-content-center">
          <h1>
            <span className="cbddRed">Terms</span> of{' '}
            <span className="cbddRed">Service</span>
          </h1>
        </div>
        <div className="d-flex justify-content-center">
          <section id="tos">
            <ol>
              <li className="tos__1">
                Introduction:
                <ol>
                  <li>
                    Car Business Data Dynamics, Ltd ("CBDD") has certain data of
                    cars specifications, cars’ prices, car market surveys, end
                    retail users’ leads data identified on{' '}
                    <strong>Exhibit A</strong> (the “CBDD Data”).
                  </li>
                  <li>
                    CBDD and User wish to enter into this Agreement whereby CBDD
                    will provide a platform (access to the web-site) which
                    allows User to use CBDD Data at www.ChooseYourCar.com. CBDD
                    will provide User with all the necessary data as described
                    in <strong>Exhibit A</strong>.
                  </li>
                  <li>
                    Nothing in this Agreement shall limit or restrict CBDD from
                    entering into or continuing any agreement or arrangement
                    with any other party, whether or not similar to this
                    Agreement in nature or scope.
                  </li>
                </ol>
              </li>
              <li className="tos__2">
                License:
                <ol>
                  <li>
                    <u>License Rights Granted</u>: Subject to the terms of this
                    Agreement, CBDD, hereby grants to User, and User accepts,
                    during the term of the Agreement and subject to all the
                    terms set forth herein, a non-exclusive, non-transferable,
                    royalty-free license to use the CBDD Data. CBDD does not
                    grant any right or authority to distribute or otherwise
                    transfer any copy of CBDD Data to any third party except as
                    provided in this Agreement.
                  </li>
                  <li>
                    <u>CBDD’s Reserved Rights:</u> CBDD reserves all rights not
                    expressly granted to User under this Agreement. CBDD further
                    reserves the right, in its sole discretion and without
                    liability to User, to modify any Software Programs and or
                    database.
                  </li>
                  <li>
                    <strong>Definitions:</strong>
                    <ol>
                      <li>
                        <strong>CBDD:</strong> Car Business Data Dynamics LTD.
                        The owner of www.chooseyourcar.com web site.
                      </li>
                      <li>
                        <strong>End Retail User:</strong> a private individual
                        or a legal entity using www.chooseyourcar.com
                        (subsequent to approving CBDD’s Terms of Service) for
                        the purpose of searching information regarding
                        acquisition of a new passenger car or LCV (Cars’ List
                        Price (MSRP), Transaction price, Specifications, Market
                        Value Grade (Comparably Equipped Price Grade), data on
                        car’s finance, leasing services, etc.) and agrees to
                        register for a test drive.
                      </li>
                      <li>
                        <strong>User Publishing Data:</strong> a legal entity
                        (such as: official new passenger cars & LCV dealer,
                        distributor, cars & LCVs national sales company, bank or
                        finance company, leasing company or rent-a-car) using
                        www.chooseyourcar.com subsequent to approving CBDD’s
                        Terms of Service, publish cars’ data (specifications,
                        prices, cars’ pictures), contact information of it’s
                        dealer’s showrooms, register to receive end retail users
                        leads data from www.chooseyourcar.com and agreed to pay
                        for www.chooseyourcar.com services (Cost Per Action:
                        Pay-per-lead & Cost Per Action: Pay-per-Sale (end retail
                        user contract to purchase car and/or services for it,
                        such as car loan, leasing, insurance, trade-in, etc.)
                      </li>
                      <li>
                        <strong>
                          Cost Per Action (CPA): pay-per-lead Service:
                        </strong>{' '}
                        CBDD service, data which include providing User
                        Publishing Data a lead (end retail user contact
                        information: full name, e-mail address, telephone
                        number, car name and model for which he/she wanted to
                        subscribe for test-drive, competitors’ cars which he/she
                        surveyed).
                      </li>
                      <li>
                        <strong>Cost Per Action (CPA): pay-per-sale:</strong>{' '}
                        CBDD provide service, data (lead) to User Publishing
                        Data which result in signing a Sales Agreement with end
                        retail user whose lead has been provided by CBDD (by
                        sending data to e-mail address registered by User
                        Publishing Data on www.chooseyourcar.com)
                      </li>
                      <li>
                        <strong>B2C</strong> Business to Customer
                        (services/products sold to retail users)
                      </li>
                      <li>
                        <strong>B2B</strong> Business to Business
                        (services/products sold from a legal entity to legal
                        entity)
                      </li>
                      <li>
                        <strong>Lead</strong> contact information on the End
                        Retail User who is registered on www.chooseyourcar.com,
                        and looking for information on a new passenger car / LCV
                        (such as price, specs, CE price grade, data on finance,
                        leasing services, information on dealer, etc) and
                        subscribed for a test-drive/service to a car
                        dealer/finance/leasing company (active User Publishing
                        Information).
                      </li>
                      <li>
                        <strong>
                          Car’s Market Value Grade = Comparably Equipped
                          (adjusted) Price Grade{' '}
                        </strong>{' '}
                        of car which takes into account the difference in
                        average market value (equipment and engine output) vs.
                        benchmark.
                      </li>
                      <li>
                        <strong>List Price</strong> = Manufacturer Suggested
                        Retail Price (MSRP)
                      </li>
                      <li>
                        <strong>Transaction Price</strong> = Retail price
                        including discounts and other official distributor’s &
                        dealers’ customer country’s Customer incentives.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li className="tos__3">
                User Responsibilities:
                <ol>
                  <li>
                    <strong>End Retail User</strong> who checked the “I Agree”
                    box must comply with CBDD’s Terms of Service & Privacy
                    Policy.
                  </li>
                  <li>
                    <strong>User publishing data must :</strong>
                    <ol>
                      <li>
                        publish correct data on cars’, prices, specs, pictures,
                        dealer’s contact information.
                      </li>
                      <li>
                        Comply with CBDD Terms of Service and Privacy Policy.
                      </li>
                      <li>
                        Pay for CBDD services: Cost Per Action: Pay-per-lead &
                        Cost Per Action: Pay-per-Sale (end retail user contract
                        to purchase car)) within 5 working days of the next
                        month after receiving customers leads data sent by
                        www.chooseyourcar.com to it’s sales/marketing dept.
                        e-mail address registered by User Publishing Data on
                        www.chooseyourcar.com.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>User Publishing Data Personnel:</strong> User
                    undertakes to disclose CBDD data only to it’s employees,
                    counterparts or subcontractors who are directly involved in
                    using car's specs, prices and market data subsequent to
                    signing a Non-Disclosure Agreement or equivalent with the
                    user.
                  </li>
                  <li>
                    <strong>User publishing data</strong> undertakes to protect
                    CBDD’s data from third parties.
                  </li>
                  <li>
                    <strong>User publishing data</strong> undertakes to protect
                    CBDD’s Database data, software and passwords from illegal
                    copying, dissemination.
                  </li>
                  <li>
                    <strong>Compliance with Applicable Laws.</strong> User shall
                    observe all applicable laws and regulations governing the
                    licensing of the Software Programs, Trademarks and data
                    base, as applicable.
                  </li>
                </ol>
              </li>
              <li>
                <ol>
                  Compensation:
                  <li>
                    <u>Fees:</u> In consideration of the rights and licenses
                    granted pursuant to section 2, User agrees to pay CBDD fees
                    as set out in <strong>Exhibit B</strong> and in accordance
                    with the terms of this Agreement.
                  </li>
                  <li>
                    <u>Technical Support:</u> As part of this Agreement CBDD
                    will provide all passwords, login and technical support as
                    defined in <strong>Exhibit A</strong>.
                  </li>
                  <li>
                    <u>Taxes and Duties:</u> All license fees set forth on
                    Exhibit B are inclusive of all taxes, duties, including
                    customs duties, and similar charges now in force or enacted
                    in the future.
                  </li>
                  <li>
                    <u>Payment Terms:</u> Payment shall be made in accordance
                    with <strong>Exhibit B</strong>.
                  </li>
                  <li>
                    <u>Currency Terms:</u> All payments which are required to be
                    made per the terms of this Agreement shall be in US dollars.
                  </li>
                </ol>
              </li>
              <li>
                Limited Warranty; Disclaimer of Warranty; Representations
                <ol>
                  <li>
                    <u>Limited Warranty.</u> CBDD warrants that on the date of
                    delivery of CBDD Data to User, such data shall be free from
                    defects in materials and workmanship and shall perform
                    properly. In the event of a defect in the CBDD data, CBDD
                    shall repair and/or replace such data without undue delay
                    and without any cost or expense to User.
                  </li>
                  <li>
                    <u>Disclaimer of Warranties.</u> The limited warranty set
                    forth herein is exclusive and in lieu of, and User hereby
                    waives, all other representations, warranties, guarantees
                    and conditions, express and implied., including without
                    limitation any implied warranties or conditions
                    merchantability or fitness of the Software programs for a
                    particular purpose, and any warranties or conditions arising
                    out of course of dealing or course of performance.
                  </li>
                  <li>
                    <u>Service Provider Warranty.</u> User shall not be
                    authorized to make any warranty, guarantee representation,
                    whether written or oral, on behalf of CBDD.
                  </li>
                  <li>
                    <u>Representations of CBDD.</u> CBDD represents and warrants
                    to User that it is the legal and beneficial owner of CBDD
                    Data with good title, free and clear of all liens,
                    limitations and restrictions on fulfilling its obligations
                    hereunder.
                  </li>
                </ol>
              </li>
              <li>
                Limitation of Liability:
                <ol>
                  <li>
                    <u>Limitations and Exclusions.</u> Notwithstanding any law
                    or provision herein or to the contrary, in no event shall
                    CBDD be liable for any lost profits or for any indirect,
                    incidental, consequential, punitive or other special damages
                    suffered by the other party, its customers, suppliers,
                    contractors, subcontractors, agents, employees, directors,
                    officers, shareholders or others arising out of or related
                    to this Agreement for any cause of action including tort,
                    contract, negligence, strict liability, breach of warranty
                    and fundamental breach.
                  </li>
                  <li>
                    User agrees that in the process of collecting cars specs.
                    prices and sales figures data in the CBDD database human or
                    computer errors may occur. CBDD web-site may contain
                    outdated data and interruptions may happen. User agrees that
                    the data is provided "as is" and will not make claims on
                    it’s accuracy, timeliness and completeness.
                  </li>
                </ol>
              </li>
              <li>
                Intellectual property rights.
                <ol>
                  <li>
                    User acknowledges that any and all of the trade names,
                    service marks, trade marks, copyrights, patents and other
                    intellectual property rights used or embodied in or in
                    connection with the Software Products or any data base shall
                    be and remain the exclusive property of CBDD.
                  </li>
                </ol>
              </li>
              <li>
                Term and Termination:
                <ol>
                  <li>
                    <u>Term.</u> This Agreement shall remain in effect for a
                    period indicated in Exhibit A from the date of accepting
                    this agreement, unless sooner terminated pursuant to Section
                    10 of this Agreement. Thereafter, the terms of this
                    Agreement shall be automatically renewed for successive
                    terms indicated in Exhibit A, unless written notice of
                    intent not to renew is given by one party to the other party
                    at least sixty, (60) days prior to the expiration of the
                    then current term.
                  </li>
                  <li>
                    <u>Effect of Termination or Expiration.</u> Upon termination
                    or expiration of this Agreement:
                    <ol>
                      <li>
                        CBDD shall promptly cease offering said services using
                        CBDD Data. All Software Programs shall be promptly
                        uninstalled in all Service Providers computers and any
                        copies of the Software Programs then in Service
                        Provider’s possession shall be returned to CBDD.
                      </li>
                      <li>
                        User shall cease using any CBDD Data, trademark, trade
                        name, logo or designation.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <ol>
                  <strong>Notices:</strong>
                  <li>
                    Any notice, communication, or amendment required or
                    permitted under the terms of this Agreement or required by
                    law shall be in writing and shall be delivered in person, or
                    sent by certified mail properly posted and fully prepaid in
                    an envelope properly addressed, or shall be sent by fax
                    (confirmed receipt) to the respective parties. However, both
                    parties may waive this provision and communicate
                    electronically if mutually agreed to by both parties.
                  </li>
                  <li>
                    CBDD may review, reject, delete User’s Publishing Data (on
                    cars’, specs, services, etc.) at it’s own discretion if the
                    data provided by User violates CBDD’s Terms of Service and
                    Privacy Policy.
                  </li>
                </ol>
              </li>
              <li>
                <strong>Miscellaneous:</strong>
                <ol>
                  <li>
                    In the event any provision of this Agreement shall be
                    determined to be invalid, illegal or unenforceable in any
                    respect, such provision shall be considered separate and
                    severable from the remaining provisions of this Agreement,
                    and the validity, legality and enforceability of all of the
                    remaining provisions of this Agreement shall not be affected
                    or impaired by such provision in any way.
                  </li>
                  <li>
                    Each party represents and warrants to the other that: (a) it
                    is duly organized and in good standing in the state or
                    country of its incorporation; (b) it has the power and
                    authority to carry on its business as presently conducted;
                    (c) the execution and performance of this Agreement has been
                    authorized by all necessary corporate action; and (d) the
                    performance of this Agreement will not violate any other
                    agreement by which such party is bound.
                  </li>
                  <li>
                    The failure by any party to exercise any rights or remedies
                    provided for in this Agreement or at law shall not be deemed
                    a waiver of any such rights or remedies.
                  </li>
                  <li>
                    This Agreement and the rights and obligations under this
                    Agreement shall not be assignable by either party without
                    the prior written consent of the other party. In any event,
                    no assignment shall be effective unless and until the
                    assignee agrees, in writing, to be bound by the terms of
                    this Agreement.
                  </li>
                  <li>
                    Neither party shall be liable for, and is hereby excused
                    from, any failure to perform or for delay in the performance
                    of its obligations under this Agreement which are due to
                    causes beyond such party's control, including without
                    limitation, interruptions of power or telecommunications
                    services, failure of suppliers or subcontractors, acts of
                    nature, acts of God, governmental actions, fire, flood,
                    natural disaster, or labor disputes.
                  </li>
                </ol>
              </li>
              <li>
                <span style={{ fontWeight: 'normal' }}>
                  Any legal action or proceeding with respect to the Agreement
                  and any action for enforcement of any judgment in respect
                  thereof shall be brought in a court sitting in the State of
                  Israel, by execution and delivery of this Agreement, each
                  party hereby accepts for itself the exclusive jurisdiction of
                  the aforesaid courts and appellate courts from any breach
                  thereof. Each party irrevocably consents to the service of
                  process out of any of the aforementioned courts in any such
                  action or proceeding by the mailing of copies thereof by
                  registered or certified mail, postage prepaid, to such party
                  addressed as called for hereunder.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 'normal' }}>
                  If any action or proceeding is brought for the enforcement of
                  this Agreement, or because of an alleged or actual dispute,
                  breach, default, or misrepresentation in connection with any
                  of the provisions of this Agreement, the successful or
                  prevailing party shall be entitled to recover reasonable
                  attorneys' fees and other costs incurred in such action or
                  proceeding in addition to any other relief to which it may be
                  entitled.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 'normal' }}>
                  All of the warranties, representations, and indemnification
                  obligations of each party under this Agreement shall survive
                  the expiration, termination, or non-renewal of this Agreement.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 'normal' }}>
                  The parties acknowledge that they have read this Agreement
                  including the terms set forth below, understand it, and agree
                  to be bound by its terms and conditions. Further, the parties
                  agree that this Agreement (including the Attachments) is the
                  complete and exclusive statement of the agreement between the
                  parties, which supersedes all proposals, concurrent or prior
                  agreements, oral or written and all other communications
                  between the parties relating to the subject matter of this
                  agreement.
                </span>
              </li>
            </ol>
            <br />
            <br />
            <br />
          </section>
        </div>
        <div className="d-flex justify-content-center">
          <section id="tos">
            <div className="pageTitle d-flex justify-content-center">
              <h2>
                <span className="cbddRed">EXHIBIT A</span>
              </h2>
            </div>
            <div className="pageTitle d-flex justify-content-center">
              <h3>CBDD SERVICES (data) & TRADEMARKS</h3>
            </div>
            {/* <ol>
                  <li className="">
                    <strong>The schedule:</strong>
                  </li>
                </ol>
                Supply period:
                <ul>
                  <li>1 year for User Publishing Data </li>
                  <li>X months for End Retail User (where X – number of months chosen by user)</li>
                </ul> */}

            <table className="table" style={{ borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Service ID-code</th>
                  <th>Service Name</th>
                  <th>Client Type</th>
                  <th>MSRP without VAT/Sales Tax</th>
                  <th>VAT/Sales Tax</th>
                  <th>MSRP with VAT/Sales Tax</th>
                </tr>
              </thead>
              <tbody>
                {/* <tr>
                  <td colspan="7">USA</td>
                </tr>
                <tr>
                  <td scope="row">1</td>
                  <td>B2BE-0840</td>
                  <td>
                    USA B2B Payment per lead, Economy, B2BE-0840;
                    www.chooseyourcar.com
                  </td>
                  <td>B2B</td>
                  <td>$86</td>
                  <td>11%</td>
                  <td>$95</td>
                </tr>
                <tr>
                  <td scope="row">2</td>
                  <td>B2BL-0840</td>
                  <td>
                    USA B2B Payment per lead, Premium, B2BL-084;
                    www.chooseyourcar.com
                  </td>
                  <td>B2B</td>
                  <td>$102</td>
                  <td>11%</td>
                  <td>$113</td>
                </tr>
                <tr>
                  <td scope="row">3</td>
                  <td>B2CE-0840</td>
                  <td>
                    USA B2C Quarry; Economy; B2CE-0840; www.chooseyourcar.com
                  </td>
                  <td>B2B</td>
                  <td>$9</td>
                  <td>11%</td>
                  <td>$0</td>
                </tr>
                <tr>
                  <td scope="row">4</td>
                  <td>B2CL-0840</td>
                  <td>
                    USA B2C Quarry; Premium; B2CL-0840; www.chooseyourcar.com
                  </td>
                  <td>B2B</td>
                  <td>$16</td>
                  <td>11%</td>
                  <td>$0</td>
                </tr>
                <tr>
                  <td scope="row">5</td>
                  <td>B2BP-0840</td>
                  <td>
                    USA B2B Payment per car retail contract, Economy, B2BP-0840;
                    www.chooseyourcar.com
                  </td>
                  <td>B2B</td>
                  <td>$714</td>
                  <td>11%</td>
                  <td>$793</td>
                </tr>
                <tr>
                  <td scope="row">6</td>
                  <td>B2BP-0840</td>
                  <td>
                    USA B2B Payment per car retail contract, Premium, B2BP-0840;
                    www.chooseyourcar.com
                  </td>
                  <td>B2B</td>
                  <td>$2,298</td>
                  <td>11%</td>
                  <td>$2,551</td>
                </tr>
                <tr>
                  <td colspan="7">Israel</td>
                </tr>
                <tr>
                  <td scope="row">7</td>
                  <td>B2BE-0376</td>
                  <td>
                    ISL B2B Payment per lead, Economy, B2BE-0376;
                    www.chooseyourcar.com
                  </td>
                  <td>B2B</td>
                  <td>$86</td>
                  <td>17%</td>
                  <td>$101</td>
                </tr>
                <tr>
                  <td scope="row">8</td>
                  <td>B2BL-0376</td>
                  <td>
                    ISL B2B Payment per lead, Premium; B2BL-0376,
                    www.chooseyourcar.com
                  </td>
                  <td>B2B</td>
                  <td>$102</td>
                  <td>17%</td>
                  <td>$119</td>
                </tr>
                <tr>
                  <td scope="row">9</td>
                  <td>B2CE-0376</td>
                  <td>
                    ISL B2C Quarry, Economy; B2CE-0376, www.chooseyourcar.com
                  </td>
                  <td>B2B</td>
                  <td>$9</td>
                  <td>17%</td>
                  <td>$0</td>
                </tr>
                <tr>
                  <td scope="row">10</td>
                  <td>B2CL-0376</td>
                  <td>
                    ISL B2C Quarry, Premium; B2CL-0376, www.chooseyourcar.com
                  </td>
                  <td>B2B</td>
                  <td>$16</td>
                  <td>17%</td>
                  <td>$0</td>
                </tr>
                <tr>
                  <td scope="row">11</td>
                  <td>B2BP-0376</td>
                  <td>
                    ISL B2B Payment per car retail contract, Economy, B2BP-0840;
                    www.chooseyourcar.com
                  </td>
                  <td>B2B</td>
                  <td>$474</td>
                  <td>17%</td>
                  <td>$555</td>
                </tr>
                <tr>
                  <td scope="row">12</td>
                  <td>B2BP-0376</td>
                  <td>
                    USA B2B Payment per car retail contract, Premium, B2BP-0840;
                    www.chooseyourcar.com
                  </td>
                  <td>B2B</td>
                  <td>$458</td>
                  <td>17%</td>
                  <td>$536</td>
                </tr> */}
                {/* <tr>
                  <td colspan="7">Russia</td>
                </tr>
                <tr>
                  <td scope="row">13</td>
                  <td>B2BE-0643</td>
                  <td>
                    RUS B2B Cost per Action (lead/contract), Premium; B2BL-0376,
                    www.chooseyourcar.com
                  </td>
                  <td>B2B</td>
                  <td>$86</td>
                  <td>20%</td>
                  <td>$103</td>
                </tr>
                <tr>
                  <td scope="row">14</td>
                  <td>B2BL-0643</td>
                  <td>
                    RUS B2B Cost per Action (lead/contract), Premium; B2BL-0376,
                    www.chooseyourcar.com
                  </td>
                  <td>B2B</td>
                  <td>$102</td>
                  <td>20%</td>
                  <td>$122</td>
                </tr>
                <tr>
                  <td scope="row">15</td>
                  <td>B2CE-0643</td>
                  <td>
                    RUS B2C Cost per quarry, Economy; B2CE-0376,
                    www.chooseyourcar.com
                  </td>
                  <td>B2B</td>
                  <td>$9</td>
                  <td>20%</td>
                  <td>$0</td>
                </tr>
                <tr>
                  <td scope="row">16</td>
                  <td>B2CL-0643</td>
                  <td>
                    RUS B2C Cost per quarry, Premium; B2CL-0376,
                    www.chooseyourcar.com
                  </td>
                  <td>B2B</td>
                  <td>$16</td>
                  <td>20%</td>
                  <td>$0</td>
                </tr>
                <tr>
                  <td scope="row">17</td>
                  <td>B2BP-0643</td>
                  <td>
                    RUS B2B Payment per car retail contract, Economy, B2BP-0840;
                    www.chooseyourcar.com,
                  </td>
                  <td>B2B</td>
                  <td>$314</td>
                  <td>20%</td>
                  <td>$377</td>
                </tr>
                <tr>
                  <td scope="row">18</td>
                  <td>B2BP-0643</td>
                  <td>
                    RUS B2B Payment per car retail contract, Premium, B2BP-0840;
                    www.chooseyourcar.com,
                  </td>
                  <td>B2B</td>
                  <td>$298</td>
                  <td>20%</td>
                  <td>$358</td>
                </tr>
                <tr>
                  <td scope="row">19</td>
                  <td>ADRU-0643</td>
                  <td>RUS Advertizing income; ADUS-0840</td>
                  <td>B2B</td>
                  <td>$0</td>
                  <td>20%</td>
                  <td>$0</td>
                </tr> */}
                <tr>
                  <td colspan="7">Russia</td>
                </tr>
                <tr>
                  <td scope="row">1</td>
                  <td>B2CB-0643</td>
                  <td>
                    RUS B2C Basic License for 7 days, B2CE-0643,
                    www.chooseyourcar.com
                  </td>
                  <td>B2C</td>
                  <td>$9</td>
                  <td>20%</td>
                  <td>$0</td>
                </tr>
                <tr>
                  <td scope="row">2</td>
                  <td>B2CC-0643</td>
                  <td>
                    RUS B2C Comfort License for 14 days, Premium; B2CL-0643,
                    www.chooseyourcar.com
                  </td>
                  <td>B2C</td>
                  <td>$16</td>
                  <td>20%</td>
                  <td>$0</td>
                </tr>
                <tr>
                  <td scope="row">3</td>
                  <td>B2CP-0643</td>
                  <td>
                    RUS B2C Premium License for 30 days, Premium; B2CL-0643,
                    www.chooseyourcar.com
                  </td>
                  <td>B2C</td>
                  <td>$25</td>
                  <td>20%</td>
                  <td>$0</td>
                </tr>
              </tbody>
            </table>
          </section>
        </div>
        <br />
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <section id="tos">
            <div className="pageTitle d-flex justify-content-center">
              <h2>
                <span className="cbddRed">EXHIBIT B</span>
              </h2>
            </div>
            <div className="pageTitle d-flex justify-content-center">
              <h3>FEES</h3>
            </div>
            <p>
              As defined in Section 2.0 of the Agreement above, CBDD and User
              hereby agree to enter into a commercial relationship whereby CBDD
              will provide CBDD Data to User. As part of this Agreement, both
              parties hereby agree as follows:
            </p>
            <ol>
              <li>
                <span style={{ fontWeight: 'normal' }}>
                  User Publishing Data agrees to pay CBDD according to Schedule
                  A per Cost Per Action: Pay-Per-Lead & Cost Per Action:
                  Pay-Per-Sale (retail car sales agreement by the end retail
                  customer whose leads has been sent by www.chooseyoucar.com to
                  User Publishing Data e-mail address) during the term of this
                  Agreement where Active User is defined as anyone who uses the
                  service during the pay period. During the term of this
                  Agreement, CBDD agrees to provide to User copies of the
                  Software Programs in Appendix A for use in Users’s platform.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 'normal' }}>
                  Active End Retail User agrees to pay CBDD according to
                  Schedule A per Cost Per Action: Pay-Per-License for the
                  license period ordered (marketing survey data on car’s price,
                  specs, comparably equipped price grade. Finance & leasing
                  services, etc.) during the term of this Agreement where Active
                  User is defined as anyone who uses the service during the pay
                  period. During the term of this Agreement, CBDD agrees to
                  provide to User data by it’s the Software Programs in Appendix
                  A for use by Users.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 'normal' }}>
                  Active Users is defined as anyone who uses the service during
                  the pay period where Pay Period is defined as the period of
                  time from the first day of the month to the last day of the
                  month. However, each individual user may be extended one (1)
                  free trial period at the option of the Service Provider.{' '}
                </span>
              </li>
            </ol>
            <p>
              User will pay CBDD on a fixed price basis (Fixed Price) It is
              agreed that the Fixed Price will be in USD + VAT and the
              submission date should be according with the Schedule. As full
              compensation of the services provided by Car Business Data
              Dynamics, Ltd. to a Client the Client will pay: 100% advance
              payment.
            </p>
          </section>
        </div>
      </React.Fragment>
    );
  }
}

export default TermsOfService;
