import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatchLogin, dispatchRegister } from '../../redux/modals/index';
import { logout } from '../../redux/users/index';
import SubmitPayment from '../modals/SubmitPayment';
import parse from 'html-react-parser';
import { isEmpty } from './../../functions';

class UserInfo extends Component {
  state = {
    show: false
  };

  componentDidMount() {
    if (window.location.pathname === '/payment-verified') {
      this.setState({ show: false });
    }
  }

  handlePaymentModal = () => {
    this.setState({ show: !this.state.show });
  };

  render() {
    const { user, loggedIn, isFetching } = this.props.user_info;
    const { blocks, user_data } = this.props;
    const { show } = this.state;
    return (
      <div id="stickyInfo" className="text-center">
        {loggedIn && (
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-3">
                  {isFetching ? 'Updating ...' : 'Hello ' + user.name}
                </div>
                <div className="col-md-9 text-center">
                  {isFetching
                    ? 'Updating ...'
                    : user.payment_verified
                    ? ''
                    : 'Your subscription is not activated. Please go to Personal page and click "Proceed with payment" button.'}
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <button
                onClick={() => this.props.handleLogOut()}
                type="button"
                className="btn btn-light float-right countrySelect dropdown-header"
              >
                <i className="fas fa-sign-out-alt" /> Log-out
              </button>
              {!isFetching
                ? user.payment_verified === 0 && (
                    <>
                      <button
                        onClick={() => this.handlePaymentModal()}
                        className="btn btn-light float-right countrySelect dropdown-header"
                      >
                        Proceed with payment
                      </button>
                      <SubmitPayment
                        show={show}
                        handleParent={this.handlePaymentModal}
                      />
                    </>
                  )
                : 'Updating ...'}
            </div>
          </div>
        )}
        {!loggedIn && (
          <div className="col-auto align-self-end">
            <button
              onClick={() => this.props.handleLoginModal()}
              type="button"
              className="btn btn-light float-right countrySelect dropdown-header"
            >
              <i className="fas fa-sign-in-alt" />{' '}
              {!isEmpty(blocks.translations) && (
                <>
                  {blocks.translations.hasOwnProperty('strlogin') && (
                    <>
                      {blocks.translations.strlogin[user_data.location.country]
                        .content !== undefined &&
                      blocks.translations.strlogin[user_data.location.country]
                        .content
                        ? parse(
                            blocks.translations.strlogin[
                              user_data.location.country
                            ].content,
                            {
                              replace: function(domNode) {
                                if (domNode.hasOwnProperty('children')) {
                                  let text =
                                    domNode.children.length > 0
                                      ? domNode.children[0].data
                                      : '';
                                  return <>{text}</>;
                                }

                                return '';
                              }
                            }
                          )
                        : parse(blocks.translations.strlogin['USA'].content, {
                            replace: function(domNode) {
                              if (domNode.hasOwnProperty('children')) {
                                let text =
                                  domNode.children.length > 0
                                    ? domNode.children[0].data
                                    : '';
                                return <>{text}</>;
                              }

                              return '';
                            }
                          })}
                    </>
                  )}
                </>
              )}
            </button>
            <button
              onClick={() => this.props.handleRegisterModal()}
              type="button"
              className="btn btn-light float-right countrySelect dropdown-header"
            >
              <i className="fas fa-user-plus" />{' '}
              {!isEmpty(blocks.translations) && (
                <>
                  {blocks.translations.hasOwnProperty('strregister') && (
                    <>
                      {blocks.translations.strregister[
                        user_data.location.country
                      ].content !== undefined &&
                      blocks.translations.strregister[
                        user_data.location.country
                      ].content
                        ? parse(
                            blocks.translations.strregister[
                              user_data.location.country
                            ].content,
                            {
                              replace: function(domNode) {
                                if (domNode.hasOwnProperty('children')) {
                                  let text =
                                    domNode.children.length > 0
                                      ? domNode.children[0].data
                                      : '';
                                  return <>{text}</>;
                                }

                                return '';
                              }
                            }
                          )
                        : parse(
                            blocks.translations.strregister['USA'].content,
                            {
                              replace: function(domNode) {
                                if (domNode.hasOwnProperty('children')) {
                                  let text =
                                    domNode.children.length > 0
                                      ? domNode.children[0].data
                                      : '';
                                  return <>{text}</>;
                                }

                                return '';
                              }
                            }
                          )}
                    </>
                  )}
                </>
              )}
            </button>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user_info: state.user_info,
    blocks: state.translations,
    user_data: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleLogOut: () => {
      dispatch(logout());
    },
    handleLoginModal: () => {
      dispatch(dispatchLogin('open'));
    },
    handleRegisterModal: () => {
      dispatch(dispatchRegister('open'));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
