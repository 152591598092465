export default {
  // pricing plans
  pricing_plans: {
    basic: {
      USA: 15,
      Russia: 9,
      Israel: 10
    },
    comfort: {
      USA: 29,
      Russia: 19,
      Israel: 19
    },
    premium: {
      USA: 39,
      Russia: 25,
      Israel: 29
    }
  }
};
