import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import ChooseCountry from './modals/ChooseCountry';
import CookiePopUp from './modals/CookiePopUp';
import { Web } from '../routes';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Login from './modals/Login';
import Register from './modals/Register';
import UserInfo from './blocks/UserInfo';
import Delay from './pages/Delay';
import Notifications from 'react-notification-system-redux';
import { isEmpty } from './../functions';

class App extends Component {
  state = {
    delay: true
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  setDelay = () => {
    if (this.state.delay === false) {
      this.setState({ delay: true });
    }
  };
  removeDelay = () => {
    this.setState({ delay: false });
  };

  render() {
    const { notifications } = this.props;
    const style = {
      NotificationItem: {
        DefaultStyle: {
          margin: '10px 5px 2px 1px'
        }
      }
    };

    if (
      this.props.user.isFetching &&
      this.props.segment_info.isFetching &&
      this.props.blocks.isFetching &&
      !isEmpty(this.props.blocks.translations)
    ) {
      this.setDelay();
      return (
        <div className="spinner-container">
          <div className="center-Y cm-spinner" />
        </div>
      );
    }

    return (
      <>
        <>
          <Delay hide={this.state.delay} onStopTime={this.removeDelay} />
          <UserInfo />
          <div className="head_bg">
            <ChooseCountry user={this.props.user} />
            <Header />
          </div>
          <Web />
          <Footer />
          <CookiePopUp />
          <Notifications notifications={notifications} style={style} />
          <Login show={this.props.modals} />
          <Register show={this.props.modals} />
        </>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user_info: state.user_info,
    user: state.user,
    segment_info: state.segment,
    notifications: state.notifications,
    modals: state.modals,
    blocks: state.translations
  };
};

export default withRouter(connect(mapStateToProps, null)(App));
